import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LogChannel, PaymentStepDrawer, PaymentStepServer } from "../../../../../constants";
import { useLogger } from "../../../../../hooks/useLogger";
import { useDrawer } from "../../../../../services/SmartTDE";
import { useMessage } from "../../../../MessageHandler/MessageService";

interface CheckDrawerProps {
	setRequestStatus: Dispatch<SetStateAction<PaymentStepServer | PaymentStepDrawer>>;
	setConnecting: Dispatch<SetStateAction<boolean>>;
	cancelPayment: () => void;
}
const CheckDrawer = ({ setRequestStatus, setConnecting, cancelPayment }: CheckDrawerProps) => {
	const { t } = useTranslation();
	const message = useMessage();
	const { log } = useLogger();
	const { checkDrawerReady } = useDrawer();

	const [isDrawerConnected, setDrawerConnected] = useState<boolean>(false);
	const [isDrawerChecked, setDrawerChecked] = useState<boolean>(false);
	const [hasBlockingResult, setBlockingResult] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");

	/**
	 * Check Drawer connection and update states
	 */
	const getDrawerConnection = useCallback(async (): Promise<void> => {
		try {
			const isDrawerAvailable = await checkDrawerReady();
			if (!isDrawerAvailable.result) {
				setErrorMessage(isDrawerAvailable.errorMessage ?? "");
			}
			if (isDrawerAvailable.errorCode) {
				setBlockingResult(true);
			}
			setDrawerConnected(isDrawerAvailable.result);
		} catch (err: unknown) {
			setDrawerConnected(false);
		}

		setDrawerChecked(true);
	}, [checkDrawerReady]);

	// if not connected... try to get the Drawer connection again every 3 seconds
	// limit the attempts to 12 seconds (4 attempts) and update the state
	useEffect(() => {
		if (isDrawerChecked && !isDrawerConnected) {
			let attempts = 0;
			const interval = setInterval(() => {
				if (attempts < 4 && !hasBlockingResult) {
					log(`check the Drawer connection... ${attempts}`, LogChannel.payment);
					getDrawerConnection();
					attempts++;
				} else {
					clearInterval(interval);
					setConnecting(false);

					message({
						title: "",
						description: errorMessage + " " + t("system.error.staff"),
						okLabel: t("common.ok"),
						okCallback: cancelPayment
					});
				}
			}, 3000);
			return () => clearInterval(interval);
		}
	}, [isDrawerChecked, isDrawerConnected, errorMessage, getDrawerConnection, log, message, t, cancelPayment, setConnecting, hasBlockingResult]);

	useEffect(() => {
		setConnecting(true);
		setBlockingResult(false);
		getDrawerConnection();
	}, [getDrawerConnection, setConnecting]);

	useEffect(() => {
		if (isDrawerConnected) {
			setConnecting(false);
			setRequestStatus(PaymentStepDrawer.creatingCashTransaction);
		}
	}, [isDrawerConnected, log, setRequestStatus, setConnecting]);

	return null;
};

export default CheckDrawer;
