import { Adb, Android, ChromeReaderMode, DeveloperBoard, TextSnippet } from "@mui/icons-material";
import { Box, Container, Tab, Tabs, ThemeProvider } from "@mui/material";
import { ReactNode, SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { RoutePath } from "../../../constants";
import { useBridgeWrapper } from "../../../hooks/useBridgeWrapper";
import { useTheme } from "../../Theme/ThemeWrapper";
import "../Admin.scss";
import AdminHeader from "../parts/AdminHeader";
import DiagnosticApp from "./DiagnosticApp";
import DiagnosticBridge from "./DiagnosticBridge";
import DiagnosticLog from "./DiagnosticLog";
import DiagnosticStripe from "./DiagnosticStripe";
import DiagnosticUsp from "./DiagnosticUsp";

interface TabPanelProps {
	children?: ReactNode;
	index: number;
	tabValue: number;
	tabKey: string;
}

const Diagnostic = () => {
	const navigate = useNavigate();
	const { theme } = useTheme();
	const {
		hasBridge,
		stripeLocationId,
		isInternetReachable,
		username,
		password,
		webviewUrl,
		appVersion,
		logFileName,
		uspFirmwareVersion,
		uspUid,
		uspStatuses,
		uspStatusFront,
		uspStatusProximity,
		uspStatusScanner,
		uspStatusFan,
		_collectUsbPayment,
		_cancelUsbPayment,
		_testUsbStripeReader,
		_persistLog,
		_getLogFilesToUpload,
		_getLogLastLines,
		_uploadLogFiles,
		_copyLogFilesToDocuments,
		_changeWebviewUrl,
		_checkUpdate,
		_installUpdate,
		_openAnyDesk,
		_openSmartTde,
		_toggleUspScanner,
		_toggleUspFan,
		_checkUspStatus,
		_changeUspLedColor,
		_setStripeLocationId,
		_setUsername,
		_setPassword,
		_checkFirmwareUpdate,
		_installFirmwareUpdate
	} = useBridgeWrapper();

	// Redirect to home page if there is no bridge
	useEffect(() => {
		if (!hasBridge) {
			navigate(RoutePath.homePage);
		}
	}, [hasBridge, navigate]);

	// useIdleTimer({
	// 	name: "5minutes-inactive-go-back-home",
	// 	onIdle: () => {
	// 		navigate(RoutePath.homePage);
	// 	},
	// 	timeout: 1000 * 60 * 5
	// });

	const handleBackButton = () => {
		_setUsername("");
		_setPassword("");
		_setStripeLocationId(null);
		navigate(RoutePath.homePage);
	};

	const [tabValue, setTabValue] = useState<number>(0);

	const handleTabChange = (event: SyntheticEvent, newTabValue: number) => {
		setTabValue(newTabValue);
	};

	const TabPanel = (props: TabPanelProps) => {
		const { children, tabValue, index, tabKey, ...other } = props;

		return (
			<Box
				role="tabPanel"
				className={`vertical-tabPanel vertical-tabPanel-${tabKey}`}
				hidden={tabValue !== index}
				id={`vertical-tabPanel-${index}`}
				aria-labelledby={`vertical-tab-${index}`}
				sx={{ p: "1.5rem" }}
				{...other}
			>
				{tabValue === index && children}
			</Box>
		);
	};

	const a11yProps = (index: number) => {
		return {
			id: `vertical-tab-${index}`,
			"aria-controls": `vertical-tabPanel-${index}`
		};
	};

	return (
		<ThemeProvider theme={theme.systemTheme}>
			<Container className="configContainer" sx={{ backgroundColor: theme.systemTheme.palette.background.paper }}>
				<AdminHeader isLoading={false} onClickBack={handleBackButton} />

				<Box className="main">
					<Tabs
						className="tabMenu"
						orientation="vertical"
						variant="scrollable"
						value={tabValue}
						onChange={handleTabChange}
						aria-label="vertical-tabs"
						sx={{ borderRight: "0.0625rem", borderColor: "divider" }}
					>
						<Tab {...a11yProps(0)} iconPosition="start" icon={<Android />} label={"App"} />
						<Tab {...a11yProps(1)} iconPosition="start" icon={<DeveloperBoard />} label={"Serial Port"} />
						<Tab {...a11yProps(2)} iconPosition="start" icon={<ChromeReaderMode />} label={"Stripe Reader"} />
						<Tab {...a11yProps(3)} iconPosition="start" icon={<TextSnippet />} label={"Log"} />
						<Tab {...a11yProps(4)} iconPosition="start" icon={<Adb />} label={"Native Bridge"} />
					</Tabs>

					<Box className="panels">
						<TabPanel tabValue={tabValue} index={0} tabKey={"app"}>
							<DiagnosticApp
								isInternetReachable={isInternetReachable}
								webviewUrl={webviewUrl}
								appVersion={appVersion}
								_checkUpdate={_checkUpdate}
								_installUpdate={_installUpdate}
								_openAnyDesk={_openAnyDesk}
								_openSmartTde={_openSmartTde}
							/>
						</TabPanel>
						<TabPanel tabValue={tabValue} index={1} tabKey={"usp"}>
							<DiagnosticUsp
								uspFirmwareVersion={uspFirmwareVersion}
								uspUid={uspUid}
								uspStatuses={uspStatuses}
								uspStatusFront={uspStatusFront}
								uspStatusProximity={uspStatusProximity}
								uspStatusScanner={uspStatusScanner}
								uspStatusFan={uspStatusFan}
								_toggleUspScanner={_toggleUspScanner}
								_toggleUspFan={_toggleUspFan}
								_checkUspStatus={_checkUspStatus}
								_changeUspLedColor={_changeUspLedColor}
								_checkFirmwareUpdate={_checkFirmwareUpdate}
								_installFirmwareUpdate={_installFirmwareUpdate}
							/>
						</TabPanel>
						<TabPanel tabValue={tabValue} index={2} tabKey={"stripe"}>
							<DiagnosticStripe
								stripeLocationId={stripeLocationId}
								username={username}
								password={password}
								_setStripeLocationId={_setStripeLocationId}
								_setUsername={_setUsername}
								_setPassword={_setPassword}
								_collectUsbPayment={_collectUsbPayment}
								_cancelUsbPayment={_cancelUsbPayment}
								_testUsbStripeReader={_testUsbStripeReader}
							/>
						</TabPanel>
						<TabPanel tabValue={tabValue} index={3} tabKey={"log"}>
							<DiagnosticLog logFileName={logFileName} _getLogLastLines={_getLogLastLines} />
						</TabPanel>
						<TabPanel tabValue={tabValue} index={4} tabKey={"bridge"}>
							<DiagnosticBridge
								_collectUsbPayment={_collectUsbPayment}
								_cancelUsbPayment={_cancelUsbPayment}
								_testUsbStripeReader={_testUsbStripeReader}
								_persistLog={_persistLog}
								_getLogFilesToUpload={_getLogFilesToUpload}
								_getLogLastLines={_getLogLastLines}
								_uploadLogFiles={_uploadLogFiles}
								_copyLogFilesToDocuments={_copyLogFilesToDocuments}
								_changeWebviewUrl={_changeWebviewUrl}
								_checkUpdate={_checkUpdate}
								_installUpdate={_installUpdate}
								_openAnyDesk={_openAnyDesk}
								_openSmartTde={_openSmartTde}
								_toggleUspScanner={_toggleUspScanner}
								_toggleUspFan={_toggleUspFan}
								_checkUspStatus={_checkUspStatus}
								_changeUspLedColor={_changeUspLedColor}
								_setStripeLocationId={_setStripeLocationId}
								_setUsername={_setUsername}
								_setPassword={_setPassword}
								_checkFirmwareUpdate={_checkFirmwareUpdate}
								_installFirmwareUpdate={_installFirmwareUpdate}
							/>
						</TabPanel>
					</Box>
				</Box>
			</Container>
		</ThemeProvider>
	);
};

export default Diagnostic;
