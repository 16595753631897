import { Clear, DeveloperBoard, Done, Hvac, QrCodeScanner, Refresh, SystemUpdate, Tune, Update, WbIncandescent } from "@mui/icons-material";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { UspLedColor } from "../../../constants";
import { AvailableRelease, BridgeResult } from "../../../types/reactNative/bridgeOutput";
import { delayer } from "../../../utils/functions";
import { getMajorVersion } from "../../../utils/version";
import AdminDivider from "../../Layout/Divider/AdminDivider";
import "../Admin.scss";

interface DiagnosticUspProps {
	uspFirmwareVersion: string;
	uspUid: string;
	uspStatuses: string;
	uspStatusFront: boolean;
	uspStatusProximity: boolean;
	uspStatusScanner: boolean;
	uspStatusFan: boolean;
	_toggleUspScanner: (isActive?: boolean, isTest?: boolean) => Promise<BridgeResult>;
	_toggleUspFan: (isActive?: boolean, isTest?: boolean) => Promise<BridgeResult>;
	_checkUspStatus: (isCheckDone?: boolean, isTest?: boolean) => Promise<BridgeResult>;
	_changeUspLedColor: (color: number, isTest?: boolean) => Promise<BridgeResult>;
	_checkFirmwareUpdate: (token: string, isTest?: boolean) => Promise<BridgeResult & { data: AvailableRelease[] }>;
	_installFirmwareUpdate: (token: string, release: AvailableRelease, isTest?: boolean) => Promise<BridgeResult>;
}

const DiagnosticUsp = ({
	uspFirmwareVersion,
	uspUid,
	uspStatuses,
	uspStatusFront,
	uspStatusProximity,
	uspStatusScanner,
	uspStatusFan,
	_toggleUspScanner,
	_toggleUspFan,
	_checkUspStatus,
	_changeUspLedColor,
	_checkFirmwareUpdate,
	_installFirmwareUpdate
}: DiagnosticUspProps) => {
	// Hardcoded token for testing purposes. Account 00001
	const token =
		"eyJTaXRlSWQiOjE0ODM1LCJTb2Z0d2FyZVZlcnNpb24iOiJzdGFnaW5nIiwiUG9zVWlkIjoiMDAwMDFfMTAwIiwiUHJvZHVjdElkIjoxfS41YjhmMmYxNTg0ZTg0ZmU1ZmUyYjc2NDViMTUyMzQyNjg0YzgzOTkw";
	const currentFwVersionMajor = getMajorVersion(uspFirmwareVersion);

	const [updatesFwFound, setUpdatesFwFound] = useState<AvailableRelease | null>();

	const handleCheckFwUpdates = () => {
		if (currentFwVersionMajor) {
			_checkFirmwareUpdate(token)
				.then((response: { data: AvailableRelease[] }) => {
					const thisMajorRelease = response.data.find((release: AvailableRelease) => Number(release.major) === currentFwVersionMajor);
					if (!thisMajorRelease) throw new Error("No release found");

					setUpdatesFwFound(thisMajorRelease);
				})
				.catch((error) => {
					setUpdatesFwFound(null);
				});
		}
	};

	const handleDownloadFwUpdate = () => {
		if (updatesFwFound) {
			_installFirmwareUpdate(token, updatesFwFound)
				.then((response: { success: boolean }) => {
					if (!response.success) {
						throw new Error("Update failed");
					}
				})
				.catch((error) => {})
				.finally(() => setUpdatesFwFound(undefined));
		}
	};
	return (
		<>
			<AdminDivider label={"Board"} icon={<DeveloperBoard />} />
			<Stack direction="row" spacing={2}>
				<Typography variant="body1">{"Firmware version: "}</Typography>
				<Typography variant="body1">{uspFirmwareVersion ?? ""}</Typography>
			</Stack>
			<Stack direction="row" spacing={2}>
				<Typography variant="body1">{"Hardware UID: "}</Typography>
				<Typography variant="body1">{uspUid ?? ""}</Typography>
			</Stack>
			<Stack direction={"row"} alignItems={"center"}>
				{updatesFwFound === undefined ? (
					<Button className="appAction" variant="outlined" color="secondary" onClick={handleCheckFwUpdates} endIcon={<Update />}>
						{"Check for updates"}
					</Button>
				) : updatesFwFound === null ? (
					<Typography variant="subtitle2">{"No updates available"}</Typography>
				) : (
					<Button className="appAction" variant="outlined" color="secondary" onClick={handleDownloadFwUpdate} endIcon={<SystemUpdate />}>
						{"Install update " + updatesFwFound.release}
					</Button>
				)}
			</Stack>

			<AdminDivider label={"Statuses"} icon={<Tune />} />
			<Stack direction="row" spacing={2}>
				<Typography variant="body1">{"Byte array: "}</Typography>
				<Typography variant="body1">{uspStatuses}</Typography>
			</Stack>
			<Stack direction="row" spacing={2}>
				{uspStatusFront ? <Done color="success" /> : <Clear color="error" />}
				<Typography variant="body1">{"Frontal door open: "}</Typography>
			</Stack>
			<Stack direction="row" spacing={2}>
				{uspStatusProximity ? <Done color="success" /> : <Clear color="error" />}
				<Typography variant="body1">{"Proximity sensor active: "}</Typography>
			</Stack>
			<Stack direction="row" spacing={2}>
				{uspStatusScanner ? <Done color="success" /> : <Clear color="error" />}
				<Typography variant="body1">{"Scanner active: "}</Typography>
			</Stack>
			<Stack direction="row" spacing={2}>
				{uspStatusFan ? <Done color="success" /> : <Clear color="error" />}
				<Typography variant="body1">{"Fan rotating: "}</Typography>
			</Stack>
			<Button variant="outlined" color="secondary" onClick={async () => await _checkUspStatus(false)} endIcon={<Refresh />}>
				{"Update statuses"}
			</Button>

			<AdminDivider label={"Scanner"} icon={<QrCodeScanner />} />
			<Stack direction="row" spacing={2}>
				<Button variant="outlined" color="secondary" onClick={async () => await _toggleUspScanner(true)}>
					{"Switch on"}
				</Button>
				<Button variant="outlined" color="secondary" onClick={async () => await _toggleUspScanner(false)}>
					{"Switch off"}
				</Button>
				<TextField label={"Focus here and scan..."} />
			</Stack>

			<AdminDivider label={"Fan"} icon={<Hvac />} />
			<Stack direction="row" spacing={2}>
				<Button variant="outlined" color="secondary" onClick={async () => await _toggleUspFan(true)}>
					{"Switch on"}
				</Button>
				<Button variant="outlined" color="secondary" onClick={async () => await _toggleUspFan(false)}>
					{"Switch off"}
				</Button>
			</Stack>

			<AdminDivider label={"Led"} icon={<WbIncandescent />} />
			<Stack direction="row" spacing={2}>
				<Button
					variant="outlined"
					color="secondary"
					onClick={async () => {
						await _changeUspLedColor(UspLedColor.black);
						await delayer(500);
						await _changeUspLedColor(UspLedColor.blue);
						await delayer(500);
						await _changeUspLedColor(UspLedColor.green);
						await delayer(500);
						await _changeUspLedColor(UspLedColor.cyan);
						await delayer(500);
						await _changeUspLedColor(UspLedColor.red);
						await delayer(500);
						await _changeUspLedColor(UspLedColor.magenta);
						await delayer(500);
						await _changeUspLedColor(UspLedColor.yellow);
						await delayer(500);
						await _changeUspLedColor(UspLedColor.white);
						await delayer(500);
						await _changeUspLedColor(UspLedColor.green);
					}}
				>
					{"Try"}
				</Button>
			</Stack>
		</>
	);
};

export default DiagnosticUsp;
