export enum RoutePath {
	homePage = "/",
	loginPage = "/login",
	adminPage = "/admin/:role",
	choosePage = "choose",
	orderPage = "/order/:method",
	reloadTrigger = "/external/reload",
	closeTrigger = "/external/close",
	diagnostic = "/diagnostic"
}
