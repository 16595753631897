import { HighlightOffRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import TouchButton from "../../Layout/Buttons/TouchButton";
import PinKeyboard from "../../Layout/Keyboard/PinKeyboard";
import ConfirmModal from "./Wrappers/ConfirmModal";

interface KeyPickerModalProps {
	closeModal: () => void;
	confirmPin: () => void;
	keyPin: string;
}

const KeyPickerModal = ({ closeModal, confirmPin, keyPin }: KeyPickerModalProps) => {
	const { t } = useTranslation();

	const [currentPin, setCurrentPin] = useState<string>("");
	const [openListener, setOpenListener] = useState<boolean>(false);

	const handleToggleKeyListener = () => {
		if (openListener) {
			if (currentPin === keyPin) {
				confirmPin();
			} else {
				closeModal();
			}
		} else {
			setCurrentPin("");
			setOpenListener((prev) => !prev);
		}
	};

	return (
		<ConfirmModal>
			<Box>
				<PinKeyboard
					digitText={currentPin}
					setDigitText={setCurrentPin}
					isPasswordMode={true}
					isKeyMode={true}
					toggleKeyListener={handleToggleKeyListener}
				/>
			</Box>
			<Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
				<TouchButton color="error" variant="contained" size="large" startIcon={<HighlightOffRounded />} onClick={closeModal}>
					<Typography variant="button">{t("checkout.eatInPlacePicker.cancel")}</Typography>
				</TouchButton>
			</Box>
		</ConfirmModal>
	);
};

export default memo(KeyPickerModal);
