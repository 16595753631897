export enum PaymentMethod {
	CASH = 1, // Payment ad cash desk
	CASH_DRAWER = 5, // via Automatic cash drawer device
	STRIPE = 2, // Stripe reader - server-driven
	STRIPE_RN = 4, // Stripe reader USB - handled by React Native container
	SATISPAY = 3
}

export const stripePaymentMethods = [PaymentMethod.STRIPE, PaymentMethod.STRIPE_RN];
export const electronicPaymentMethods = [...stripePaymentMethods, PaymentMethod.SATISPAY];
export const fiscalPaymentMethods = [...electronicPaymentMethods, PaymentMethod.CASH_DRAWER];

export function getPaymentMethodName(value: number): string {
	const indexOfS = Object.values(PaymentMethod).indexOf(value as unknown as PaymentMethod);
	return Object.keys(PaymentMethod)[indexOfS].toLowerCase();
}

/**
 * Check if the given array of PaymentMethod contains some Stripe PaymentMethod
 *
 * @param methods  // array of PaymentMethods
 * @returns boolean
 */
export function hasStripePaymentMethods(methods: PaymentMethod[]): boolean {
	return methods.some((pm: PaymentMethod) => stripePaymentMethods.includes(pm));
}

/**
 * Check if the given array of PaymentMethod contains some Electronic PaymentMethod
 *
 * @param methods  // array of PaymentMethods
 * @returns boolean
 */
export function hasElectronicPaymentMethods(methods: PaymentMethod[]): boolean {
	return methods.some((pm: PaymentMethod) => electronicPaymentMethods.includes(pm));
}

/**
 *  Check if the given array of PaymentMethod contains some Fiscal PaymentMethod
 * @param methods  // array of PaymentMethods
 * @returns boolean
 */
export function hasFiscalPaymentMethods(methods: PaymentMethod[]): boolean {
	return methods.some((pm: PaymentMethod) => fiscalPaymentMethods.includes(pm));
}
