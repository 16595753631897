import { Save, Undo } from "@mui/icons-material";
import { CircularProgress, Divider, TextField, TextFieldProps } from "@mui/material";
import { ChangeEvent, memo, useCallback, useState } from "react";
import TouchIcon from "../Buttons/TouchIcon";

type TInputSaveProps = {
	label: string;
	value: string | number;
	onSave: (value: string | number) => void;
	isSaving?: boolean;
	inputProps?: { max?: number; min?: number; maxLength?: number; minLength?: number };
} & TextFieldProps;

const InputSave = ({ label, value, onSave, isSaving, inputProps, slotProps, ...rest }: TInputSaveProps) => {
	const [localValue, setLocalValue] = useState<string | number>(value);
	const [valid, setValid] = useState<boolean>(false);

	const handleValidation = useCallback(
		(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
			const newValue: string | number = e.target.value;

			setValid(false);

			// check maxLength if exist
			if (inputProps?.maxLength !== undefined) {
				if (newValue.length > inputProps?.maxLength) return;
			}
			// check min and max if exist
			if (inputProps?.max !== undefined && inputProps?.min !== undefined) {
				const number: number = Number(newValue);
				if (number > inputProps.max || number < inputProps.min) return;
			}

			// set Valid by length
			if (inputProps?.maxLength !== undefined && inputProps?.minLength !== undefined) {
				if (newValue.length <= inputProps?.maxLength && newValue.length >= inputProps?.minLength) {
					setValid(true);
				}
			} else {
				setValid(true);
			}

			setLocalValue(newValue);
		},
		[inputProps]
	);

	const updateValue = useCallback(() => onSave(localValue), [localValue, onSave]);

	const resetValue = useCallback(() => setLocalValue(value), [value]);

	return (
		<TextField
			label={label}
			value={localValue}
			onChange={handleValidation}
			{...rest}
			slotProps={{
				...slotProps,
				input: {
					...slotProps?.input,
					inputProps: inputProps,
					endAdornment: (
						<>
							<TouchIcon title="update" onClick={updateValue} color={rest.color} disabled={isSaving || !valid}>
								{isSaving ? (
									<CircularProgress
										title="saving"
										color="info"
										sx={{
											width: "1.5rem !important",
											height: "1.5rem !important"
										}}
									/>
								) : (
									<Save />
								)}
							</TouchIcon>

							<Divider sx={{ height: "1.75rem", m: "0.25rem" }} orientation="vertical" />

							<TouchIcon title="reset" onClick={resetValue} color={rest.color} disabled={value === localValue}>
								<Undo />
							</TouchIcon>
						</>
					)
				}
			}}
		/>
	);
};

export default memo(InputSave);
