import { memo } from "react";
import { useLongPress } from "use-long-press";
import useSoundEffect, { SoundEffectProps } from "../../../hooks/effects/useSoundEffect";
import ToggleItem from "../Toggle/ToggleItem";

interface TouchToggleButtonProps extends SoundEffectProps {
	active: boolean;
	statusToCompare?: boolean; // toggle buttons become colored when "active" is different from "statusToCompare"
	onToggle: () => void;
}

const TouchToggleButton = ({ muteSound, tone, active, statusToCompare, onToggle }: TouchToggleButtonProps) => {
	const { playSound } = useSoundEffect({ muteSound, tone });
	const bind = useLongPress((event: any) => {
		if (onToggle) {
			onToggle();
		}
	});

	return (
		<ToggleItem
			disableRipple
			disableTouchRipple
			remDimension={2.75}
			color={active !== statusToCompare ? "success" : "info"}
			checked={active}
			onChange={onToggle}
			onClickCapture={playSound}
			sx={{ my: 0 }}
			{...bind()}
		/>
	);
};

export default memo(TouchToggleButton);
