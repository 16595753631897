import { useCallback, useEffect, useMemo, useState } from "react";
import { useTheme } from "../components/Theme/ThemeWrapper";
import { useBridgeWrapper } from "./useBridgeWrapper";
import { useDebounceValue } from "./useDebounceValue";

/**
 * Custom hook to handle scanned barcodes
 */
export const useBarcodeInput = () => {
	const { hasBridge } = useBridgeWrapper();
	const { settings } = useTheme();

	/* Listen to scanner asynchronous input only if it is enabled in the settings and the bridge is active */
	const shouldListenToScanner = useMemo(() => settings.isScannerEnabled && hasBridge, [hasBridge, settings.isScannerEnabled]);

	const [barcode, setBarcode] = useState<string>("");
	const [exposedBarcode, setExposedBarcode] = useState<string>("");
	const [barcodeReady, setBarcodeReady] = useState<boolean>(false);

	const debouncedBarcode = useDebounceValue(barcode, 100);

	const clearBarcode = useCallback(() => {
		setBarcode("");
		setExposedBarcode("");
		setBarcodeReady(false);
	}, []);

	useEffect(() => {
		const handleBarcodeRead = () => {
			if (barcodeReady && debouncedBarcode !== "") {
				setExposedBarcode(debouncedBarcode);
			}
		};

		handleBarcodeRead();
	}, [barcodeReady, debouncedBarcode, exposedBarcode]);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			const whitespaceKeys = ["Enter"];
			const skippableKeys = [
				"Unidentified",
				"Alt",
				"AltGraph",
				"CapsLock",
				"Control",
				"Fn",
				"FnLock",
				"Hyper",
				"Meta",
				"NumLock",
				"ScrollLock",
				"Shift",
				"Super",
				"Symbol",
				"SymbolLock",
				"ArrowDown",
				"ArrowLeft",
				"ArrowRight",
				"ArrowUp",
				"End",
				"Home",
				"PageDown",
				"PageUp",
				"Backspace",
				"Clear",
				"Copy",
				"CrSel",
				"Cut",
				"Delete",
				"EraseEof",
				"ExSel",
				"Insert",
				"Paste",
				"Redo",
				"Undo",
				"Accept",
				"Again",
				"Attn",
				"Cancel",
				"ContextMenu",
				"Escape",
				"Execute",
				"Find",
				"Finish",
				"Help",
				"Pause",
				"Play",
				"Props",
				"Select",
				"ZoomIn",
				"ZoomOut",
				"BrightnessDown",
				"BrightnessUp",
				"Eject",
				"LogOff",
				"Power",
				"PowerOff",
				"PrintScreen",
				"Hibernate",
				"Standby",
				"WakeUp"
			];

			// "Enter" key (or equivalent keys) ends the barcode input
			if (whitespaceKeys.includes(event.key)) {
				setBarcodeReady(true);
				return;
			}
			// skip if the key is a Modifier, Navigation, Editing, UI or Device key
			if (skippableKeys.includes(event.key)) return;

			setBarcode((prev) => prev + event.key);
		};

		// add event listener to handle keydown
		if (shouldListenToScanner) {
			document.addEventListener("keydown", handleKeyDown);
		}

		return () => {
			// remove event listener
			if (shouldListenToScanner) {
				document.removeEventListener("keydown", handleKeyDown);
			}
		};
	}, [shouldListenToScanner]);

	return { scannedBarcode: exposedBarcode, clearBarcode };
};
