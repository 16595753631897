/**
 * When the pwa communicates with the SmartTde API, it uses a progressive request counter.
 *
 * The counter should increment at each request.
 * The progressiveRequestId loops from 0 to 99.
 *
 * But, if a request receive an expected error (network coverage, missing response, server temporary not reachable, etc...),
 * the next request should use the previous key (previous counter value).
 * This way we leverages the idempotency api pattern and we expect the response for the old request.
 *
 * The progressiveRequestId key is stored in localStorage
 */
export class ProgressiveRequestManager {
	min: number = 0;
	max: number = 9999;
	current: number;

	constructor() {
		this.current = this.#getInitialCounter();
	}

	/**
	 * Returns the next progressiveRequestId (and stores the updated value in storage).
	 *
	 * @returns next progressiveRequestId
	 */
	getNextProgressiveRequestId = (): number => {
		const nextProgressiveRequestId = (this.current + 1) % (this.max + 1);

		this.setCurrentCounter(nextProgressiveRequestId);
		localStorage.setItem("progressiveRequestId", String(nextProgressiveRequestId));

		return nextProgressiveRequestId;
	};

	/**
	 * Stores in localStorage the previous version of the progressiveRequestId
	 */
	revertLastProgressiveRequestId = (): void => {
		const prevProgressiveRequestId = String((this.current + this.max) % (this.max + 1));

		this.setCurrentCounter(parseInt(prevProgressiveRequestId));
		localStorage.setItem("progressiveRequestId", prevProgressiveRequestId);
	};

	/**
	 * Search the localStorage for a stored progressiveRequestId.
	 * If not found, generate a new one.
	 *
	 * @returns current progressiveRequestId
	 */
	#getInitialCounter = (): number => {
		const lastId: string | null = localStorage.getItem("progressiveRequestId");
		return lastId ? parseInt(lastId) : this.getRandomProgressiveRequestId();
	};

	/**
	 * Generate a random progressiveRequestId
	 *
	 * @returns random progressiveRequestId
	 */
	getRandomProgressiveRequestId = (): number => {
		return Math.floor(Math.random() * (this.max - this.min + 1)) + this.min;
	};

	/**
	 * Getter / Setter
	 */
	getCurrentCounter = (): number => {
		return this.current;
	};
	setCurrentCounter = (value: number): void => {
		this.current = value;
	};
}
