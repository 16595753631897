import { alpha, Box, Grid2 as Grid, Paper, Stack, ThemeProvider, Typography } from "@mui/material";
import { memo, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as BarCodeIcon } from "../../../assets/system/barcode.svg";
import { ModalViewName } from "../../../constants";
import { useBarcodeInput } from "../../../hooks/useBarcodeInput";
import { useGetRestaurantMedia } from "../../../hooks/useCdnAssets";
import { Cart, CartItem, ItemSelectable } from "../../../types";
import { delayer } from "../../../utils/functions";
import TableWrapper from "../../Layout/Table/TableWrapper";
import { useTheme } from "../../Theme/ThemeWrapper";
import OrderReviewBottomBar from "../BottomBar/OrderReviewBottomBar";
import ItemCard from "../Items/ItemCard";
import "../OrderMaker.scss";
import "./OrderReviewDialog.scss";
import OrderReviewRow from "./OrderReviewRow";
import DialogFull from "./Wrappers/DialogFull";

interface OrderReviewDialogProps {
	cart: Cart;
	addItemToCart: (cartItem: CartItem) => void;
	removeItemFromCart: (uid: string) => void;
	closeModal: () => void;
	confirmOrder: () => void;
	cancelOrder: () => void;
	goToItemCartEditor: (cartItem: CartItem) => void;
	recommendedItems: ItemSelectable[];
	onNewCartItem: (itemSelectable: ItemSelectable, openDetail?: boolean, referral?: ModalViewName) => void;
	decodeBarcode: (barcode: string) => ItemSelectable | undefined;
}

const OrderReviewDialog = ({
	cart,
	addItemToCart,
	removeItemFromCart,
	closeModal,
	confirmOrder,
	cancelOrder,
	goToItemCartEditor,
	recommendedItems,
	onNewCartItem,
	decodeBarcode
}: OrderReviewDialogProps) => {
	const { theme, settings } = useTheme();
	const { t } = useTranslation();
	const { scannedBarcode, clearBarcode } = useBarcodeInput();
	const { url: logoUrl, isFallback: isLogoFallback } = useGetRestaurantMedia("logo");

	const bg = useMemo(() => theme.systemTheme.palette.background.default, [theme.systemTheme.palette.background.default]);

	/* PLUs in the cart */
	const plus: string[] = useMemo(() => {
		const newPlus: string[] = [];
		cart.items?.forEach((item: CartItem) => {
			if (newPlus.indexOf(item.item.itemInfo.uid) === -1) {
				newPlus.push(item.item.itemInfo.uid);
			}
		});
		return newPlus;
	}, [cart.items]);

	/* Recommended PLUs (not in cart yet) */
	const currentRecommended: ItemSelectable[] = useMemo(
		() => recommendedItems.filter((item: ItemSelectable) => !plus.includes(item.itemInfo.uid)),
		[plus, recommendedItems]
	);

	const [barcodeResult, setBarcodeResult] = useState<boolean>();
	const [barcodeText, setBarcodeText] = useState<string>("");

	useEffect(() => {
		const handleBarcodeRead = async () => {
			if (settings.isScannerEnabled && scannedBarcode !== "") {
				setBarcodeText(scannedBarcode);
				const item = decodeBarcode(scannedBarcode);
				if (item) {
					onNewCartItem(item, item.hasItemLinkedList, ModalViewName.orderReview);
					setBarcodeResult(true);
				} else {
					setBarcodeResult(false);
				}

				clearBarcode();
				await delayer(1000);
				setBarcodeResult(undefined);
				setBarcodeText("");
			}
		};
		handleBarcodeRead();
	}, [scannedBarcode, decodeBarcode, onNewCartItem, clearBarcode, settings.isScannerEnabled]);

	/**
	 * When a new item is added from the suggested items, open the detail view
	 */
	const onNewCartItemFromSuggested = useCallback(
		(itemSelectable: ItemSelectable, openDetail?: boolean): void => {
			onNewCartItem(itemSelectable, openDetail, ModalViewName.orderReview);
		},
		[onNewCartItem]
	);

	const doNothing = useCallback(() => {}, []);

	return (
		<DialogFull title="order-review-dialog">
			<Box className="header" sx={{ backgroundColor: bg }}>
				{isLogoFallback ? null : (
					<ThemeProvider theme={theme.customTheme}>
						<Paper elevation={0}>
							<img className="customLogo" src={logoUrl} alt="logo" loading="lazy" />
						</Paper>
					</ThemeProvider>
				)}
			</Box>
			<Box className="main orderReview">
				<Box className="titles" sx={{ backgroundColor: bg }}>
					<Typography variant="h3" color="primary">
						{t("checkout.orderReview.confirmOrderText").toUpperCase()}
					</Typography>
					<Typography variant="h4" color="primary">
						{cart.amount === 0 ? "" : t("checkout.orderReview.summaryText").toUpperCase()}
					</Typography>
				</Box>

				<Box className="scrollable" sx={{ width: "100%", mt: "8vh" }}>
					<TableWrapper sx={{ minHeight: "50vh", margin: "2rem 0 3rem" }}>
						{cart.items?.map((row: CartItem) => {
							const handleClick = () => goToItemCartEditor(row);

							return (
								<OrderReviewRow
									key={row.uid}
									cartItem={row}
									clickRow={handleClick}
									addItemToCart={addItemToCart}
									removeItemFromCart={removeItemFromCart}
								/>
							);
						})}
					</TableWrapper>

					{currentRecommended.length > 0 && !settings.isScannerEnabled ? (
						<Box className="marketingHint">
							<Box
								className="verticalFaderStatic"
								sx={{
									background: `linear-gradient(0deg, ${alpha(bg, 1)} 0%, ${alpha(bg, 0)} 100%)`
								}}
							/>
							<Typography variant="h5">{t("checkout.orderReview.marketingHint")}</Typography>

							<ThemeProvider theme={theme.customTheme}>
								<Grid container spacing={0}>
									<Grid size={{ xs: 0.5 }}></Grid>
									<Grid size={{ xs: 11 }}>
										<Grid container rowSpacing="1rem" columnSpacing="4.7rem">
											{currentRecommended.map(
												(item: ItemSelectable, index: number): ReactNode =>
													index < 3 && (
														<ItemCard
															key={item.itemInfo.uid}
															itemSelectable={item}
															menuColumn={3}
															itemCount={0}
															onNewCartItem={onNewCartItemFromSuggested}
															decreaseItemCart={doNothing}
														/>
													)
											)}
										</Grid>
									</Grid>
								</Grid>
							</ThemeProvider>
						</Box>
					) : null}

					{settings.isScannerEnabled ? (
						<Stack className="scannerHint" direction="column" alignItems="center" justifyContent={"center"}>
							<Typography color="textPrimary" variant="h4">
								{t("checkout.orderReview.scannerHint")}
							</Typography>
							<Stack className="barcodeReading" sx={{ color: theme.systemTheme.palette.secondary.main }} alignItems="center">
								<Stack direction="row" spacing={2}>
									{Array(2)
										.fill(0)
										.map((_, index) => (
											<BarCodeIcon
												key={index}
												fill={
													barcodeResult === undefined
														? theme.systemTheme.palette.common.black
														: barcodeResult
															? theme.systemTheme.palette.success.main
															: theme.systemTheme.palette.error.main
												}
											/>
										))}
								</Stack>
								<Typography className="barcodeResult" color="textPrimary" variant="h4">
									{barcodeText}
								</Typography>
							</Stack>
						</Stack>
					) : null}

					<div className="verticalFiller" />
					{currentRecommended.length === 0 ? (
						<Box
							className="verticalFader"
							sx={{
								background: `linear-gradient(0deg, ${alpha(bg, 1)} 0%, ${alpha(bg, 0)} 100%)`
							}}
						/>
					) : null}
				</Box>
			</Box>

			<OrderReviewBottomBar
				amount={cart.amount}
				itemsCount={cart.itemsCount}
				closeModal={closeModal}
				confirmOrder={confirmOrder}
				cancelOrder={cancelOrder}
			/>
		</DialogFull>
	);
};

export default memo(OrderReviewDialog);
