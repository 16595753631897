import { ArrowCircleLeftOutlined, CheckCircleOutlineRounded, Search } from "@mui/icons-material";
import { alpha, Box, Typography } from "@mui/material";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCurrency } from "../../../hooks/useCurrency";
import TouchButton from "../../Layout/Buttons/TouchButton";
import { useTheme } from "../../Theme/ThemeWrapper";
import BottomBar from "./BottomBar";

export interface OrderReviewBottomBarProps {
	amount: number;
	itemsCount: number;
	closeModal: () => void;
	confirmOrder: () => void;
	cancelOrder: () => void;
}

const OrderReviewBottomBar = ({ amount, itemsCount, closeModal, confirmOrder, cancelOrder }: OrderReviewBottomBarProps) => {
	const { theme, settings } = useTheme();
	const { t } = useTranslation();
	const { currency } = useCurrency();

	const confirmDescription: string = useMemo(() => currency(amount) + " | " + t("checkout.cartBottomBar.confirmOrder"), [amount, currency, t]);
	const buttonTextColor: string = theme.systemTheme.palette.info.contrastText;
	const buttonBorderColor: string = useMemo(() => alpha(theme.systemTheme.palette.info.contrastText, 0.1), [theme.systemTheme]);

	return (
		<BottomBar>
			<Box>
				<TouchButton
					color="inherit"
					variant="outlined"
					size="large"
					sx={{ color: buttonTextColor, borderColor: buttonBorderColor }}
					startIcon={<ArrowCircleLeftOutlined />}
					disableElevation
					className="cancel-order-btn"
					onClick={settings.isScannerEnabled ? cancelOrder : closeModal}
				>
					<Typography variant="button">{settings.isScannerEnabled ? t("checkout.cartBottomBar.cancelOrder") : t("common.back")}</Typography>
				</TouchButton>
			</Box>
			{settings.isScannerEnabled ? (
				<Box>
					<TouchButton
						color="success"
						variant="contained"
						fillBgWhenDisabled={true}
						size="large"
						startIcon={<Search />}
						disableElevation
						onClick={closeModal}
					>
						<Typography variant="button">{t("common.search")}</Typography>
					</TouchButton>
				</Box>
			) : null}
			<Box>
				<TouchButton
					color="success"
					variant="contained"
					fillBgWhenDisabled={true}
					size="large"
					endIcon={<CheckCircleOutlineRounded />}
					disableElevation
					onClick={confirmOrder}
					disabled={itemsCount === 0 ? true : false}
				>
					<Typography variant="button">{confirmDescription}</Typography>
				</TouchButton>
			</Box>
		</BottomBar>
	);
};

export default memo(OrderReviewBottomBar);
