import { cloneDeep } from "lodash";
import { ActionType } from "../constants";
import { defaultRestaurantInfo, defaultUpSellingItem } from "../constants/defaults";
import { AppConfig, LayoutConfig, RestaurantInfo, RestaurantInfoMedia, SettingsConfig, UpSellingItem } from "../types";
import { isObject } from "../utils/functions";

/**
 * This hook  is used to map the RestaurantInfo object to the latest version of the software.
 * It is used to ensure that the object has all the properties of the latest version.
 *
 * @returns The versionMapper function.
 */
export const useRestaurantInfoVersionMapper = () => {
	/**
	 * This function maps the properties of the given object to the default object.
	 * It is used to ensure that the object has all the properties of the default object.
	 * The sub-objects are not mapped, they are handled separately.
	 *
	 * @param defaultObject The default object to map to.
	 * @param object The object to map.
	 * @returns The mapped object.
	 */
	const mapProperties = (defaultObject: any, object: any): any => {
		const baseObject = cloneDeep(defaultObject);

		try {
			Object.entries(baseObject).forEach(([key, baseValue]: [string, any]) => {
				if (isObject(baseValue)) {
					delete baseObject[key];
				} else {
					baseObject[key] = object != null && Object.prototype.hasOwnProperty.call(object, key) ? object[key] : baseValue;
				}
			});
			return baseObject;
		} catch (error) {
			return baseObject;
		}
	};

	/**
	 * Adapt the KioskIds to the new range [101 - 199]. Previously it was [1 - 99].
	 * Breaking change introduced in version 1.4.3
	 *
	 * @param settingsConfig The settingsConfig object to adapt.
	 * @returns The adapted settingsConfig object.
	 */
	const adaptKioskIds = (settingsConfig: SettingsConfig): SettingsConfig => {
		if (settingsConfig.kioskId === 0 || settingsConfig.kioskId > 100) return settingsConfig;

		const newSettingConfig = cloneDeep(settingsConfig);
		newSettingConfig.kioskId = settingsConfig.kioskId + 100;

		return newSettingConfig;
	};

	/**
	 * Convert the cardActions flags to buttonAction and boxAction
	 * Breaking change introduced in version 2.0.0
	 *
	 * @param settingsConfig The settingsConfig object to convert.
	 * @returns The converted settingsConfig object.
	 */
	const convertCardActions = (settingsConfig: SettingsConfig): SettingsConfig => {
		if (settingsConfig.cardButtonsBehaviourInverted == null && settingsConfig.cardHideLinkToDetails == null && settingsConfig.cardHideOneClickAdd == null) {
			return settingsConfig;
		}
		const addAction = settingsConfig.cardHideOneClickAdd ? ActionType.none : ActionType.add;
		const detailsAction = settingsConfig.cardHideLinkToDetails ? ActionType.none : ActionType.details;

		const newSettingConfig = cloneDeep(settingsConfig);
		return {
			...newSettingConfig,
			buttonAction: settingsConfig.cardButtonsBehaviourInverted ? detailsAction : addAction,
			boxAction: settingsConfig.cardButtonsBehaviourInverted ? addAction : detailsAction,
			cardButtonsBehaviourInverted: undefined,
			cardHideLinkToDetails: undefined,
			cardHideOneClickAdd: undefined
		};
	};

	/**
	 * This function maps the settings to the new version.
	 * @param settings The settings to map.
	 * @returns The mapped settings
	 */
	const mapSettings = (settings: SettingsConfig[]): SettingsConfig[] => {
		if (!settings) return defaultRestaurantInfo.config.settings;

		return settings.map((settingsConfig: SettingsConfig) => {
			const converted = convertCardActions(settingsConfig);
			const mapped = mapProperties(defaultRestaurantInfo.config.settings[0], converted);
			return adaptKioskIds(mapped);
		});
	};

	/**
	 * This function maps the theme to the new version.
	 * @param theme The theme to map.
	 * @param defaultTheme The default theme.
	 * @returns The mapped theme.
	 */
	const mapTheme = (theme: LayoutConfig, defaultTheme: LayoutConfig): LayoutConfig => {
		if (!theme) return defaultTheme;

		return {
			...mapProperties(defaultTheme, theme),
			color: mapProperties(defaultTheme.color, theme.color),
			font: mapProperties(defaultTheme.font, theme.font),
			imageSet: {} // handle imageSet as needed
		};
	};

	/**
	 * This function maps the themes to the new version.
	 * @param themes The themes to map.
	 * @returns The mapped themes.
	 */
	const mapThemes = (themes: AppConfig["themes"]): AppConfig["themes"] => {
		if (!themes) return defaultRestaurantInfo.config.themes;

		return {
			custom: mapTheme(themes.custom, defaultRestaurantInfo.config.themes.custom),
			system: mapTheme(themes.system, defaultRestaurantInfo.config.themes.system)
		};
	};

	/**
	 * This function maps the upSellingList to the new version.
	 * @param upSellingList The upSellingList to map.
	 * @returns The mapped upSellingList.
	 */
	const mapUpSellingList = (upSellingList: UpSellingItem[]): UpSellingItem[] => {
		if (!upSellingList) return defaultRestaurantInfo.config.upSellingList;

		return upSellingList.map((upSellingItem: UpSellingItem) => {
			return mapProperties(defaultUpSellingItem, upSellingItem);
		});
	};

	/**
	 * This function maps the media to the new version.
	 * @param media The media to map.
	 * @returns The mapped media.
	 */
	const mapMedia = (media: RestaurantInfoMedia): RestaurantInfoMedia => {
		if (!media) return defaultRestaurantInfo.media;

		return mapProperties(defaultRestaurantInfo.media, media);
	};

	/**
	 * This function maps the config to the new version.
	 * @param config The config to map.
	 * @returns The mapped config.
	 */
	const mapConfig = (config: AppConfig, versionApp: string, versionFw: string): AppConfig => {
		if (!config) return defaultRestaurantInfo.config;

		return {
			...mapProperties(defaultRestaurantInfo.config, config),
			settings: mapSettings(config.settings),
			themes: mapThemes(config.themes),
			upSellingList: mapUpSellingList(config.upSellingList),
			versionApp: versionApp,
			versionFw: versionFw
		};
	};

	/**
	 * This function maps the RestaurantInfo object to the current version of the object.
	 * @param restaurantInfo The RestaurantInfo object to map.
	 * @returns The mapped RestaurantInfo object
	 */
	const versionMapper = (restaurantInfo: any, versionApp: string, versionFw: string): RestaurantInfo => {
		if (!restaurantInfo) return defaultRestaurantInfo;

		return {
			...mapProperties(defaultRestaurantInfo, restaurantInfo),
			config: mapConfig(restaurantInfo.config, versionApp, versionFw),
			media: mapMedia(restaurantInfo.media)
		};
	};

	return { versionMapper };
};
