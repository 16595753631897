import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LogChannel, PaymentStepDrawer, PaymentStepServer } from "../../../../../constants";
import { useBridgeWrapper } from "../../../../../hooks/useBridgeWrapper";
import { useLogger } from "../../../../../hooks/useLogger";
import { useMessage } from "../../../../MessageHandler/MessageService";

interface CheckReaderRNProps {
	setRequestStatus: Dispatch<SetStateAction<PaymentStepServer | PaymentStepDrawer>>;
	setConnecting: Dispatch<SetStateAction<boolean>>;
	cancelPayment: () => void;
}
const CheckReaderRN = ({ setRequestStatus, setConnecting, cancelPayment }: CheckReaderRNProps) => {
	const { t } = useTranslation();
	const message = useMessage();
	const { log, warn } = useLogger();
	const { hasBridge, _testUsbStripeReader } = useBridgeWrapper();

	const [isUsbReaderConnected, setUsbReaderConnected] = useState<boolean>(false);
	const [isUsbReaderChecked, setUsbReaderChecked] = useState<boolean>(false);

	/**
	 * Handle the error message when the USB reader is not
	 * reachable and the user wants to cancel the order
	 */
	const handleError = useCallback(() => {
		warn("USB reader not reachable", LogChannel.payment);
		message({
			title: "",
			description: t("system.error.reader_not_found") + ". " + t("system.error.staff"),
			okLabel: t("common.ok"),
			okCallback: cancelPayment
		});
	}, [warn, message, t, cancelPayment]);

	/**
	 * Check USB reader connection and update states
	 */
	const getUsbReaderConnection = useCallback(async (): Promise<void> => {
		if (hasBridge) {
			const connected: { success: boolean } = await _testUsbStripeReader();
			setUsbReaderConnected(connected.success);
		}
		setUsbReaderChecked(true);
	}, [hasBridge, _testUsbStripeReader]);

	// if the available methods is empty... try to get the USB reader connection again every 4 seconds
	// limit the attempts to 28 seconds (7 attempts) and update the state
	useEffect(() => {
		if (hasBridge && isUsbReaderChecked && !isUsbReaderConnected) {
			let attempts = 0;
			const interval = setInterval(() => {
				if (attempts < 7) {
					log(`check the USB reader connection... ${attempts}`, LogChannel.payment);
					getUsbReaderConnection();
					attempts++;
				} else {
					clearInterval(interval);
					setConnecting(false);
					handleError();
				}
			}, 4000);
			return () => clearInterval(interval);
		}
	}, [hasBridge, getUsbReaderConnection, isUsbReaderChecked, isUsbReaderConnected, log, handleError, setConnecting]);

	useEffect(() => {
		setConnecting(true);
		getUsbReaderConnection();
	}, [getUsbReaderConnection, setConnecting]);

	useEffect(() => {
		if (isUsbReaderConnected) {
			setConnecting(false);
			setRequestStatus(PaymentStepServer.creatingIntent);
		}
	}, [isUsbReaderConnected, log, setRequestStatus, setConnecting]);

	return null;
};

export default CheckReaderRN;
