import { Adb } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { AvailableRelease, BridgeResult } from "../../../types/reactNative/bridgeOutput";
import AdminDivider from "../../Layout/Divider/AdminDivider";
import "../Admin.scss";

interface DiagnosticBridgeProps {
	_collectUsbPayment: (piClientSecret: string, isTest?: boolean) => Promise<BridgeResult>;
	_cancelUsbPayment: (isTest?: boolean) => Promise<BridgeResult>;
	_testUsbStripeReader: (isTest?: boolean) => Promise<BridgeResult>;
	_persistLog: (value: string, isTest?: boolean) => Promise<BridgeResult>;
	_getLogFilesToUpload: (kioskId: number, isTest?: boolean) => Promise<BridgeResult & { data: string[] }>;
	_getLogLastLines: (isTest?: boolean) => Promise<BridgeResult & { data: string }>;
	_uploadLogFiles: (kioskId: number, logFiles: string[], isTest?: boolean) => Promise<BridgeResult>;
	_copyLogFilesToDocuments: (isTest?: boolean) => Promise<BridgeResult>;
	_changeWebviewUrl: (url?: string, hasCloseButton?: boolean, isTest?: boolean) => Promise<BridgeResult>;
	_checkUpdate: (token: string, isTest?: boolean) => Promise<BridgeResult & { data: AvailableRelease[] }>;
	_installUpdate: (token: string, release: AvailableRelease, isTest?: boolean) => Promise<BridgeResult>;
	_openAnyDesk: (isTest?: boolean) => Promise<BridgeResult>;
	_openSmartTde: (backgroundMode?: boolean, isTest?: boolean) => Promise<BridgeResult>;
	_toggleUspScanner: (isActive?: boolean, isTest?: boolean) => Promise<BridgeResult>;
	_toggleUspFan: (isActive?: boolean, isTest?: boolean) => Promise<BridgeResult>;
	_checkUspStatus: (isCheckDone?: boolean, isTest?: boolean) => Promise<BridgeResult>;
	_changeUspLedColor: (color: number, isTest?: boolean) => Promise<BridgeResult>;
	_setStripeLocationId: (stripeLocationId: string | null, isTest?: boolean) => Promise<BridgeResult>;
	_setUsername: (username: string, isTest?: boolean) => Promise<BridgeResult>;
	_setPassword: (password: string, isTest?: boolean) => Promise<BridgeResult>;
	_checkFirmwareUpdate: (token: string, isTest?: boolean) => Promise<BridgeResult & { data: AvailableRelease[] }>;
	_installFirmwareUpdate: (token: string, release: AvailableRelease, isTest?: boolean) => Promise<BridgeResult>;
}

const DiagnosticBridge = ({
	_collectUsbPayment,
	_cancelUsbPayment,
	_testUsbStripeReader,
	_persistLog,
	_getLogFilesToUpload,
	_getLogLastLines,
	_uploadLogFiles,
	_copyLogFilesToDocuments,
	_changeWebviewUrl,
	_checkUpdate,
	_installUpdate,
	_openAnyDesk,
	_openSmartTde,
	_toggleUspScanner,
	_toggleUspFan,
	_checkUspStatus,
	_changeUspLedColor,
	_setStripeLocationId,
	_setUsername,
	_setPassword,
	_checkFirmwareUpdate,
	_installFirmwareUpdate
}: DiagnosticBridgeProps) => {
	// ES6 Map to store the methods and their test result
	const [bridgeMethods, setBridgeMethods] = useState<Map<string, boolean>>(new Map());

	const checkBridgeMethods = useCallback(async () => {
		const result01 = await _collectUsbPayment("", true);
		const result02 = await _cancelUsbPayment(true);
		const result03 = await _testUsbStripeReader(true);
		const result04 = await _persistLog("", true);
		const result05 = await _getLogFilesToUpload(0, true);
		const result06 = await _getLogLastLines(true);
		const result07 = await _uploadLogFiles(0, [], true);
		const result08 = await _copyLogFilesToDocuments(true);
		const result09 = await _changeWebviewUrl(undefined, false, true);
		const result10 = await _checkUpdate("", true);
		const result11 = await _installUpdate("", { major: "", release: "", name: "" }, true);
		const result12 = await _openAnyDesk(true);
		const result13 = await _openSmartTde(false, true);
		const result14 = await _toggleUspScanner(true, true);
		const result15 = await _toggleUspFan(true, true);
		const result16 = await _checkUspStatus(true, true);
		const result17 = await _changeUspLedColor(0, true);
		const result18 = await _setStripeLocationId("", true);
		const result19 = await _setUsername("", true);
		const result20 = await _setPassword("", true);
		const result21 = await _checkFirmwareUpdate("", true);
		const result22 = await _installFirmwareUpdate("", { major: "", release: "", name: "" }, true);
		setBridgeMethods(
			new Map([
				["_collectUsbPayment", result01.success],
				["_cancelUsbPayment", result02.success],
				["_testUsbStripeReader", result03.success],
				["_persistLog", result04.success],
				["_getLogFilesToUpload", result05.success],
				["_getLogLastLines", result06.success],
				["_uploadLogFiles", result07.success],
				["_copyLogFilesToDocuments", result08.success],
				["_changeWebviewUrl", result09.success],
				["_checkUpdate", result10.success],
				["_installUpdate", result11.success],
				["_openAnyDesk", result12.success],
				["_openSmartTde", result13.success],
				["_toggleUspScanner", result14.success],
				["_toggleUspFan", result15.success],
				["_checkUspStatus", result16.success],
				["_changeUspLedColor", result17.success],
				["_setStripeLocationId", result18.success],
				["_setUsername", result19.success],
				["_setPassword", result20.success],
				["_checkFirmwareUpdate", result21.success],
				["_installFirmwareUpdate", result22.success]
			])
		);
	}, [
		_collectUsbPayment,
		_cancelUsbPayment,
		_testUsbStripeReader,
		_persistLog,
		_getLogFilesToUpload,
		_getLogLastLines,
		_uploadLogFiles,
		_copyLogFilesToDocuments,
		_changeWebviewUrl,
		_checkUpdate,
		_installUpdate,
		_openAnyDesk,
		_openSmartTde,
		_toggleUspScanner,
		_toggleUspFan,
		_checkUspStatus,
		_changeUspLedColor,
		_setStripeLocationId,
		_setUsername,
		_setPassword,
		_checkFirmwareUpdate,
		_installFirmwareUpdate
	]);

	return (
		<>
			<AdminDivider label={"Bridge method available"} icon={<Adb />} />

			<Button variant="outlined" color="secondary" onClick={checkBridgeMethods}>
				{"Check methods"}
			</Button>

			<Stack spacing={2}>
				{Array.from(bridgeMethods).map(([key, value]) => (
					<Stack direction="row" spacing={2} key={key}>
						<Typography variant="body1">{key}</Typography>
						<Typography color={value ? "success" : "error"} variant="body1">
							{value ? "✔" : "❌"}
						</Typography>
					</Stack>
				))}
			</Stack>
		</>
	);
};

export default DiagnosticBridge;
