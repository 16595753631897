import { ChromeReaderMode, LocationOn } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { BridgeResult } from "../../../types/reactNative/bridgeOutput";
import AdminDivider from "../../Layout/Divider/AdminDivider";
import "../Admin.scss";

interface DiagnosticStripeProps {
	stripeLocationId: string | null;
	username: string;
	password: string;
	_setStripeLocationId: (stripeLocationId: string | null, isTest?: boolean) => Promise<BridgeResult>;
	_setUsername: (username: string, isTest?: boolean) => Promise<BridgeResult>;
	_setPassword: (password: string, isTest?: boolean) => Promise<BridgeResult>;
	_collectUsbPayment: (piClientSecret: string, isTest?: boolean) => Promise<BridgeResult>;
	_cancelUsbPayment: (isTest?: boolean) => Promise<BridgeResult>;
	_testUsbStripeReader: (isTest?: boolean) => Promise<BridgeResult>;
}

interface StripeLocation {
	country: string;
	name: string;
	live: string;
	test: string;
}

const DiagnosticStripe = ({
	stripeLocationId,
	username,
	password,
	_setStripeLocationId,
	_setUsername,
	_setPassword,
	_cancelUsbPayment,
	_collectUsbPayment,
	_testUsbStripeReader
}: DiagnosticStripeProps) => {
	const handleDisconnectReader = useCallback(async () => {
		await _setUsername("");
		await _setPassword("");
		await _setStripeLocationId(null);
	}, [_setUsername, _setPassword, _setStripeLocationId]);

	const locations: StripeLocation[] = useMemo(() => {
		return [
			{ country: "AT", live: "tml_F1GAwrqG1NhfOq", test: "tml_F2CvfQPoQTFlQu", name: "Austria" },
			{ country: "BE", live: "tml_FzrVNgyz512J8T", test: "tml_FzrVlAxqzpu8nE", name: "Belgium" },
			{ country: "CH", live: "tml_F2CoeQyfcHMXuB", test: "tml_F2CwvABHreMEBZ", name: "Switzerland" },
			{ country: "CZ", live: "tml_F1GlQuciDKF99E", test: "tml_F2CvswFwfwJgQ1", name: "Czechia" },
			{ country: "DE", live: "tml_Fu37qARmfImiqV", test: "tml_Fu3e3wq59z8mQV", name: "Germany" },
			{ country: "DK", live: "tml_F1HAQE3ZfXZzGT", test: "tml_F2Cv3AokhzUgp5", name: "Denmark" },
			{ country: "ES", live: "tml_Fu37NwHbR8bNkI", test: "tml_Fu3gA2NtJ322sc", name: "Spain" },
			{ country: "FI", live: "tml_F2CgwCPKp2XOLv", test: "tml_F2CwHALoicKVvp", name: "Finland" },
			{ country: "FR", live: "tml_Fu37awUFJSg7N6", test: "tml_Fu3fOgQ7O56F6p", name: "France" },
			{ country: "IE", live: "tml_F2CUBg9qkZJWjh", test: "tml_F2CwAwqktPWpZC", name: "Ireland" },
			{ country: "IT", live: "tml_Fu38dw5A298UBd", test: "tml_FuXgwT641d8Gmm", name: "Italy" },
			{ country: "LU", live: "tml_F2Cibwxcd8iDeB", test: "tml_F2CwQALs3ODTMF", name: "Luxembourg" },
			{ country: "NL", live: "tml_Fu38KQMJPTJEuY", test: "tml_FuYeZw2hivGvaD", name: "Netherlands" },
			{ country: "NO", live: "tml_F2Ci7g38xZuidx", test: "tml_F2CwXA498AwqPw", name: "Norway" },
			{ country: "PT", live: "tml_F2CndgfqLN79Ns", test: "tml_F2CwewcRiVrgXO", name: "Portugal" },
			{ country: "SE", live: "tml_F2Cn6g4fQ2X30N", test: "tml_F2CwngJObh1s4c", name: "Sweden" },
			{ country: "GB", live: "tml_Fu361AHxmPldng", test: "tml_Fu3hmgYB1DLJ20", name: "United Kingdom" }
		];
	}, []);

	return (
		<>
			<AdminDivider label={"Stripe reader"} icon={<ChromeReaderMode />} />
			<Stack direction="row" spacing={2}>
				<Typography variant="body1">{"Stripe Location ID: "}</Typography>
				<Typography variant="body1">{stripeLocationId}</Typography>
			</Stack>
			<Stack direction="row" spacing={2}>
				<Typography variant="body1">{"Username: "}</Typography>
				<Typography variant="body1">{username}</Typography>
			</Stack>
			<Stack direction="row" spacing={2}>
				<Typography variant="body1">{"Password: "}</Typography>
				<Typography variant="body1">{password}</Typography>
			</Stack>

			<AdminDivider label={"Try Reader locations"} icon={<LocationOn />} />
			<Stack spacing={2}>
				{locations.map((location) => (
					<Stack key={location.country} direction="row" spacing={2} alignItems="center">
						<Button
							variant="outlined"
							color="secondary"
							onClick={async () => {
								await _setUsername("00001");
								await _setPassword("test");
								await _setStripeLocationId(location.test);
							}}
						>
							{"TEST MODE"}
						</Button>
						<Button
							variant="outlined"
							color="secondary"
							onClick={async () => {
								await _setUsername("00001");
								await _setPassword("live");
								await _setStripeLocationId(location.live);
							}}
						>
							{"TEST LIVE"}
						</Button>
						<Typography variant="h6">{location.name}</Typography>
					</Stack>
				))}

				<Button variant="outlined" color="secondary" onClick={handleDisconnectReader}>
					{"Disconnect Reader"}
				</Button>
			</Stack>
		</>
	);
};

export default DiagnosticStripe;
