import { Box } from "@mui/material";
import { ImgHTMLAttributes, memo, useEffect, useState } from "react";
import { useCdnAssets } from "../../../hooks/useCdnAssets";
import { useTheme } from "../../Theme/ThemeWrapper";

interface ProductImageProps extends ImgHTMLAttributes<HTMLImageElement> {
	imageName: string;
	parentCategoryIcon?: string;
	iconColor: string;
	iconFull?: boolean;
}

const ProductImage = ({ imageName, parentCategoryIcon, iconColor, iconFull, ...rest }: ProductImageProps) => {
	const { restaurantId } = useTheme();
	const { getProductImageUrl, getSystemIconUrl } = useCdnAssets();

	const imgUrl: string | null = getProductImageUrl(imageName, restaurantId);
	const iconUrl: string = getSystemIconUrl(parentCategoryIcon);

	const initialImgSrc: string = imgUrl === null ? iconUrl : imgUrl;
	const initialImgIsIcon: boolean = imgUrl === null ? true : false;

	const [imgSrc, setImgSrc] = useState<string>(initialImgSrc);
	const [imgIsIcon, setImgIsIcon] = useState<boolean>(initialImgIsIcon);

	useEffect(() => {
		const img = new Image();
		img.src = imgSrc as string;

		img.addEventListener("load", () => {
			if (imgIsIcon) {
				setImgSrc(imgSrc);
			}
		});
		img.addEventListener("error", () => {
			if (!imgIsIcon) {
				setImgSrc(iconUrl);
				setImgIsIcon(true);
			}
		});
	}, [imgSrc, imgIsIcon, iconUrl]);

	return imgIsIcon ? (
		<Box
			title="icon"
			sx={{
				mask: `url(${imgSrc}) no-repeat center / ${iconFull ? "100%" : "50%"}`,
				backgroundColor: iconColor,
				display: "block"
			}}
			{...rest}
		>
			{iconFull ? (
				<Box
					sx={{
						width: rest.width,
						height: rest.height
					}}
				/>
			) : null}
		</Box>
	) : (
		<Box
			component="img"
			src={imgSrc}
			sx={{
				width: "auto !important",
				overflow: "hidden",
				objectFit: "contain"
			}}
			{...rest}
		/>
	);
};

export default memo(ProductImage);
