/**
 * Enum for goods type
 *
 * goods is the goods type
 * services is the services type
 */
export enum GoodType {
	goods = "goods",
	services = "services"
}

/**
 * Enum for item info typology
 *
 * plu is the PLU
 * cond_add is the condiment addiction (e.g. extra cheese)
 * cond_sub is the condiment subtraction (e.g. no cheese)
 * cook is the cooking instruction (e.g. rare, medium, well done)
 */
export enum ItemInfoTypology {
	plu = "plu",
	cond_add = "cond_add",
	cond_sub = "cond_sub",
	cook = "cook"
}

/**
 * Enum for barcode typology
 *
 * plu is the PLU
 * weight is the weight
 */
export enum BarcodeTypology {
	plu = "plu",
	weight = "weight",
	openPrice = "openPrice"
}

/*
 * Enum for item linked list mode
 *
 * add is the addiction mode
 * sub is the subtraction mode
 */
export enum ItemLinkedListMode {
	add = "add",
	sub = "sub"
}

/**
 * Enum for item linked list item mode
 *
 * subMultiple is the multiple choice
 * subSingle is the single choice
 */
export enum ItemLinkedListItemMode {
	subMultiple = "sub-multiple",
	subSingle = "sub-single"
}
