/**
 * Enum for SmartTde error codes
 * It could be used to handle the error codes returned by the SmartTde API.
 */
export enum DrawerErrorCode {
	OFFLINE = 8197,
	MALFUNCTION = 8198,
	TIMEOUT = 8199,
	TRY_AGAIN_LATER = 8200,
	INVALID_PASSWORD = 8201,
	INVALID_PARAMETERS = 8202,
	INVALID_SEQUENCE = 8203,
	OUT_OF_RANGE = 8204,
	RUNNING_TRANSACTION = 8205,
	INVALID_COMMAND = 8206
}
