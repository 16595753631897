import { useBridge } from "@webview-bridge/react";
import { useCallback, useMemo } from "react";
import { defaultFirmwareVersion, defaultRnAppVersion, defaultV1AppVersion } from "../constants/defaults";
import { AvailableRelease, bridge, BridgeResult, BridgeResultLegacy, initialBridge } from "../types/reactNative/bridgeOutput";

/**
 * Custom hook to call a bridge method
 *
 */
export const useBridgeWrapper = () => {
	const isBridgeNewFormat = useBridge(bridge.store, (state) => state.isBridgeNewFormat);
	const isBridgeActive = useBridge(bridge.store, (state) => state.bridgeActive);
	const stripeLocationId = useBridge(bridge.store, (state) => state.data.stripeLocationId);
	const isInternetReachable = useBridge(bridge.store, (state) => state.data.isInternetReachable);
	const username = useBridge(bridge.store, (state) => state.data.username);
	const password = useBridge(bridge.store, (state) => state.data.password);
	const webviewUrl = useBridge(bridge.store, (state) => state.data.webviewUrl);
	const webviewHasCloseButton = useBridge(bridge.store, (state) => state.data.webviewHasCloseButton);
	const webviewReferrer = useBridge(bridge.store, (state) => state.data.webviewReferrer);
	const appVersion = useBridge(bridge.store, (state) => state.data.appVersion);
	const logFileName = useBridge(bridge.store, (state) => state.data.logFileName);
	const uspForceScannerActive = useBridge(bridge.store, (state) => state.data.uspForceScannerActive);
	const uspForceFanActive = useBridge(bridge.store, (state) => state.data.uspForceFanActive);
	const uspForceUpdateActive = useBridge(bridge.store, (state) => state.data.uspForceUpdateActive);
	const uspBinaryPath = useBridge(bridge.store, (state) => state.data.uspBinaryPath);
	const uspForceStatusOutdated = useBridge(bridge.store, (state) => state.data.uspForceStatusOutdated);
	const uspForceLedColor = useBridge(bridge.store, (state) => state.data.uspForceLedColor);
	const uspFirmwareVersion = useBridge(bridge.store, (state) => state.data.uspFirmwareVersion);
	const uspUid = useBridge(bridge.store, (state) => state.data.uspUid);
	const uspStatuses = useBridge(bridge.store, (state) => state.data.uspStatuses);

	const hasBridge = useMemo(() => bridge.isWebViewBridgeAvailable && isBridgeActive, [isBridgeActive]);
	const currentStripeLocationId = useMemo(() => (hasBridge ? stripeLocationId : initialBridge.data.stripeLocationId), [stripeLocationId, hasBridge]);
	const currentIsInternetReachable: boolean = useMemo(() => (hasBridge ? isInternetReachable : true), [hasBridge, isInternetReachable]);
	const currentUsername = useMemo(() => (hasBridge ? username : initialBridge.data.username), [username, hasBridge]);
	const currentPassword = useMemo(() => (hasBridge ? password : initialBridge.data.password), [password, hasBridge]);
	const currentWebviewUrl = useMemo(() => (hasBridge ? webviewUrl : initialBridge.data.webviewUrl), [webviewUrl, hasBridge]);
	const currentWebviewHasCloseButton = useMemo(
		() => (hasBridge ? webviewHasCloseButton : initialBridge.data.webviewHasCloseButton),
		[webviewHasCloseButton, hasBridge]
	);
	const currentWebviewReferrer = useMemo(() => (hasBridge ? webviewReferrer : initialBridge.data.webviewReferrer), [webviewReferrer, hasBridge]);
	const currentAppVersion = useMemo(() => (hasBridge ? (appVersion ?? defaultRnAppVersion) : defaultV1AppVersion), [appVersion, hasBridge]);
	const currentLogFileName = useMemo(() => (hasBridge ? logFileName : initialBridge.data.logFileName), [logFileName, hasBridge]);
	const currentUspForceScannerActive = useMemo(
		() => (hasBridge ? uspForceScannerActive : initialBridge.data.uspForceScannerActive),
		[uspForceScannerActive, hasBridge]
	);
	const currentUspForceFanActive = useMemo(() => (hasBridge ? uspForceFanActive : initialBridge.data.uspForceFanActive), [uspForceFanActive, hasBridge]);
	const currentUspForceUpdateActive = useMemo(
		() => (hasBridge ? uspForceUpdateActive : initialBridge.data.uspForceUpdateActive),
		[uspForceUpdateActive, hasBridge]
	);
	const currentUspBinaryPath = useMemo(() => (hasBridge ? uspBinaryPath : initialBridge.data.uspBinaryPath), [uspBinaryPath, hasBridge]);
	const currentUspForceStatusOutdated = useMemo(
		() => (hasBridge ? uspForceStatusOutdated : initialBridge.data.uspForceStatusOutdated),
		[uspForceStatusOutdated, hasBridge]
	);
	const currentUspForceLedColor = useMemo(() => (hasBridge ? uspForceLedColor : initialBridge.data.uspForceLedColor), [uspForceLedColor, hasBridge]);
	const currentUspFirmwareVersion = useMemo(
		() => (hasBridge ? (uspFirmwareVersion ?? defaultFirmwareVersion) : defaultFirmwareVersion),
		[uspFirmwareVersion, hasBridge]
	);
	const currentUspUid = useMemo(() => (hasBridge ? (uspUid ?? "") : ""), [uspUid, hasBridge]);
	const currentUspStatuses = useMemo(() => (hasBridge ? (uspStatuses ?? "") : ""), [uspStatuses, hasBridge]);
	const uspStatusFront: boolean = useMemo(() => (currentUspStatuses ? currentUspStatuses[0] === "1" : false), [currentUspStatuses]);
	const uspStatusProximity: boolean = useMemo(() => (currentUspStatuses ? currentUspStatuses[1] === "1" : false), [currentUspStatuses]);
	const uspStatusScanner: boolean = useMemo(() => (currentUspStatuses ? currentUspStatuses[2] === "1" : false), [currentUspStatuses]);
	const uspStatusFan: boolean = useMemo(() => (currentUspStatuses ? currentUspStatuses[3] === "1" : false), [currentUspStatuses]);

	const _collectUsbPayment = useCallback(
		async (piClientSecret: string, isTest?: boolean): Promise<BridgeResult> => {
			let result: BridgeResult = { success: false, fallback: true, errorMessage: "_collectUsbPayment not supported" };

			if (hasBridge) {
				if (isBridgeNewFormat) {
					const response: BridgeResult = await bridge._collectUsbPayment(piClientSecret, isTest);
					if (response) result = response;
				} else {
					const response: BridgeResultLegacy = await bridge.collectUsbPayment(piClientSecret);
					if (response) result = { success: !!response.errorMessage, fallback: false, errorMessage: response.errorMessage };
				}
			}
			return result;
		},
		[hasBridge, isBridgeNewFormat]
	);
	const _cancelUsbPayment = useCallback(
		async (isTest?: boolean): Promise<BridgeResult> => {
			let result: BridgeResult = { success: false, fallback: true, errorMessage: "_cancelUsbPayment not supported" };
			if (hasBridge) {
				if (isBridgeNewFormat) {
					const response: BridgeResult = await bridge._cancelUsbPayment(isTest);
					if (response) result = response;
				} else {
					const response: boolean = await bridge.cancelUsbPayment();
					if (response) result = { success: response, fallback: false };
				}
			}
			return result;
		},
		[hasBridge, isBridgeNewFormat]
	);
	const _testUsbStripeReader = useCallback(
		async (isTest?: boolean): Promise<BridgeResult> => {
			let result: BridgeResult = { success: false, fallback: true, errorMessage: "_testUsbStripeReader not supported" };
			if (hasBridge) {
				if (isBridgeNewFormat) {
					const response: BridgeResult = await bridge._testUsbStripeReader(isTest);
					if (response) result = response;
				} else {
					const response: boolean = await bridge.testUsbStripeReader();
					if (response) result = { success: response, fallback: false };
				}
			}
			return result;
		},
		[hasBridge, isBridgeNewFormat]
	);
	const _persistLog = useCallback(
		async (value: string, isTest?: boolean): Promise<BridgeResult> => {
			let result: BridgeResult = { success: false, fallback: true, errorMessage: "_persistLog not supported" };
			if (hasBridge) {
				if (isBridgeNewFormat) {
					const response: BridgeResult = await bridge._persistLog(value, isTest);
					if (response) result = response;
				} else {
					await bridge.persistLog(value);
					result = { success: true, fallback: false };
				}
			}
			return result;
		},
		[hasBridge, isBridgeNewFormat]
	);
	const _getLogFilesToUpload = useCallback(
		async (kioskId: number, isTest?: boolean): Promise<BridgeResult & { data: string[] }> => {
			let result: BridgeResult & { data: string[] } = { success: false, fallback: true, errorMessage: "_getLogFilesToUpload not supported", data: [] };
			if (hasBridge) {
				if (isBridgeNewFormat) {
					const response: BridgeResult & { data: string[] } = await bridge._getLogFilesToUpload(kioskId, isTest);
					if (response) result = response;
				} else {
					const response: string[] = await bridge.getLogFilesToUpload(kioskId);
					result = { success: true, fallback: false, data: response };
				}
			}
			return result;
		},
		[hasBridge, isBridgeNewFormat]
	);
	const _getLogLastLines = useCallback(
		async (isTest?: boolean): Promise<BridgeResult & { data: string }> => {
			let result: BridgeResult & { data: string } = { success: false, fallback: true, errorMessage: "_getLogLastLines not supported", data: "" };
			if (hasBridge) {
				const response: BridgeResult & { data: string } = await bridge._getLogLastLines(isTest);
				if (response) result = response;
			}
			return result;
		},
		[hasBridge]
	);
	const _uploadLogFiles = useCallback(
		async (kioskId: number, logFiles: string[], isTest?: boolean): Promise<BridgeResult> => {
			let result: BridgeResult = { success: false, fallback: true, errorMessage: "_uploadLogFiles not supported" };
			if (hasBridge) {
				if (isBridgeNewFormat) {
					const response: BridgeResult = await bridge._uploadLogFiles(kioskId, logFiles, isTest);
					if (response) result = response;
				} else {
					await bridge.uploadLogFiles(kioskId, logFiles);
					result = { success: true, fallback: false };
				}
			}
			return result;
		},
		[hasBridge, isBridgeNewFormat]
	);
	const _copyLogFilesToDocuments = useCallback(
		async (isTest?: boolean): Promise<BridgeResult> => {
			let result: BridgeResult = { success: false, fallback: true, errorMessage: "_copyLogFilesToDocuments not supported" };
			if (hasBridge) {
				if (isBridgeNewFormat) {
					const response: BridgeResult = await bridge._copyLogFilesToDocuments(isTest);
					if (response) result = response;
				} else {
					await bridge.copyLogFilesToDocuments();
					result = { success: true, fallback: false };
				}
			}
			return result;
		},
		[hasBridge, isBridgeNewFormat]
	);
	const _changeWebviewUrl = useCallback(
		async (url?: string, hasCloseButton?: boolean, isTest?: boolean): Promise<BridgeResult> => {
			let result: BridgeResult = { success: false, fallback: true, errorMessage: "_changeWebviewUrl not supported" };
			if (hasBridge) {
				if (isBridgeNewFormat) {
					const response: BridgeResult = await bridge._changeWebviewUrl(url, hasCloseButton, isTest);
					if (response) result = response;
				} else {
					await bridge.changeWebviewUrl(url ?? "", hasCloseButton);
					result = { success: true, fallback: false };
				}
			}
			return result;
		},
		[hasBridge, isBridgeNewFormat]
	);
	const _checkUpdate = useCallback(
		async (token: string, isTest?: boolean): Promise<BridgeResult & { data: AvailableRelease[] }> => {
			let result: BridgeResult & { data: AvailableRelease[] } = { success: false, fallback: true, errorMessage: "_checkUpdate not supported", data: [] };
			if (hasBridge) {
				if (isBridgeNewFormat) {
					const response: BridgeResult & { data: AvailableRelease[] } = await bridge._checkUpdate(token, isTest);
					if (response) result = response;
				} else {
					const response: AvailableRelease[] = await bridge.checkUpdate(token);
					result = { success: true, fallback: false, data: response };
				}
			}
			return result;
		},
		[hasBridge, isBridgeNewFormat]
	);
	const _installUpdate = useCallback(
		async (token: string, release: AvailableRelease, isTest?: boolean): Promise<BridgeResult> => {
			let result: BridgeResult = { success: false, fallback: true, errorMessage: "_installUpdate not supported" };
			if (hasBridge) {
				if (isBridgeNewFormat) {
					const response: BridgeResult = await bridge._installUpdate(token, release, isTest);
					if (response) result = response;
				} else {
					await bridge.installUpdate(token, release);
					result = { success: true, fallback: false };
				}
			}
			return result;
		},
		[hasBridge, isBridgeNewFormat]
	);
	const _openAnyDesk = useCallback(
		async (isTest?: boolean): Promise<BridgeResult> => {
			let result: BridgeResult = { success: false, fallback: true, errorMessage: "_openAnyDesk not supported" };
			if (hasBridge) {
				if (isBridgeNewFormat) {
					const response: BridgeResult = await bridge._openAnyDesk(isTest);
					if (response) result = response;
				} else {
					await bridge.openAnyDesk();
					result = { success: true, fallback: false };
				}
			}
			return result;
		},
		[hasBridge, isBridgeNewFormat]
	);
	const _openSmartTde = useCallback(
		async (backgroundMode?: boolean, isTest?: boolean): Promise<BridgeResult> => {
			let result: BridgeResult = { success: false, fallback: true, errorMessage: "_openSmartTde not supported" };
			if (hasBridge) {
				const response: BridgeResult = await bridge._openSmartTde(backgroundMode, isTest);
				if (response) result = response;
			}
			return result;
		},
		[hasBridge]
	);
	const _toggleUspScanner = useCallback(
		async (isActive?: boolean, isTest?: boolean): Promise<BridgeResult> => {
			let result: BridgeResult = { success: false, fallback: true, errorMessage: "_toggleUspScanner not supported" };
			if (hasBridge) {
				const response: BridgeResult = await bridge._toggleUspScanner(isActive, isTest);
				if (response) result = response;
			}
			return result;
		},
		[hasBridge]
	);
	const _toggleUspFan = useCallback(
		async (isActive?: boolean, isTest?: boolean): Promise<BridgeResult> => {
			let result: BridgeResult = { success: false, fallback: true, errorMessage: "_toggleUspFan not supported" };
			if (hasBridge) {
				const response: BridgeResult = await bridge._toggleUspFan(isActive, isTest);
				if (response) result = response;
			}
			return result;
		},
		[hasBridge]
	);
	const _checkUspStatus = useCallback(
		async (isCheckDone?: boolean, isTest?: boolean): Promise<BridgeResult> => {
			let result: BridgeResult = { success: false, fallback: true, errorMessage: "_checkUspStatus not supported" };
			if (hasBridge) {
				const response: BridgeResult = await bridge._checkUspStatus(isCheckDone, isTest);
				if (response) result = response;
			}
			return result;
		},
		[hasBridge]
	);
	const _changeUspLedColor = useCallback(
		async (color: number, isTest?: boolean): Promise<BridgeResult> => {
			let result: BridgeResult = { success: false, fallback: true, errorMessage: "_changeUspLedColor not supported" };
			if (hasBridge) {
				const response: BridgeResult = await bridge._changeUspLedColor(color, isTest);
				if (response) result = response;
			}
			return result;
		},
		[hasBridge]
	);
	const _setStripeLocationId = useCallback(
		async (stripeLocationId: string | null, isTest?: boolean): Promise<BridgeResult> => {
			let result: BridgeResult = { success: false, fallback: true, errorMessage: "_setStripeLocationId not supported" };
			if (hasBridge) {
				if (isBridgeNewFormat) {
					const response: BridgeResult = await bridge._setStripeLocationId(stripeLocationId, isTest);
					if (response) result = response;
				} else {
					await bridge.setStripeLocationId(stripeLocationId);
					result = { success: true, fallback: false };
				}
			}
			return result;
		},
		[hasBridge, isBridgeNewFormat]
	);
	const _setUsername = useCallback(
		async (username: string, isTest?: boolean): Promise<BridgeResult> => {
			let result: BridgeResult = { success: false, fallback: true, errorMessage: "_setUsername not supported" };
			if (hasBridge) {
				if (isBridgeNewFormat) {
					const response: BridgeResult = await bridge._setUsername(username, isTest);
					if (response) result = response;
				} else {
					await bridge.setUsername(username);
					result = { success: true, fallback: false };
				}
			}
			return result;
		},
		[hasBridge, isBridgeNewFormat]
	);
	const _setPassword = useCallback(
		async (password: string, isTest?: boolean): Promise<BridgeResult> => {
			let result: BridgeResult = { success: false, fallback: true, errorMessage: "_setPassword not supported" };
			if (hasBridge) {
				if (isBridgeNewFormat) {
					const response: BridgeResult = await bridge._setPassword(password, isTest);
					if (response) result = response;
				} else {
					await bridge.setPassword(password);
					result = { success: true, fallback: false };
				}
			}
			return result;
		},
		[hasBridge, isBridgeNewFormat]
	);
	const _checkFirmwareUpdate = useCallback(
		async (token: string, isTest?: boolean): Promise<BridgeResult & { data: AvailableRelease[] }> => {
			let result: BridgeResult & { data: AvailableRelease[] } = {
				success: false,
				fallback: true,
				errorMessage: "_checkFirmwareUpdate not supported",
				data: []
			};
			if (hasBridge) {
				const response: BridgeResult & { data: AvailableRelease[] } = await bridge._checkFirmwareUpdate(token, isTest);
				if (response) result = response;
			}
			return result;
		},
		[hasBridge]
	);
	const _installFirmwareUpdate = useCallback(
		async (token: string, release: AvailableRelease, isTest?: boolean): Promise<BridgeResult> => {
			let result: BridgeResult = { success: false, fallback: true, errorMessage: "_installFirmwareUpdate not supported" };
			if (hasBridge) {
				const response: BridgeResult = await bridge._installFirmwareUpdate(token, release, isTest);
				if (response) result = response;
			}
			return result;
		},
		[hasBridge]
	);

	return {
		hasBridge: hasBridge,
		stripeLocationId: currentStripeLocationId,
		isInternetReachable: currentIsInternetReachable,
		username: currentUsername,
		password: currentPassword,
		webviewUrl: currentWebviewUrl,
		webviewHasCloseButton: currentWebviewHasCloseButton,
		webviewReferrer: currentWebviewReferrer,
		appVersion: currentAppVersion,
		logFileName: currentLogFileName,
		uspForceScannerActive: currentUspForceScannerActive,
		uspForceFanActive: currentUspForceFanActive,
		uspForceUpdateActive: currentUspForceUpdateActive,
		uspBinaryPath: currentUspBinaryPath,
		uspForceStatusOutdated: currentUspForceStatusOutdated,
		uspForceLedColor: currentUspForceLedColor,
		uspFirmwareVersion: currentUspFirmwareVersion,
		uspUid: currentUspUid,
		uspStatuses: currentUspStatuses,
		uspStatusFront,
		uspStatusProximity,
		uspStatusScanner,
		uspStatusFan,
		_collectUsbPayment,
		_cancelUsbPayment,
		_testUsbStripeReader,
		_persistLog,
		_getLogFilesToUpload,
		_getLogLastLines,
		_uploadLogFiles,
		_copyLogFilesToDocuments,
		_changeWebviewUrl,
		_checkUpdate,
		_installUpdate,
		_openAnyDesk,
		_openSmartTde,
		_toggleUspScanner,
		_toggleUspFan,
		_checkUspStatus,
		_changeUspLedColor,
		_setStripeLocationId,
		_setUsername,
		_setPassword,
		_checkFirmwareUpdate,
		_installFirmwareUpdate
	};
};
