import { Box, Stack, TableCell, TableRow, Typography, alpha } from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useCurrency } from "../../../hooks/useCurrency";
import { CartItem } from "../../../types";
import i18n from "../../../utils/i18n/i18n";
import { tItemInfo } from "../../../utils/i18nMenu";
import CounterButtonGroup from "../../Layout/Buttons/CounterButtonGroup";
import TouchButton from "../../Layout/Buttons/TouchButton";
import ProductImage from "../../Layout/Images/ProductImage";
import { useMessage } from "../../MessageHandler/MessageService";
import { useTheme } from "../../Theme/ThemeWrapper";
import "./OrderReviewDialog.scss";
import "./OrderReviewRow.scss";
import OrderReviewRowOptions from "./OrderReviewRowOptions";

interface OrderReviewRowProps {
	cartItem: CartItem;
	clickRow: () => void;
	addItemToCart: (cartItem: CartItem) => void;
	removeItemFromCart: (uid: string) => void;
	hideCustomize?: boolean;
}

const OrderReviewRow = ({ cartItem, clickRow, addItemToCart, removeItemFromCart, hideCustomize }: OrderReviewRowProps) => {
	const { theme, settings } = useTheme();
	const { t } = useTranslation();
	const message = useMessage();
	const { currency } = useCurrency();
	const showDetails: boolean = !hideCustomize && !settings.cardHideLinkToDetails;

	const increaseQuantity = useCallback((): void => {
		const newCartItem: CartItem = { ...cartItem };
		newCartItem.quantity = 1;
		addItemToCart(newCartItem);
	}, [addItemToCart, cartItem]);

	const decreaseQuantity = useCallback((): void => {
		const newCartItem: CartItem = { ...cartItem };
		if (cartItem.quantity === 1) {
			message({
				title: t("checkout.orderReview.delete").toUpperCase(),
				description: t("checkout.orderReview.deleteConfirm"),
				okCallback: () => removeItemFromCart(cartItem.uid),
				okLabel: t("common.yes").toUpperCase(),
				cancelCallback: () => {},
				cancelLabel: t("common.cancel").toUpperCase()
			});
		} else {
			newCartItem.quantity = -1;
			addItemToCart(newCartItem);
		}
	}, [addItemToCart, cartItem, message, removeItemFromCart, t]);

	return (
		<TableRow className="cartRow" /* onClick={clickRow}*/ sx={{ borderColor: alpha(theme.systemTheme.palette.grey[300], 0.8) }}>
			<TableCell className="col-desc" colSpan={7}>
				<Stack direction="row" spacing="1rem" alignItems="center">
					{settings.showImages ? (
						<ProductImage
							imageName={cartItem.item.itemInfo.imageName}
							parentCategoryIcon={cartItem.item.parentCategoryIcon}
							height="4.375rem"
							width="4.375rem"
							alt={cartItem.item.itemInfo.imageName}
							iconColor={theme.customTheme.palette.primary.main}
							iconFull={true}
						/>
					) : null}
					<Box>
						<Typography variant="caption" fontSize="1.5rem" className="itemTitle">
							{tItemInfo(i18n.language, cartItem.item.itemInfo, "shortText")}
						</Typography>
						<Typography component="div" variant="body2">
							{cartItem.options.length !== 0 && <OrderReviewRowOptions options={cartItem.options} />}
						</Typography>
						{showDetails ? (
							<TouchButton onClick={clickRow} sx={{ p: 0 }}>
								<Typography variant="body2" color="secondary" className="underline">
									{t("checkout.orderReview." + (cartItem.item.hasFields ? "customize" : "info"))}
								</Typography>
							</TouchButton>
						) : null}
					</Box>
				</Stack>
			</TableCell>
			<TableCell className="col-button" colSpan={3}>
				<CounterButtonGroup counter={cartItem.quantity} isRemover onDecrement={decreaseQuantity} onIncrement={increaseQuantity} tone="cart" />
			</TableCell>
			<TableCell className="col-price" colSpan={2} align="right">
				<Typography>{currency(cartItem.resultingPrice * cartItem.quantity)}</Typography>
			</TableCell>
		</TableRow>
	);
};

export default memo(OrderReviewRow);
