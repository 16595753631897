import { DoubleArrow, HighlightOffRounded } from "@mui/icons-material";
import { Box, LinearProgress, Skeleton, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { LogChannel, PaymentMethod, PaymentStepDrawer, PaymentStepServer, stripePaymentMethods } from "../../../../constants";
import { defaultPaymentIntentResponse } from "../../../../constants/defaults";
import { useCurrency } from "../../../../hooks/useCurrency";
import { useLogger } from "../../../../hooks/useLogger";
import { ElectronicPayment, ElectronicPaymentSatispay, ElectronicPaymentStripe } from "../../../../services/4Delivery";
import { Cart, Order, PaymentInfo, PaymentIntentResponse } from "../../../../types";
import { TransactionStatusDrawer } from "../../../../types/SmartTde";
import TouchButton from "../../../Layout/Buttons/TouchButton";
import { useTheme } from "../../../Theme/ThemeWrapper";
import ConfirmModal from "../Wrappers/ConfirmModal";
import ActivateReaderRN from "./PaymentStep/ActivateReaderRN";
import CancelCashTransaction from "./PaymentStep/CancelCashTransaction";
import CancelIntent from "./PaymentStep/CancelIntent";
import CheckCashSystem from "./PaymentStep/CheckCashSystem";
import CheckCashTransaction from "./PaymentStep/CheckCashTransaction";
import CheckDrawer from "./PaymentStep/CheckDrawer";
import CheckIntent from "./PaymentStep/CheckIntent";
import CheckReaderRN from "./PaymentStep/CheckReaderRN";
import CreateCashTransaction from "./PaymentStep/CreateCashTransaction";
import CreateIntent from "./PaymentStep/CreateIntent";
import CreateIntentAndActivateReader from "./PaymentStep/CreateIntentAndActivateReader";

interface PaymentModalProps {
	cart: Cart;
	order: Order;
	confirmPayment: (paymentInfo: PaymentInfo, kioskCartId: number | null) => void;
	cancelPayment: () => void;
	confirmCashPayment: (transactionStatusDrawer: TransactionStatusDrawer) => void;
}

const PaymentModal = ({ cart, order, confirmPayment, cancelPayment, confirmCashPayment }: PaymentModalProps) => {
	const { t } = useTranslation();
	const { currency } = useCurrency();
	const { theme, settings } = useTheme();
	const { log } = useLogger();

	const isOneOFStripePayments: boolean = useMemo(() => stripePaymentMethods.includes(order.paymentMethod), [order.paymentMethod]);
	const isStripeRnPayment: boolean = useMemo(() => order.paymentMethod === PaymentMethod.STRIPE_RN, [order.paymentMethod]);
	const isDrawerPayment: boolean = useMemo(() => order.paymentMethod === PaymentMethod.CASH_DRAWER, [order.paymentMethod]);
	const isSatispayPayment: boolean = useMemo(() => order.paymentMethod === PaymentMethod.SATISPAY, [order.paymentMethod]);

	const firstPaymentStep = useMemo(
		() =>
			isDrawerPayment
				? PaymentStepDrawer.checkingDrawer
				: isStripeRnPayment
					? PaymentStepServer.checkingReaderRN
					: PaymentStepServer.creatingIntentAndActivateReader,
		[isDrawerPayment, isStripeRnPayment]
	);

	// check the cash system only if it is enabled, otherwise go to the first payment step
	const initialPaymentStep = useMemo(
		() =>
			settings.isCashSystemEnabled ? (isDrawerPayment ? PaymentStepDrawer.checkingCashSystem : PaymentStepServer.checkingCashSystem) : firstPaymentStep,
		[isDrawerPayment, firstPaymentStep, settings.isCashSystemEnabled]
	);

	const electronicPayment: ElectronicPayment | null = useMemo(
		() =>
			isDrawerPayment
				? null
				: isOneOFStripePayments
					? new ElectronicPaymentStripe(isStripeRnPayment ? undefined : settings.terminalSerialNumber)
					: new ElectronicPaymentSatispay(),
		[isOneOFStripePayments, isStripeRnPayment, settings.terminalSerialNumber, isDrawerPayment]
	);

	const [requestStatus, setRequestStatus] = useState<PaymentStepServer | PaymentStepDrawer>(initialPaymentStep);
	const [paymentIntent, setPaymentIntent] = useState<PaymentIntentResponse>(defaultPaymentIntentResponse);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isCanceling, setIsCanceling] = useState<boolean>(false);
	const [isConnecting, setConnecting] = useState<boolean>(false);
	const [textWarning, setTextWarning] = useState<string>();
	const [cashTransaction, setCashTransaction] = useState<TransactionStatusDrawer | null>(null);
	const [cashTimeLeft, setCashTimeLeft] = useState<number>(0);

	const cashTransactionValues = useMemo(() => {
		if (cashTransaction === null) return { amount: 0, inserted: 0, remain: 0, change: 0, notDispensed: 0, dispensed: 0 };
		const change = cashTransaction.to_pay < 0 ? Math.abs(cashTransaction.to_pay) : 0;
		return {
			inserted: cart.amount - cashTransaction.to_pay,
			remain: cashTransaction.to_pay < 0 ? 0 : cashTransaction.to_pay,
			change: change,
			notDispensed: cashTransaction.non_erogato > 0 ? cashTransaction.non_erogato : 0
		};
	}, [cashTransaction, cart.amount]);

	const resetPaymentIntent = () => setPaymentIntent(defaultPaymentIntentResponse);

	const handleCancelButton = () => setRequestStatus(isDrawerPayment ? PaymentStepDrawer.cancelingCashTransaction : PaymentStepServer.cancelingIntent);

	const timeConverter = useCallback((time: number): string => {
		const minutes = Math.floor(time / 60);
		const seconds = time % 60;
		return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
	}, []);

	useEffect(() => {
		if (cashTransaction !== null && cashTimeLeft > 0) {
			const interval = setInterval(() => {
				if (cashTimeLeft <= 0) {
					clearInterval(interval);
					return;
				}
				setCashTimeLeft((prev) => prev - 1);
			}, 1000);

			return () => clearInterval(interval);
		}
	}, [cashTimeLeft, cashTransaction]);

	useEffect(() => {
		log(`${requestStatus}`, LogChannel.payment);
	}, [requestStatus, log]);

	return (
		<>
			<ConfirmModal
				outsideNode={
					isStripeRnPayment && !textWarning && !isCanceling ? (
						<DoubleArrow
							className="embeddedReaderPointer animated"
							sx={{
								fontSize: "20rem",
								color: theme.customTheme.palette.background.default,
								backgroundColor: theme.customTheme.palette.secondary.main,
								borderRadius: "50%",
								transform: "rotate(90deg)"
							}}
						/>
					) : undefined
				}
			>
				<Box>
					{isOneOFStripePayments && (
						<>
							<Box>
								<Typography color="primary" variant="h4" textAlign="center" sx={{ marginTop: "1rem" }}>
									{isConnecting ? "" : t("checkout.paymentModal.total").toUpperCase() + " " + currency(cart.amount)}
								</Typography>
								<Typography color="default" variant="h4" textAlign="center" sx={{ marginTop: "1rem" }}>
									{textWarning
										? textWarning
										: isCanceling
											? t("checkout.paymentModal.cancelling") + t("common.ellipsis")
											: isConnecting
												? t("checkout.paymentModal.waitPlease") + t("common.ellipsis")
												: t("checkout.paymentModal.instructions").toUpperCase()}
								</Typography>
							</Box>
							{!textWarning && !isCanceling && !isStripeRnPayment && <DoubleArrow sx={{ fontSize: "20rem" }} />}

							<LinearProgress
								sx={{ width: "100%", marginTop: "2rem", paddingTop: ".5rem", opacity: isLoading || isConnecting ? 1 : 0 }}
								color="secondary"
							/>
						</>
					)}
					{isSatispayPayment && (
						<>
							<Box>
								<Typography color="primary" variant="h4" textAlign="center" sx={{ marginTop: "1rem" }}>
									{t("checkout.paymentModal.total").toUpperCase() + " " + currency(cart.amount)}
								</Typography>
								<Typography color="default" variant="h4" textAlign="center" sx={{ marginTop: "1rem" }}>
									{textWarning
										? textWarning
										: isCanceling
											? t("checkout.paymentModal.cancelling")
											: t("checkout.paymentModal.instructionsSatispay").toUpperCase()}
								</Typography>
							</Box>

							{isLoading && (
								<>
									{paymentIntent !== null && paymentIntent.redirect_url ? (
										<QRCode
											size={240}
											fgColor={theme.systemTheme.palette.text.primary}
											bgColor={theme.systemTheme.palette.background.paper}
											value={paymentIntent.redirect_url}
										/>
									) : (
										<Skeleton variant="rectangular" width={240} height={240} />
									)}
								</>
							)}
						</>
					)}
					{isDrawerPayment && (
						<>
							<Box>
								<Typography color="primary" variant="h4" textAlign="center" sx={{ marginTop: "1rem" }}>
									{isConnecting ? "" : t("checkout.paymentModal.total").toUpperCase() + " " + currency(cart.amount)}
								</Typography>
								<Typography color="default" variant="h4" textAlign="center" sx={{ marginTop: "1rem" }}>
									{textWarning
										? textWarning
										: isCanceling
											? t("checkout.paymentModal.cancelling") + t("common.ellipsis")
											: isConnecting
												? t("checkout.paymentModal.waitPlease") + t("common.ellipsis")
												: t("checkout.paymentModal.instructionsDrawer").toUpperCase()}
								</Typography>

								<hr />
								{cashTransaction ? (
									<Stack direction="column" justifyContent="center" alignItems={"center"} spacing={1} sx={{ marginTop: "1rem" }}>
										{isCanceling ? (
											<Typography>{`${t("checkout.paymentModal.getChange")}: ${currency(cashTransactionValues.inserted)}`}</Typography>
										) : (
											<>
												<Typography>{`${t("checkout.paymentModal.inserted")}: ${currency(cashTransactionValues.inserted)}`}</Typography>
												{cashTransaction.is_closed === false ? (
													<Typography>{`${t("checkout.paymentModal.remain")}: ${currency(cashTransactionValues.remain)}`}</Typography>
												) : null}
												<Typography>{`${t("checkout.paymentModal.change")}: ${currency(cashTransactionValues.change)}`}</Typography>
												{cashTransactionValues.notDispensed > 0 ? (
													<Typography fontWeight="bold">{`${t("checkout.paymentModal.notDispensed")}: ${currency(cashTransactionValues.notDispensed)}`}</Typography>
												) : null}
											</>
										)}
									</Stack>
								) : null}
							</Box>

							{isLoading || isConnecting ? (
								<>
									<LinearProgress sx={{ width: "100%", marginTop: "2rem", paddingTop: ".5rem" }} color="secondary" />
									{cashTransaction && !cashTransaction.is_closed ? <Typography>{`${timeConverter(cashTimeLeft)}`}</Typography> : null}
								</>
							) : null}
						</>
					)}
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center"
					}}
				>
					<TouchButton
						color="error"
						variant="contained"
						size="large"
						startIcon={<HighlightOffRounded />}
						onClick={handleCancelButton}
						disabled={
							isCanceling || (isDrawerPayment ? cashTransaction === null || cashTransaction.is_closed : paymentIntent.kiosk_cart_id === null)
						}
					>
						<Typography variant="button">{t("common.cancel")}</Typography>
					</TouchButton>
				</Box>
			</ConfirmModal>

			{[PaymentStepServer.checkingCashSystem, PaymentStepDrawer.checkingCashSystem].includes(requestStatus) && (
				<CheckCashSystem
					firstPaymentStep={firstPaymentStep}
					setRequestStatus={setRequestStatus}
					setIsLoading={setIsLoading}
					cancelPayment={cancelPayment}
				/>
			)}

			{/* Server driven FLOW */}
			{requestStatus === PaymentStepServer.checkingReaderRN && (
				<CheckReaderRN setRequestStatus={setRequestStatus} setConnecting={setConnecting} cancelPayment={cancelPayment} />
			)}
			{requestStatus === PaymentStepServer.creatingIntent && electronicPayment && (
				<CreateIntent
					order={order}
					cart={cart}
					electronicPayment={electronicPayment}
					paymentIntent={paymentIntent}
					setRequestStatus={setRequestStatus}
					setIsLoading={setIsLoading}
					setPaymentIntent={setPaymentIntent}
					cancelPayment={cancelPayment}
				/>
			)}
			{requestStatus === PaymentStepServer.activateReaderRN && (
				<ActivateReaderRN
					clientSecret={paymentIntent.client_secret ?? ""}
					setRequestStatus={setRequestStatus}
					setIsLoading={setIsLoading}
					cancelPayment={cancelPayment}
				/>
			)}
			{requestStatus === PaymentStepServer.creatingIntentAndActivateReader && electronicPayment && (
				<CreateIntentAndActivateReader
					order={order}
					cart={cart}
					electronicPayment={electronicPayment}
					paymentIntent={paymentIntent}
					setRequestStatus={setRequestStatus}
					setIsLoading={setIsLoading}
					setPaymentIntent={setPaymentIntent}
					cancelPayment={cancelPayment}
				/>
			)}
			{requestStatus === PaymentStepServer.checkingIntent && electronicPayment && (
				<CheckIntent
					order={order}
					paymentIntent={paymentIntent}
					electronicPayment={electronicPayment}
					firstPaymentStep={firstPaymentStep}
					setRequestStatus={setRequestStatus}
					setIsLoading={setIsLoading}
					confirmPayment={confirmPayment}
				/>
			)}
			{requestStatus === PaymentStepServer.cancelingIntent && electronicPayment && (
				<CancelIntent
					order={order}
					cart={cart}
					electronicPayment={electronicPayment}
					paymentIntent={paymentIntent}
					isStripeRnPayment={isStripeRnPayment}
					setIsCanceling={setIsCanceling}
					setIsLoading={setIsLoading}
					setTextWarning={setTextWarning}
					resetPaymentIntent={resetPaymentIntent}
					cancelPayment={cancelPayment}
				/>
			)}

			{/* Drawer FLOW */}
			{requestStatus === PaymentStepDrawer.checkingDrawer && (
				<CheckDrawer setRequestStatus={setRequestStatus} setConnecting={setConnecting} cancelPayment={cancelPayment} />
			)}
			{requestStatus === PaymentStepDrawer.creatingCashTransaction && (
				<CreateCashTransaction
					amount={cart.amount}
					setRequestStatus={setRequestStatus}
					cancelPayment={cancelPayment}
					setIsLoading={setIsLoading}
					setCashTransaction={setCashTransaction}
				/>
			)}
			{requestStatus === PaymentStepDrawer.checkingCashTransaction && (
				<CheckCashTransaction
					firstPaymentStep={firstPaymentStep}
					setRequestStatus={setRequestStatus}
					setIsLoading={setIsLoading}
					cashTransaction={cashTransaction}
					setCashTransaction={setCashTransaction}
					confirmCashPayment={confirmCashPayment}
					setCashTimeLeft={setCashTimeLeft}
				/>
			)}
			{requestStatus === PaymentStepDrawer.cancelingCashTransaction && (
				<CancelCashTransaction
					cancelPayment={cancelPayment}
					setIsCanceling={setIsCanceling}
					setIsLoading={setIsLoading}
					setTextWarning={setTextWarning}
					cashTransaction={cashTransaction}
				/>
			)}
		</>
	);
};
export default PaymentModal;
