import { Android, SystemUpdate, Update } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { AvailableRelease, BridgeResult } from "../../../types/reactNative/bridgeOutput";
import { getMajorVersion } from "../../../utils/version";
import AdminDivider from "../../Layout/Divider/AdminDivider";
import "../Admin.scss";

interface DiagnosticAppProps {
	isInternetReachable: boolean;
	webviewUrl: string;
	appVersion: string;
	_checkUpdate: (token: string, isTest?: boolean) => Promise<BridgeResult & { data: AvailableRelease[] }>;
	_installUpdate: (token: string, release: AvailableRelease, isTest?: boolean) => Promise<BridgeResult>;
	_openAnyDesk: (isTest?: boolean) => Promise<BridgeResult>;
	_openSmartTde: (backgroundMode?: boolean, isTest?: boolean) => Promise<BridgeResult>;
}

const DiagnosticApp = ({ isInternetReachable, webviewUrl, appVersion, _checkUpdate, _installUpdate, _openAnyDesk, _openSmartTde }: DiagnosticAppProps) => {
	// Hardcoded token for testing purposes. Account 00001
	const token =
		"eyJTaXRlSWQiOjE0ODM1LCJTb2Z0d2FyZVZlcnNpb24iOiJzdGFnaW5nIiwiUG9zVWlkIjoiMDAwMDFfMTAwIiwiUHJvZHVjdElkIjoxfS41YjhmMmYxNTg0ZTg0ZmU1ZmUyYjc2NDViMTUyMzQyNjg0YzgzOTkw";

	const currentAppVersionMajor = getMajorVersion(appVersion);
	const [updatesAppFound, setUpdatesAppFound] = useState<AvailableRelease | null>();

	const handleCheckAppUpdates = () => {
		if (currentAppVersionMajor) {
			_checkUpdate(token)
				.then((response: { data: AvailableRelease[] }) => {
					const thisMajorRelease = response.data.find((release: AvailableRelease) => Number(release.major) === currentAppVersionMajor);
					if (!thisMajorRelease) throw new Error("No release found");

					setUpdatesAppFound(thisMajorRelease);
				})
				.catch((error) => {
					setUpdatesAppFound(null);
				});
		}
	};

	const handleDownloadAppUpdate = () => {
		if (updatesAppFound) {
			_installUpdate(token, updatesAppFound)
				.then((response: { success: boolean }) => {
					if (!response.success) {
						throw new Error("Update failed");
					}
				})
				.catch((error) => {})
				.finally(() => setUpdatesAppFound(undefined));
		}
	};

	return (
		<>
			<AdminDivider label={"App"} icon={<Android />} />
			<Stack direction="row" spacing={2}>
				<Typography variant="body1">{"App version: "}</Typography>
				<Typography variant="body1">{appVersion}</Typography>
			</Stack>
			<Stack direction={"row"} alignItems={"center"}>
				{updatesAppFound === undefined ? (
					<Button className="appAction" variant="outlined" color="secondary" onClick={handleCheckAppUpdates} endIcon={<Update />}>
						{"Check for updates"}
					</Button>
				) : updatesAppFound === null ? (
					<Typography variant="subtitle2">{"No updates available"}</Typography>
				) : (
					<Button className="appAction" variant="outlined" color="secondary" onClick={handleDownloadAppUpdate} endIcon={<SystemUpdate />}>
						{"Install update " + updatesAppFound.release}
					</Button>
				)}
			</Stack>
			<Stack direction="row" spacing={2}>
				<Typography variant="body1">{"Is internet reachable: "}</Typography>
				<Typography color={isInternetReachable ? "success" : "error"} variant="body1">
					{isInternetReachable ? "✔" : "❌"}
				</Typography>
			</Stack>
			<Stack direction="row" spacing={2}>
				<Typography variant="body1">{"PWA Url: "}</Typography>
				<Typography variant="body1">{webviewUrl}</Typography>
			</Stack>

			<Button variant="outlined" color="secondary" onClick={() => _openAnyDesk()}>
				{"Open AnyDesk"}
			</Button>

			<Button variant="outlined" color="secondary" onClick={() => _openSmartTde()}>
				{"Open SmartTde"}
			</Button>
		</>
	);
};

export default DiagnosticApp;
