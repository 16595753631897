import { HttpStatusCode } from "axios";
import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Cc, LogChannel, PaymentStepDrawer, PaymentStepServer, PrinterType } from "../../../../../constants";
import { useLogger } from "../../../../../hooks/useLogger";
import useCcPrinter from "../../../../../services/Coatcheck/useCcPrinter";
import useRtPrinter from "../../../../../services/Rt/useRtPrinter";
import { PrintCcResponse, PrintRtResponse } from "../../../../../types";
import { useMessage } from "../../../../MessageHandler/MessageService";
import { useTheme } from "../../../../Theme/ThemeWrapper";

interface CheckCashSystemProps {
	firstPaymentStep: PaymentStepServer | PaymentStepDrawer;
	setRequestStatus: Dispatch<SetStateAction<PaymentStepServer | PaymentStepDrawer>>;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	cancelPayment: () => void;
}
const CheckCashSystem = ({ firstPaymentStep, setRequestStatus, setIsLoading, cancelPayment }: CheckCashSystemProps) => {
	const { settings } = useTheme();
	const { t } = useTranslation();
	const message = useMessage();
	const { warn } = useLogger();
	const { RT } = useRtPrinter();
	const { CC } = useCcPrinter();

	const check = useCallback(async () => {
		const handleError = () => {
			warn("CheckCashSystem error", LogChannel.payment);
			message({
				title: "",
				description: t("system.error.cashSystem.statusError"),
				okCallback: check,
				okLabel: t("common.retry"),
				cancelCallback: cancelPayment,
				cancelLabel: t("common.cancel") ?? ""
			});
		};

		setIsLoading(true);

		if (settings.printerType === PrinterType.basiq) {
			await RT.cashSystemCheckStatus()
				.then((response: PrintRtResponse) => {
					if (
						response.status === HttpStatusCode.Ok &&
						response.data.status.error === false &&
						response.data.status.inattivita === false &&
						response.data.status.key === 1
					) {
						setRequestStatus(firstPaymentStep);
					} else {
						handleError();
					}
				})
				.catch(() => handleError())
				.finally(() => setIsLoading(false));
		} else {
			await CC.printerCheckStatus()
				.then((response: PrintCcResponse) => {
					if (response?.data?.result === Cc.ErrorCode.noError) {
						setRequestStatus(firstPaymentStep);
					} else {
						handleError();
					}
				})
				.catch(() => handleError())
				.finally(() => setIsLoading(false));
		}
	}, [firstPaymentStep, setRequestStatus, setIsLoading, message, cancelPayment, t, RT, CC, warn, settings.printerType]);

	useEffect(() => {
		/* eslint-disable react-hooks/exhaustive-deps */
		check();
	}, []);

	return null;
};

export default CheckCashSystem;
