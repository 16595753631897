import { Container, ThemeProvider } from "@mui/material";
import { memo, ReactNode } from "react";
import { slugify } from "../../../../utils/Strings";
import { useTheme } from "../../../Theme/ThemeWrapper";
import "../../OrderMaker.scss";

interface DialogFullProps {
	children?: ReactNode;
	title?: string;
}

const DialogFull = ({ children, title }: DialogFullProps) => {
	const { theme } = useTheme();

	return (
		<ThemeProvider theme={theme.systemTheme}>
			<Container
				className={`dialogContainer${title ? " " + slugify(title) : ""}`}
				sx={{ backgroundColor: theme.systemTheme.palette.background.paper, minHeight: "100vh" }}
			>
				{children}
			</Container>
		</ThemeProvider>
	);
};

export default memo(DialogFull);
