import { Settings } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { memo, MouseEvent as ReactMouseEvent, useState } from "react";
import { useTheme } from "../../Theme/ThemeWrapper";
import TouchIcon from "./TouchIcon";

interface IHiddenAdminButtonProps {
	handleAction: () => void;
	corner?: "topLeft" | "topRight" | "bottomLeft" | "bottomRight";
}

const HiddenAdminButton = ({ handleAction, corner = "topRight" }: IHiddenAdminButtonProps) => {
	const { theme } = useTheme();

	const [configEnabled, setConfigEnabled] = useState<number>(0);

	const showAndHandleClick = (e: ReactMouseEvent<HTMLElement>) => {
		setConfigEnabled((prev) => prev + 1);
		if (configEnabled > 1) {
			setConfigEnabled(0);
			handleAction();
		}
		e.stopPropagation();
	};

	return (
		<Stack
			className="hiddenConfigIcon"
			direction="row-reverse"
			spacing={1}
			sx={{
				position: "absolute",
				top: ["topLeft", "topRight"].includes(corner) ? 0 : "auto",
				bottom: ["bottomLeft", "bottomRight"].includes(corner) ? 0 : "auto",
				right: ["topRight", "bottomRight"].includes(corner) ? 0 : "auto",
				left: ["topLeft", "bottomLeft"].includes(corner) ? 0 : "auto",
				zIndex: "1000"
			}}
		>
			<TouchIcon sx={{ color: configEnabled > 1 ? theme.systemTheme.palette.info.main : "transparent" }} size="large" onClick={showAndHandleClick}>
				<Settings sx={{ fontSize: "6rem" }} />
			</TouchIcon>
		</Stack>
	);
};

export default memo(HiddenAdminButton);
