import { TextSnippet } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { BridgeResult } from "../../../types/reactNative/bridgeOutput";
import AdminDivider from "../../Layout/Divider/AdminDivider";
import "../Admin.scss";

interface DiagnosticLogProps {
	logFileName: string | undefined;
	_getLogLastLines: (isTest?: boolean) => Promise<BridgeResult & { data: string }>;
}

const DiagnosticLog = ({ logFileName, _getLogLastLines }: DiagnosticLogProps) => {
	const [fullLog, setFullLog] = useState<string>("");

	const handleReadLog = async () => {
		const result: BridgeResult & { data: string } = await _getLogLastLines();
		if (result.success && result.data) {
			setFullLog(result.data);
		}
	};

	return (
		<>
			<AdminDivider label={"Log"} icon={<TextSnippet />} />
			<Stack spacing={2}>
				<Stack direction="row" spacing={2}>
					<Typography variant="body1">{"Current log file: "}</Typography>
					<Typography variant="body1">{logFileName ?? ""}</Typography>
				</Stack>

				<Button variant="outlined" color="secondary" onClick={handleReadLog}>
					{"Refresh log"}
				</Button>

				{/* <TextField slotProps={{ htmlInput: { fontSize: "0.5rem !important" } }} size="small" value={fullLog} multiline maxRows={45} /> */}
				<Box sx={{ overflowY: "scroll", maxHeight: "75vh" }}>
					<Box sx={{ fontSize: "0.75rem !important", whiteSpace: "break-spaces" }}>{fullLog}</Box>
				</Box>
			</Stack>
		</>
	);
};

export default DiagnosticLog;
