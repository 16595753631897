import { Payment, Warning } from "@mui/icons-material";
import { Box, Button, Checkbox, Stack, Typography } from "@mui/material";
import { Dispatch, JSX, memo, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getPaymentMethodName, PaymentMethod } from "../../../constants";
import { useBridgeWrapper } from "../../../hooks/useBridgeWrapper";
import { ElectronicPaymentSatispay, ElectronicPaymentStripe } from "../../../services/4Delivery";
import { useDrawer } from "../../../services/SmartTDE";
import { PaymentErrorResponse, SettingsConfig } from "../../../types";
import AdminDivider from "../../Layout/Divider/AdminDivider";
import InputSave from "../../Layout/Form/InputSave";
import { useMessage } from "../../MessageHandler/MessageService";

interface AdminSettingsPaymentProps {
	isAdmin: boolean;
	currentSettings: SettingsConfig;
	setCurrentSettings: Dispatch<SetStateAction<SettingsConfig>>;
	satispayDisabled: boolean;
	setSatispayDisabled: Dispatch<SetStateAction<boolean>>;
	stripeLocationId: string | null;
}

const AdminSettingsPayment = ({
	isAdmin,
	currentSettings,
	setCurrentSettings,
	satispayDisabled,
	setSatispayDisabled,
	stripeLocationId
}: AdminSettingsPaymentProps) => {
	const { hasBridge, _testUsbStripeReader } = useBridgeWrapper();
	const { t } = useTranslation();
	const message = useMessage();
	const { checkDrawerReady, postReset } = useDrawer();

	/**
	 * Handles the change of the terminal serial number - Updates the Settings.
	 *
	 * @param {string} value The new terminal serial number.
	 */
	const handleTerminalSerialChange = useCallback(
		(value: string) => {
			setCurrentSettings((prev: SettingsConfig) => ({ ...prev, terminalSerialNumber: value }));
		},
		[setCurrentSettings]
	);

	/**
	 * Tests the Satispay electronic payment system and displays appropriate messages based on the result.
	 *
	 * @returns {Promise<boolean>} A promise that resolves to `true` if the test is successful, otherwise `false`.
	 */
	const postSatispayTest = useCallback(async (): Promise<boolean> => {
		const electronicPaymentSatispay = new ElectronicPaymentSatispay();

		message({
			title: t("common.crud.testing", { item: t("checkout.paymentModal.type.satispayLabel") }),
			description: t("system.admin.settings.alertWaitAction"),
			okCallback: () => {},
			okLabel: ""
		});

		let success = false;

		await electronicPaymentSatispay
			.test()
			.then(() => {
				success = true;
				message({
					title: `${t("common.crud.testing", { item: t("checkout.paymentModal.type.satispayLabel") })}: ${t("common.ok")}`,
					description: t("system.admin.settings.testOk"),
					okCallback: () => {},
					okLabel: t("common.ok")
				});
			})
			.catch((err: PaymentErrorResponse) => {
				message({
					title: `${t("common.crud.testing", { item: t("checkout.paymentModal.type.satispayLabel") })}: ${t("common.error")}`,
					description: t(`system.error.${err.error_code}`),
					okCallback: () => {},
					okLabel: t("common.ok")
				});
			});
		return success;
	}, [message, t]);

	/**
	 * Tests the Automatic Cash Drawer and displays appropriate messages based on the result.
	 *
	 * @returns {Promise<boolean>} A promise that resolves to `true` if the test is successful, otherwise `false`.
	 */
	const postDrawerTest = useCallback(async (): Promise<boolean> => {
		message({
			title: t("common.crud.testing", { item: t("checkout.paymentModal.type.cash_drawerLabel") }),
			description: t("system.admin.settings.alertWaitAction"),
			okCallback: () => {},
			okLabel: ""
		});

		let success = false;

		try {
			const isDrawerAvailable = await checkDrawerReady(currentSettings.ipDrawer);
			if (!isDrawerAvailable.result) throw new Error(isDrawerAvailable.errorMessage);

			success = true;
			message({
				title: `${t("common.crud.testing", { item: t("checkout.paymentModal.type.cash_drawerLabel") })}: ${t("common.ok")}`,
				description: t("system.admin.settings.testOk"),
				okCallback: () => {},
				okLabel: t("common.ok")
			});
		} catch (err: unknown) {
			message({
				title: `${t("common.crud.testing", { item: t("checkout.paymentModal.type.cash_drawerLabel") })}: ${t("common.error")}`,
				description: err instanceof Error ? err.message : t("system.error.ERR_BAD_RESPONSE"),
				okCallback: () => {},
				okLabel: t("common.ok")
			});
		}
		return success;
	}, [checkDrawerReady, message, t, currentSettings.ipDrawer]);

	const postDrawerReset = useCallback(async (): Promise<boolean> => {
		let success = false;

		try {
			const isDrawerAvailable = await postReset();
			if (!isDrawerAvailable) throw new Error();

			success = true;
			message({
				title: `${t("common.reset") + " " + t("checkout.paymentModal.type.cash_drawerLabel")}: ${t("common.ok")}`,
				description: t("system.admin.settings.testOk"),
				okCallback: () => {},
				okLabel: t("common.ok")
			});
		} catch (err: unknown) {
			message({
				title: `${t("common.reset") + " " + t("checkout.paymentModal.type.cash_drawerLabel")}: ${t("common.error")}`,
				description: t("system.error.ERR_BAD_RESPONSE"),
				okCallback: () => {},
				okLabel: t("common.ok")
			});
		}
		return success;
	}, [postReset, message, t]);

	/**
	 * Handles the change of the available payment methods - Updates the Settings.
	 *
	 * @param {PaymentMethod} changedMethod The payment method that has been changed.
	 */
	const handleAvailablePaymentChange = useCallback(
		async (changedMethod: PaymentMethod) => {
			const methods: PaymentMethod[] = [...currentSettings.availablePaymentMethods];
			const index: number = methods.indexOf(changedMethod);

			const indexAlternativeStripeMethod: number = methods.indexOf(
				changedMethod === PaymentMethod.STRIPE ? PaymentMethod.STRIPE_RN : PaymentMethod.STRIPE
			);

			// if the method is not in the list, add it
			if (index === -1) {
				switch (changedMethod) {
					case PaymentMethod.SATISPAY:
						await postSatispayTest().then((testResponse: boolean) => {
							if (testResponse) {
								methods.push(changedMethod); // you can't add Satispay: not enabled
							} else {
								setSatispayDisabled(true);
								message({
									title: t("common.warning"),
									description: t("system.admin.settings.satispayComing"),
									okCallback: () => {},
									okLabel: t("common.ok")
								});
							}
						});

						break;

					case PaymentMethod.STRIPE:
					case PaymentMethod.STRIPE_RN:
						if (indexAlternativeStripeMethod !== -1) {
							methods.splice(indexAlternativeStripeMethod, 1);
						}
						methods.push(changedMethod);
						break;

					default:
						methods.push(changedMethod);
						break;
				}
			} else {
				// remove method
				if (methods.length === 1) {
					message({
						title: t("common.warning"),
						description: t("system.admin.settings.paymentsMandatory"),
						okCallback: () => {},
						okLabel: t("common.ok")
					});
					return; // at least one method must be enabled
				}
				methods.splice(index, 1);
			}

			// if the stripeLocationId is missing, remove all the stripe methods if they are present
			if (!stripeLocationId) {
				const newIndex: number = methods.indexOf(PaymentMethod.STRIPE);
				if (newIndex !== -1) methods.splice(newIndex, 1);
				const newIndexRn: number = methods.indexOf(PaymentMethod.STRIPE_RN);
				if (newIndexRn !== -1) methods.splice(newIndexRn, 1);
			}

			setCurrentSettings((prev: SettingsConfig) => ({ ...prev, availablePaymentMethods: methods }));
		},
		[postSatispayTest, setCurrentSettings, setSatispayDisabled, stripeLocationId, message, t, currentSettings.availablePaymentMethods]
	);

	/**
	 * Tests the Stripe electronic payment system (using external reader) and displays appropriate messages based on the result.
	 */
	const postTerminalTest = useCallback(async () => {
		const electronicPaymentStripe = new ElectronicPaymentStripe(currentSettings.terminalSerialNumber);

		message({
			title: t("common.crud.testing", { item: t("system.admin.settings.reader") }),
			description: t("system.admin.settings.alertWaitAction"),
			okCallback: () => {},
			okLabel: ""
		});

		await electronicPaymentStripe
			.test()
			.then(() => {
				message({
					title: `${t("common.crud.testing", { item: t("system.admin.settings.reader") })}: ${t("common.ok")}`,
					description: t("system.admin.settings.testOk"),
					okCallback: () => {},
					okLabel: t("common.ok")
				});
			})
			.catch((err: PaymentErrorResponse) => {
				message({
					title: `${t("common.crud.testing", { item: t("system.admin.settings.reader") })}: ${t("common.error")}`,
					description: t(`system.error.${err.error_code}`),
					okCallback: () => {},
					okLabel: t("common.ok")
				});
			});
	}, [currentSettings.terminalSerialNumber, message, t]);

	/**
	 * Tests the Stripe electronic payment system (using embedded reader through react native bridge) and displays appropriate messages based on the result.
	 */
	const stripeRnTest = useCallback(async () => {
		await _testUsbStripeReader()
			.then((response: { success: boolean }) => {
				if (response.success) {
					message({
						title: `${t("common.crud.testing", { item: t("system.admin.settings.reader") })}: ${t("common.ok")}`,
						description: t("system.admin.settings.testOk"),
						okCallback: () => {},
						okLabel: t("common.ok")
					});
				} else throw new Error("error");
			})
			.catch(() => {
				message({
					title: `${t("common.crud.testing", { item: t("system.admin.settings.reader") })}: ${t("common.error")}`,
					description: t(`system.error.reader_is_offline`),
					okCallback: () => {},
					okLabel: t("common.ok")
				});
			});
	}, [_testUsbStripeReader, message, t]);

	const handleIpDrawerChange = useCallback(
		(value: string) => {
			setCurrentSettings((prev: SettingsConfig) => ({ ...prev, ipDrawer: value }));
			// await postCashSystemCheck(currentSettings.cashSystemRevenueServiceType);
		},
		[setCurrentSettings]
	);

	const cashDrawerSettings: JSX.Element = (
		<Stack direction="row" alignItems="center" justifyContent="left">
			<InputSave
				color="secondary"
				placeholder="localhost"
				label={t("system.admin.settings.ipDrawer")}
				value={currentSettings.ipDrawer}
				onSave={(value) => handleIpDrawerChange(value.toString())}
				inputProps={{ maxLength: 15, minLength: 0 }}
				disabled={!isAdmin}
			/>
			<Button sx={{ marginLeft: "0.25rem" }} variant="contained" color="success" onClick={postDrawerTest}>
				{t("common.test").toUpperCase()}
			</Button>
			<Button sx={{ marginLeft: "0.25rem" }} variant="contained" color="success" onClick={postDrawerReset}>
				{t("common.reset").toUpperCase()}
			</Button>
		</Stack>
	);

	const stripeTerminalSettings: JSX.Element = (
		<Stack direction="row" alignItems="center" justifyContent="left">
			<InputSave
				color="secondary"
				label={t("system.admin.settings.terminalSerialNumber")}
				value={currentSettings.terminalSerialNumber}
				onSave={(value) => handleTerminalSerialChange(value.toString().toUpperCase())}
				inputProps={{ maxLength: 20, minLength: 10 }} //should be 15 characters: STRIPE
				disabled={!currentSettings.availablePaymentMethods.includes(PaymentMethod.STRIPE) || !isAdmin}
			/>
			<Button
				sx={{ marginLeft: "0.25rem" }}
				variant="contained"
				color="success"
				onClick={postTerminalTest}
				disabled={!currentSettings.availablePaymentMethods.includes(PaymentMethod.STRIPE)}
			>
				{t("common.test").toUpperCase()}
			</Button>
		</Stack>
	);

	const stripeRnSettings: JSX.Element = (
		<Stack direction="row" alignItems="center" justifyContent="left">
			<Button
				sx={{ marginLeft: "0.25rem" }}
				variant="contained"
				color="success"
				onClick={stripeRnTest}
				disabled={!currentSettings.availablePaymentMethods.includes(PaymentMethod.STRIPE_RN)}
			>
				{t("common.crud.testing", { item: t("system.admin.settings.reader") })}
			</Button>
		</Stack>
	);

	return (
		<>
			<AdminDivider label={t("system.admin.settings.payments")} icon={<Payment />} />
			<Box>
				{Object.values(PaymentMethod).map((value: string | PaymentMethod) => {
					if (isNaN(Number(value))) return null; //looping over a "Numeric ENUM" returns both names and values. Get rid of names!
					const method: PaymentMethod = Number(value) as PaymentMethod;
					const existing: boolean = currentSettings.availablePaymentMethods.some((searchMethod: PaymentMethod) => searchMethod === method);
					const isSatispay: boolean = method === PaymentMethod.SATISPAY;
					const isOneOfStripeMethods: boolean = [PaymentMethod.STRIPE, PaymentMethod.STRIPE_RN].includes(method);
					const isStripeRn: boolean = method === PaymentMethod.STRIPE_RN;
					let isAvailable = true;
					if (isSatispay) {
						isAvailable = !satispayDisabled;
					} else {
						if (isOneOfStripeMethods) isAvailable = Boolean(stripeLocationId);
						if (isStripeRn) isAvailable = hasBridge && Boolean(stripeLocationId);
					}

					const handleClick = () => handleAvailablePaymentChange(method);

					let additionalSettings: JSX.Element | null = null;
					if (method === PaymentMethod.CASH_DRAWER) additionalSettings = cashDrawerSettings;
					if (method === PaymentMethod.STRIPE && stripeLocationId) additionalSettings = stripeTerminalSettings;
					if (method === PaymentMethod.STRIPE_RN && stripeLocationId) additionalSettings = stripeRnSettings;

					return !isAdmin && !existing ? null : (
						<Stack key={method} direction="column" alignItems="left" justifyContent="left">
							<Box>
								<Checkbox
									color="success"
									checked={existing}
									onChange={handleClick}
									sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
									disabled={!isAvailable || !isAdmin}
								/>
								{t(`checkout.paymentModal.type.${getPaymentMethodName(method)}`)}
							</Box>
							{existing && additionalSettings ? (
								<Box className="paymentSetting" sx={{ m: "1rem 4rem" }}>
									{additionalSettings}
								</Box>
							) : null}
						</Stack>
					);
				})}

				{!stripeLocationId ? (
					<Stack direction="row" justifyContent="left" alignItems="flex-end" sx={{ mt: "1rem" }}>
						<Warning color="warning" sx={{ mr: "1rem" }} />
						<Typography color="warning" variant="body2">
							{t("system.admin.settings.stripeLocationIdMissing")}
						</Typography>
					</Stack>
				) : null}
			</Box>
		</>
	);
};

export default memo(AdminSettingsPayment);
