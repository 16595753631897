import { Service4DeliveryEndpoints } from "..";
import { CheckoutMethod } from "../../../constants";
import { Cart, Order, PaymentIntentResponse, PaymentStatusResponse, ResultResponse, SimpleStatusResponse } from "../../../types";
import { Payload } from "../../../utils/httpClient/AxiosWrapper";
import { OrderRequestManager } from "../../../utils/OrderRequestManager";
import { callPayment } from "./Payment";

export abstract class ElectronicPayment {
	terminalSerialNumber: string | null;

	constructor(terminalSerialNumber?: string) {
		this.terminalSerialNumber = terminalSerialNumber ?? null;
	}

	abstract payOrder(isFiscalMode: boolean, cart: Cart, order: Order): Promise<PaymentIntentResponse>;
	abstract createIntent(isFiscalMode: boolean, cart: Cart, order: Order): Promise<PaymentIntentResponse>;
	abstract checkStatusPayment(cartId: number | null): Promise<PaymentStatusResponse>;
	abstract cancelPayment(cartId: number | null): Promise<SimpleStatusResponse>;
	abstract test(): Promise<ResultResponse>;

	attachTerminalSerialNumber(payload: Payload): Payload {
		if (this.terminalSerialNumber) payload.serial_number = this.terminalSerialNumber;
		return payload;
	}

	getPayloadPayOrder(isFiscalMode: boolean, cart: Cart, order: Order): Payload {
		const orderRequestManager = new OrderRequestManager(order);

		const payload: Payload = {
			uid: orderRequestManager.getNextOrderRequestId(),
			cart: JSON.stringify(cart),
			is_fiscal_mode: Number(isFiscalMode ?? "0"),
			checkout_method: order.checkoutMethod.toString()
		};
		if (order.checkoutMethod === CheckoutMethod.PLACE_NUMBER) payload.place_number = order.placeNumber;

		return this.attachTerminalSerialNumber(payload);
	}

	getPayloadKioskCart(cartId: number | null): Payload {
		const payload: Payload = { kiosk_cart_id: cartId?.toString() };
		return this.attachTerminalSerialNumber(payload);
	}
}

export class ElectronicPaymentSatispay extends ElectronicPayment {
	urls = Service4DeliveryEndpoints.satispay;

	payOrder = async (isFiscalMode: boolean, cart: Cart, order: Order): Promise<PaymentIntentResponse> => {
		const payload: Payload = this.getPayloadPayOrder(isFiscalMode, cart, order);
		return callPayment(payload, this.urls.request);
	};

	createIntent = async (isFiscalMode: boolean, cart: Cart, order: Order): Promise<PaymentIntentResponse> => {
		return this.payOrder(isFiscalMode, cart, order);
	};

	checkStatusPayment = async (cartId: number | null): Promise<PaymentStatusResponse> => {
		const payload: Payload = this.getPayloadKioskCart(cartId);
		return callPayment(payload, this.urls.update);
	};

	cancelPayment = async (cartId: number | null): Promise<SimpleStatusResponse> => {
		const payload: Payload = this.getPayloadKioskCart(cartId);
		return callPayment(payload, this.urls.cancel);
	};

	test = async (): Promise<ResultResponse> => {
		return callPayment(this.attachTerminalSerialNumber({}), this.urls.test);
	};
}

export class ElectronicPaymentStripe extends ElectronicPayment {
	urls = Service4DeliveryEndpoints.stripe;

	payOrder = async (isFiscalMode: boolean, cart: Cart, order: Order): Promise<PaymentIntentResponse> => {
		const payload: Payload = this.getPayloadPayOrder(isFiscalMode, cart, order);
		return callPayment(payload, this.urls.request);
	};

	createIntent = async (isFiscalMode: boolean, cart: Cart, order: Order): Promise<PaymentIntentResponse> => {
		const payload: Payload = this.getPayloadPayOrder(isFiscalMode, cart, order);
		return callPayment(payload, this.urls.intentRequest);
	};

	checkStatusPayment = async (cartId: number | null): Promise<PaymentStatusResponse> => {
		const payload: Payload = this.getPayloadKioskCart(cartId);
		return callPayment(payload, this.urls.update);
	};

	cancelPayment = async (cartId: number | null): Promise<SimpleStatusResponse> => {
		const payload: Payload = this.getPayloadKioskCart(cartId);
		return callPayment(payload, this.urls.cancel);
	};

	test = async (): Promise<ResultResponse> => {
		return callPayment(this.attachTerminalSerialNumber({}), this.urls.test);
	};
}
