import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import useStaticImage from "../../../hooks/useStaticImage";
import { useTheme } from "../../Theme/ThemeWrapper";
import "../Admin.scss";

interface AdminHeaderProps {
	isLoading: boolean;
	onClickBack: () => void;
}

const AdminHeader = ({ isLoading, onClickBack }: AdminHeaderProps) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { loading: logoLoading, error: logoError, image: logoImage } = useStaticImage("system/logoLabware.svg");
	const { loading: iqSelfLoading, error: iqSelfError, image: iqSelfImage } = useStaticImage("system/logoIQSelf.svg");

	return (
		<Box className="header" sx={{ borderColor: `${theme.systemTheme.palette.primary.dark} !important` }}>
			<Button className={"back"} variant="outlined" color="info" onClick={onClickBack} size="large" endIcon={<ArrowBack />} disabled={isLoading}>
				{t("common.back")}
			</Button>
			<Stack direction="row" alignItems="baseline" justifyContent="space-between">
				<Box className="iqSelf">
					{!iqSelfLoading && !iqSelfError ? <img src={iqSelfImage ?? ""} alt={t("system.admin.title") ?? ""} loading="lazy" /> : null}
				</Box>
				<Typography variant="caption" color={theme.systemTheme.palette.text.secondary}>
					{t("checkout.powered")}
				</Typography>
				<Box className="logo">
					{!logoLoading && !logoError ? (
						<img className="labwareLogo" src={logoImage ?? ""} alt={t("system.admin.labware") ?? ""} loading="lazy" />
					) : null}
				</Box>
			</Stack>
		</Box>
	);
};

export default memo(AdminHeader);
