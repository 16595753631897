import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { LogChannel, PaymentStepDrawer, PaymentStepServer } from "../../../../../constants";
import { useLogger } from "../../../../../hooks/useLogger";
import { useDrawer } from "../../../../../services/SmartTDE";
import { PaymentErrorResponse } from "../../../../../types";
import { TransactionStatusDrawer, UseDrawerResult } from "../../../../../types/SmartTde";
import { useMessage } from "../../../../MessageHandler/MessageService";

interface CheckCashTransactionProps {
	firstPaymentStep: PaymentStepServer | PaymentStepDrawer;
	setRequestStatus: Dispatch<SetStateAction<PaymentStepServer | PaymentStepDrawer>>;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	cashTransaction: TransactionStatusDrawer | null;
	setCashTransaction: Dispatch<SetStateAction<TransactionStatusDrawer | null>>;
	setCashTimeLeft: Dispatch<SetStateAction<number>>;
	confirmCashPayment: (transactionStatusDrawer: TransactionStatusDrawer) => void;
}
const CheckCashTransaction = ({
	firstPaymentStep,
	setRequestStatus,
	setIsLoading,
	confirmCashPayment,
	cashTransaction,
	setCashTransaction,
	setCashTimeLeft
}: CheckCashTransactionProps) => {
	const { t } = useTranslation();
	const message = useMessage();
	const { log } = useLogger();

	const updateStatusInterval: number = 2; // 2s
	const updateStatusMax: number = 150; // 150 * 2s = 5 minutes (Limit polling attempts)
	const updateStatusCounter = useRef<number>(0);
	const [attemptResponseCounter, setAttemptResponseCounter] = useState<number>();
	const { getTransactionStatus } = useDrawer();

	useEffect(() => {
		const interval = setInterval(checkPayment, updateStatusInterval * 1000);

		return () => clearInterval(interval);
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [attemptResponseCounter]);

	const reset = useCallback(() => {
		setIsLoading(false);
		setCashTimeLeft(0);
	}, []);

	const confirm = useCallback(
		async (transactionStatus: TransactionStatusDrawer) => {
			setCashTransaction(null);
			confirmCashPayment(transactionStatus);
		},
		[confirmCashPayment, setCashTransaction]
	);

	const handleError = (errorCode?: string, errorMessage?: string) => {
		/* inhibit any further attempts of polling */
		reset();
		updateStatusCounter.current = updateStatusMax + 1;

		let title: string;
		if (errorMessage) {
			title = errorMessage;
		} else {
			title = errorCode ? t(`system.error.${errorCode}`) : t("system.error.ERR_BAD_RESPONSE");
		}
		message({
			title: title.toUpperCase(),
			description: t("common.retryQuestion"),
			okCallback: () => setRequestStatus(firstPaymentStep),
			okLabel: t("common.yes").toUpperCase(),
			cancelCallback: () => setRequestStatus(PaymentStepDrawer.cancelingCashTransaction),
			cancelLabel: t("common.cancel").toUpperCase()
		});
	};

	// const handleUncompletedChange = (transactionStatus: TransactionStatusDrawer) => {
	// 	message({
	// 		title: t("common.warning").toUpperCase(),
	// 		description: t("checkout.paymentModal.notDispensedError") + " " + t("system.error.staff"),
	// 		okCallback: async () => {
	// 			setTextWarning(t("checkout.paymentModal.completed"));
	// 			await delayer(10000);
	// 			await confirm(transactionStatus);
	// 		},
	// 		okLabel: t("common.ok").toUpperCase()
	// 	});
	// };

	const checkPayment = async () => {
		if (!cashTransaction) return;
		if (attemptResponseCounter !== updateStatusCounter.current) return;
		updateStatusCounter.current++;

		// Reached the limit of polling attempts to capture the updated status: forced the cancel action.
		if (updateStatusCounter.current > updateStatusMax) {
			log(`CheckCashTransaction - Reached the limit of ${updateStatusMax} polling attempts`, LogChannel.payment);

			reset();
			setRequestStatus(PaymentStepDrawer.cancelingCashTransaction);
		} else {
			await getTransactionStatus(cashTransaction?.transaction_id ?? 0)
				.then(async (response: UseDrawerResult) => {
					if (response.result && response.data) {
						const transactionStatus = response.data as TransactionStatusDrawer;

						log(`CheckCashTransaction ${updateStatusCounter.current}: ${!!transactionStatus.is_closed}`, LogChannel.payment);

						if (transactionStatus.is_closed) {
							reset();
							// if the transaction is closed, but the change han not been fully dispensed...
							// if (transactionStatus.non_erogato > 0) {
							// 	setCashTransaction(transactionStatus);
							// 	handleUncompletedChange(transactionStatus);
							// 	return;
							// }
							await confirm(transactionStatus);
							return;
						} else {
							setCashTransaction(transactionStatus);
							setAttemptResponseCounter(updateStatusCounter.current);
						}
					} else {
						handleError(undefined, response.errorMessage ?? "");
					}
				})
				.catch((err: PaymentErrorResponse) => {
					handleError(err.error_code);
				});
		}
	};

	useEffect(() => {
		setIsLoading(true);
		setCashTimeLeft(updateStatusMax * updateStatusInterval);
		setAttemptResponseCounter(0);
		/* eslint-disable react-hooks/exhaustive-deps */
	}, []);

	return null;
};

export default CheckCashTransaction;
