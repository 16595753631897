export const getServiceSmartTdeEndpoints = (ip: string) => {
	const base = `http://${ip === "" ? "localhost" : ip}:10500/api`;
	const moduleDrawer = base + "/automatic-cash-drawer";

	/**
	 * Smart TDE Service - endpoints
	 */
	return {
		// Automatic Cash Drawer module
		drawer: {
			checkDrawerReady: moduleDrawer + "/is_ready",
			postStartTransaction: moduleDrawer + "/start_transaction",
			getTransactionStatus: moduleDrawer + "/transaction_status",
			postCancelTransaction: moduleDrawer + "/cancel_transaction",
			postCommitTransaction: moduleDrawer + "/commit_transaction"
		}
	};
};

export * from "./Drawer/useDrawer";
