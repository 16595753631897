import { ListItemButton, ListItemButtonProps } from "@mui/material";
import { memo } from "react";
import { useLongPress } from "use-long-press";
import useSoundEffect, { SoundEffectProps } from "../../../hooks/effects/useSoundEffect";

interface TouchListItemButtonProps extends ListItemButtonProps, SoundEffectProps {}

const TouchListItemButton = ({ muteSound, tone, ...rest }: TouchListItemButtonProps) => {
	const { playSound } = useSoundEffect({ muteSound, tone });
	const bind = useLongPress((event: any) => {
		if (rest.onClick) {
			rest.onClick(event);
		}
	});

	return <ListItemButton disableRipple disableTouchRipple onClickCapture={playSound} {...bind()} {...rest} />;
};

export default memo(TouchListItemButton);
