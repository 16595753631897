/**
 * The character Dimensions enum
 * The key has semantic meaning: W = width, H = height. The number is the space the character occupies (compared to a single plain character ).
 */
export enum Dim {
	W1H1 = "0", // width 1, height 1
	W1H2 = "1", // width 1, height 2
	W1H3 = "2",
	W1H4 = "3",
	W2H2 = "4",
	W2H3 = "5",
	W3H3 = "6",
	W2H4 = "7",
	W3H4 = "8",
	W4H4 = "9", // width 4, height 4
	W2H1 = "A",
	W3H1 = "B",
	W3H2 = "C",
	W4H2 = "D"
}

/**
 * The character Colors enum
 * The keys has semantic meaning:
 * - the first part is the text Color (black/white)
 * - the second part is the background Color (black/white/gray1/gray2)
 */
export enum Colors {
	blackOnWhite = "0",
	whiteOnBlack = "1",
	whiteOnGray1 = "2",
	whiteOnGray2 = "3",
	blackOnGray1 = "4",
	blackOnGray2 = "5"
}

/**
 * The character Style enum
 */
export enum Weight {
	regular = 0,
	bold = 1
}

/**
 * The character Decoration enum
 */
export enum Decoration {
	none = 0,
	underline = 1
}

/**
 * Get the width of the character based on the given Dimension enum
 * @param dim
 * @returns The space the character occupies (compared to a single plain character).
 */
export const getDimWidth = (dim: Dim): number => {
	switch (dim) {
		case Dim.W1H1:
		case Dim.W1H2:
		case Dim.W1H3:
		case Dim.W1H4:
			return 1;
		case Dim.W2H1:
		case Dim.W2H2:
		case Dim.W2H3:
		case Dim.W2H4:
			return 2;
		case Dim.W3H1:
		case Dim.W3H2:
		case Dim.W3H3:
		case Dim.W3H4:
			return 3;
		case Dim.W4H2:
		case Dim.W4H4:
			return 4;
		default:
			return 1;
	}
};
