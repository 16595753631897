import { AxiosError } from "axios";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getServiceSmartTdeEndpoints } from "..";
import { useTheme } from "../../../components/Theme/ThemeWrapper";
import { LogChannel } from "../../../constants";
import { smartTdeKioskDeviceId } from "../../../constants/defaults";
import { DrawerErrorCode } from "../../../constants/enum/SmartTde/SmartTdeErrors";
import { useBridgeWrapper } from "../../../hooks/useBridgeWrapper";
import { useLogger } from "../../../hooks/useLogger";
import {
	GetTransactionStatusDrawerResponse,
	ResultDrawerResponse,
	SmartTdeRequestHeaders,
	StartTransactionDrawerResponse,
	UseDrawerResult
} from "../../../types/SmartTde";
import { callAxios, callAxiosResponseInterface } from "../../../utils/httpClient/AxiosWrapper";
import { ProgressiveRequestManager } from "../../../utils/ProgressiveRequestManager";

/**
 * Custom hook for "Automatic Cash Drawer" communication via Smart TDE
 */
export const useDrawer = () => {
	const { settings } = useTheme();
	const { log, warn } = useLogger();
	const { t } = useTranslation();
	const { hasBridge, uspUid, _openSmartTde } = useBridgeWrapper();

	const urls = useMemo(() => {
		const endpoints = getServiceSmartTdeEndpoints(settings.ipDrawer);
		return endpoints.drawer;
	}, [settings.ipDrawer]);
	const idManager = useMemo(() => new ProgressiveRequestManager(), []);

	const getHeaders = useCallback(
		(progressiveId: number): string => {
			const xHeaders: SmartTdeRequestHeaders = {
				"X-uid-device": uspUid,
				"X-progressive-id": progressiveId,
				"X-device": smartTdeKioskDeviceId,
				"X-pos-id": settings.kioskId
			};

			return JSON.stringify(xHeaders);
		},
		[uspUid, settings.kioskId]
	);

	/**
	 * Check if the bridge is available
	 * The app relies on the bridge to retrieve the USP hardware UID and share it with the Smart TDE service
	 *
	 * @returns boolean
	 */
	const checkBridge = useCallback(() => {
		if (!hasBridge) {
			warn("Bridge not available", LogChannel.drawer);
			return false;
		}
		return true;
	}, [hasBridge, warn]);

	const getDrawerErrorResult = useCallback(
		(code: DrawerErrorCode, caller: string): UseDrawerResult => {
			const translationCode = `system.error.smartTde.drawer.${code}`;
			warn(`${caller} error: ${t(translationCode, { lng: "en" })}`, LogChannel.drawer);
			return {
				result: false,
				errorMessage: t(translationCode),
				errorCode: code
			};
		},
		[t, warn]
	);

	const getErrorResult = useCallback(
		(err: callAxiosResponseInterface, caller: string): UseDrawerResult => {
			const translationCode: string = "system.error." + err.data.error_code;
			warn(`${caller} error: ${t(translationCode, { lng: "en" })}`, LogChannel.drawer);
			return {
				result: false,
				errorMessage: t(translationCode)
			};
		},
		[t, warn]
	);

	const validateId = useCallback(
		(transaction_id: number) => {
			if (!transaction_id || isNaN(transaction_id)) {
				warn("No transaction id available", LogChannel.drawer);
				return false;
			}
			return true;
		},
		[warn]
	);

	/**
	 * Check if the drawer is ready to accept transactions
	 *
	 * @param ip - Optional IP address of the drawer
	 * @returns Promise<UseDrawerResult>
	 */
	const checkDrawerReady = useCallback(
		async (ip?: string): Promise<UseDrawerResult> => {
			const operation = "Is ready";
			if (!checkBridge()) return { result: false, errorMessage: t("system.error.bridge") };

			const instanceIp = ip === undefined ? settings.ipDrawer : ip;
			const urlsCheckDrawerReady = ip === undefined ? urls.checkDrawerReady : getServiceSmartTdeEndpoints(ip)["drawer"].checkDrawerReady;
			return new Promise<UseDrawerResult>((resolve) => {
				callAxios({
					method: "get",
					url: urlsCheckDrawerReady,
					body: "{}",
					headers: getHeaders(idManager.getNextProgressiveRequestId()),
					smartTde: true
				})
					.then((response: callAxiosResponseInterface & { data: ResultDrawerResponse }) => {
						if (!response.data.operation_result) {
							resolve(getDrawerErrorResult(response.data.xHeaders["X-error-code"], operation));
						} else {
							log(`is ready`, LogChannel.drawer);
							resolve({ result: true });
						}
					})
					.catch((err: callAxiosResponseInterface) => {
						// If the drawer is not ready and the IP is not provided (default localhost, relying on SmartTde app as a server),
						// log the error and try to revive the SmartTde app
						if (!(err instanceof Error) && err.data.error_code === AxiosError.ERR_NETWORK && !instanceIp) {
							log("Resuming SmartTde app", LogChannel.drawer);
							_openSmartTde(true);
						}

						resolve(getErrorResult(err, operation));
					});
			});
		},
		[checkBridge, getErrorResult, log, t, urls.checkDrawerReady, getHeaders, idManager, _openSmartTde, settings.ipDrawer, getDrawerErrorResult]
	);

	/**
	 * Start a transaction
	 *
	 * @param amount - Amount to pay in cents. (e.g. 1000 = 10.00€)
	 * @returns Promise<UseDrawerResult> On success the transaction id is returned in the data field
	 */
	const postStartTransaction = useCallback(
		async (amount: number): Promise<UseDrawerResult> => {
			const operation = "Start transaction";
			if (!checkBridge()) return { result: false, errorMessage: t("system.error.bridge") };
			// If amount is not a number  or is less than 1 cents or greater than 1000000 cents, return
			if (isNaN(amount) || amount < 1 || amount > 99999999) return getDrawerErrorResult(DrawerErrorCode.INVALID_PARAMETERS, operation);

			return new Promise<UseDrawerResult>((resolve) => {
				callAxios({
					method: "post",
					url: urls.postStartTransaction,
					body: JSON.stringify({ to_pay: amount }),
					headers: getHeaders(idManager.getNextProgressiveRequestId()),
					smartTde: true
				})
					.then((response: callAxiosResponseInterface & { data: StartTransactionDrawerResponse }) => {
						if (!response.data.operation_result) {
							resolve(getDrawerErrorResult(response.data.xHeaders["X-error-code"], operation));
						} else {
							log(`Transaction started with id: ${response.data.transaction_id}`, LogChannel.drawer);
							resolve({ result: true, data: { transaction_id: response.data.transaction_id } });
						}
					})
					.catch((err: callAxiosResponseInterface) => {
						resolve(getErrorResult(err, operation));
					});
			});
		},
		[checkBridge, getErrorResult, getHeaders, idManager, log, t, urls.postStartTransaction, getDrawerErrorResult]
	);

	/**
	 * Get the status of the current transaction
	 *
	 * @param transaction_id - Transaction id
	 * @returns Promise<UseDrawerResult> On success the transaction status object is returned in the data field
	 */
	const getTransactionStatus = useCallback(
		async (transaction_id: number): Promise<UseDrawerResult> => {
			const operation = "Get Status";
			if (!checkBridge()) return { result: false, errorMessage: t("system.error.bridge") };
			if (!validateId(transaction_id)) return getDrawerErrorResult(DrawerErrorCode.INVALID_SEQUENCE, operation);

			return new Promise<UseDrawerResult>((resolve) => {
				callAxios({
					method: "get",
					url: urls.getTransactionStatus,
					body: "{}",
					headers: getHeaders(idManager.getNextProgressiveRequestId()),
					smartTde: true
				})
					.then((response: callAxiosResponseInterface & { data: GetTransactionStatusDrawerResponse }) => {
						if (!response.data.operation_result) {
							resolve(getDrawerErrorResult(response.data.xHeaders["X-error-code"], operation));
						} else {
							if (!response.data.transaction_id || response.data.transaction_id !== transaction_id) {
								resolve(getDrawerErrorResult(DrawerErrorCode.INVALID_SEQUENCE, operation));
							} else {
								log(
									`Transaction ${response.data.transaction_id} - isClosed:${response.data.is_closed} toPay:${response.data.to_pay} notDispensed:${response.data.non_erogato}`,
									LogChannel.drawer
								);
								resolve({ result: true, data: response.data });
							}
						}
					})
					.catch((err: callAxiosResponseInterface) => {
						resolve(getErrorResult(err, operation));
					});
			});
		},
		[checkBridge, validateId, getErrorResult, getHeaders, idManager, log, t, urls.getTransactionStatus, getDrawerErrorResult]
	);

	/**
	 * Cancel the current transaction
	 *
	 * @param transaction_id - Transaction id
	 * @returns Promise<UseDrawerResult>
	 */
	const postCancelTransaction = useCallback(
		async (transaction_id: number): Promise<UseDrawerResult> => {
			const operation = "Cancel transaction";
			if (!checkBridge()) return { result: false, errorMessage: t("system.error.bridge") };
			if (!validateId(transaction_id)) return getDrawerErrorResult(DrawerErrorCode.INVALID_SEQUENCE, operation);

			return new Promise<UseDrawerResult>((resolve) => {
				callAxios({
					method: "post",
					url: urls.postCancelTransaction,
					body: "{}",
					headers: getHeaders(idManager.getNextProgressiveRequestId()),
					smartTde: true
				})
					.then((response: callAxiosResponseInterface & { data: ResultDrawerResponse }) => {
						if (!response.data.operation_result) {
							resolve(getDrawerErrorResult(response.data.xHeaders["X-error-code"], operation));
						} else {
							log(`Transaction ${transaction_id} cancelled`, LogChannel.drawer);
							resolve({ result: true });
						}
					})
					.catch((err: callAxiosResponseInterface) => {
						resolve(getErrorResult(err, operation));
					});
			});
		},
		[checkBridge, validateId, getErrorResult, getHeaders, idManager, log, t, urls.postCancelTransaction, getDrawerErrorResult]
	);

	const postReset = useCallback(async (): Promise<UseDrawerResult> => {
		const operation = "Reset";
		if (!checkBridge()) return { result: false, errorMessage: t("system.error.bridge") };

		return new Promise<UseDrawerResult>((resolve) => {
			callAxios({
				method: "get",
				url: urls.getTransactionStatus,
				body: "{}",
				headers: getHeaders(idManager.getNextProgressiveRequestId()),
				smartTde: true
			})
				.then((response: callAxiosResponseInterface & { data: GetTransactionStatusDrawerResponse }) => {
					if (!response.data.operation_result) {
						resolve(getDrawerErrorResult(response.data.xHeaders["X-error-code"], operation));
					} else {
						// if transaction is not closed, need to cancel it
						if (!response.data.is_closed && response.data.transaction_id) {
							resolve(postCancelTransaction(response.data.transaction_id));
						} else {
							resolve({ result: true, data: response.data });
						}
					}
				})
				.catch((err: callAxiosResponseInterface) => {
					resolve(getErrorResult(err, operation));
				});
		});
	}, [checkBridge, getErrorResult, getHeaders, idManager, t, urls.getTransactionStatus, postCancelTransaction, getDrawerErrorResult]);

	return { checkDrawerReady, postStartTransaction, getTransactionStatus, postCancelTransaction, postReset };
};
