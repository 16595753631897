import { ExitToApp } from "@mui/icons-material";
import { Button, Paper } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useBridgeWrapper } from "../../hooks/useBridgeWrapper";

const AdminSmartTde = () => {
	const { t } = useTranslation();
	const { _openSmartTde } = useBridgeWrapper();

	const handleOpenSmartTde = useCallback(() => {
		_openSmartTde();
	}, [_openSmartTde]);

	return (
		<Paper sx={{ p: "1.5rem 0" }} elevation={0}>
			<Button variant="contained" color="success" onClick={handleOpenSmartTde} sx={{ mr: "1rem" }} endIcon={<ExitToApp />}>
				{t("common.open").toUpperCase()}
			</Button>
		</Paper>
	);
};

export default AdminSmartTde;
