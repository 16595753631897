import { Order } from "../types";

/**
 * When the user reaches the Menu page and starts creating its order (OrderMaker.tsx),
 * an "Order" entity is created using a unique orderUid (a timestamp).
 *
 * Continuing the Checkout flow, choosing an electronic payment, the kiosk asks the server for a payment Request.
 * Each of this orderRequest should use an idempotency key.
 * Our key (orderRequestId) consists of the "orderUid" itself and a numeric counter representing the request attempts for the current Order.
 *
 * The counter should increment at each request.
 *
 * But, if a request receive an expected error (network coverage, missing response, server temporary not reachable, etc...),
 * the next request should use the previous key (previous counter value).
 * This way we leverages the idempotency api pattern and we expect the response for the old request.
 *
 * The orderRequestId key is stored in localStorage
 */
export class OrderRequestManager {
	#separator: string = "_";
	#order: Order;

	constructor(order: Order) {
		this.#order = order;
	}

	/**
	 * Returns the next orderRequestId (and stores the updated value in storage).
	 *
	 * @returns next orderRequestId
	 */
	getNextOrderRequestId = (): string => {
		const requestNumber: number = this.#getInitialCounterByOrder(this.#order.orderUid);

		const nextOrderRequestId = this.#order.orderUid + this.#separator + String(requestNumber + 1);

		localStorage.setItem("orderRequest", nextOrderRequestId);

		return nextOrderRequestId;
	};

	/**
	 * Stores in localStorage the previous version of the orderRequestId
	 */
	revertLastOrderRequestId = (): void => {
		const requestNumber: number = this.#getInitialCounterByOrder(this.#order.orderUid);

		const prevOrderRequestId = requestNumber ? this.#order.orderUid + this.#separator + String(requestNumber - 1) : "";

		localStorage.setItem("orderRequest", prevOrderRequestId);
	};

	/**
	 * Delete orderRequestId from localStorage
	 */
	resetOrderRequestId = (): void => {
		localStorage.removeItem("orderRequest");
	};

	/**
	 * Search the localStorage for a stored orderRequest (related to the current order) and retrieves the counter part.
	 *
	 * @param orderUid
	 * @returns counter part of current orderRequestId
	 */
	#getInitialCounterByOrder = (orderUid: string): number => {
		const lastReq: string = localStorage.getItem("orderRequest") ?? "";
		let nextReqCounter: number = 0;

		if (lastReq.startsWith(orderUid)) {
			nextReqCounter = Number(lastReq.split(this.#separator)[1] ?? "1");
		}

		return nextReqCounter;
	};

	// UTILITY

	/**
	 * Takes the 4Order generated orderId and returns only the last part without leading zeros.
	 * e.g. orderId "10667_20240817082138_000002" becomes shortenOrderId "2".
	 *
	 * @param order
	 * @returns the shorten version of the order
	 */
	static getServerOrderIdShortened = (order: Order): string => {
		const orderId: string = order?.paymentInfo?.order_id ?? "";

		return orderId ? (orderId.split("_").at(-1) ?? "").replace(/^0+/, "") : ""; // remove leading zeros
	};
}
