/**
 * PAYMENT STEPS
 * Used to track the payment flow status in the checkout process
 *
 * The pattern used is a finite-state machine (FSM). Each state represents a step in the payment process.
 * Each state can have multiple transitions to other states.
 *
 * The payment flow is different depending on the payment method used:
 */

/**
 * STRIPE (external reader) / SATISPAY - server-driven
 * checkingCashSystem - creatingIntentAndActivateReader - checkingIntent | cancelingIntent
 *
 * STRIPE REACT NATIVE (embedded reader)
 * checkingCashSystem - checkingReaderRN - creatingIntent - activateReaderRN - checkingIntent | cancelingIntent
 */
export enum PaymentStepServer {
	checkingCashSystem = "checkingCashSystem",
	checkingReaderRN = "checkingReaderRN", // check reader usb connection through React Native bridge
	creatingIntent = "creatingIntent",
	activateReaderRN = "activateReaderRN", // activate reader through React Native bridge
	creatingIntentAndActivateReader = "creatingIntentAndActivateReader",
	checkingIntent = "checkingIntent",
	cancelingIntent = "cancelingIntent"
}

/**
 * AUTOMATIC CASH DRAWER
 */
export enum PaymentStepDrawer {
	checkingCashSystem = "checkingCashSystem",
	checkingDrawer = "checkingDrawer",
	creatingCashTransaction = "creatingCashTransaction",
	checkingCashTransaction = "checkingCashTransaction",
	cancelingCashTransaction = "cancelingCashTransaction"
}
