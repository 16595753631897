import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LogChannel, PaymentStepDrawer, PaymentStepServer } from "../../../../../constants";
import { useBridgeWrapper } from "../../../../../hooks/useBridgeWrapper";
import { useLogger } from "../../../../../hooks/useLogger";
import { BridgeResult } from "../../../../../types/reactNative/bridgeOutput";
import { useMessage } from "../../../../MessageHandler/MessageService";

interface ActivateReaderRNProps {
	clientSecret: string;
	setRequestStatus: Dispatch<SetStateAction<PaymentStepServer | PaymentStepDrawer>>;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	cancelPayment: () => void;
}
const ActivateReaderRN = ({ clientSecret, setRequestStatus, setIsLoading, cancelPayment }: ActivateReaderRNProps) => {
	const { t } = useTranslation();
	const message = useMessage();
	const { warn } = useLogger();
	const { hasBridge, _collectUsbPayment } = useBridgeWrapper();

	const activate = useCallback(async () => {
		const handleError = () => {
			warn("ActivateReaderRN error", LogChannel.payment);
			message({
				title: "",
				description: t("system.error.reader_not_found"),
				okCallback: activate,
				okLabel: t("common.retry"),
				cancelCallback: cancelPayment,
				cancelLabel: t("common.cancel") ?? ""
			});
		};

		if (hasBridge) {
			setIsLoading(true);

			await _collectUsbPayment(clientSecret ?? "")
				.then((result: BridgeResult) => setRequestStatus(PaymentStepServer.checkingIntent))
				.catch(() => handleError())
				.finally(() => setIsLoading(false));
		} else {
			cancelPayment();
		}
	}, [hasBridge, clientSecret, _collectUsbPayment, setRequestStatus, setIsLoading, message, cancelPayment, t, warn]);

	useEffect(() => {
		/* eslint-disable react-hooks/exhaustive-deps */
		activate();
	}, []);

	return null;
};

export default ActivateReaderRN;
