import { Box, Divider, FormControl, RadioGroup, Typography } from "@mui/material";
import { ChangeEvent, memo, useCallback, useEffect, useState } from "react";
import { ItemLinkedListItemMode, ItemLinkedListMode } from "../../../../constants";
import {
	cart_calculatePrice,
	cart_calculateQuantity,
	cart_createEmpty,
	cart_generateVariationUid,
	cart_getSimplifiedUid,
	cart_optionAdd,
	cart_remove
} from "../../../../hooks/useCart";
import { CartItem, ItemLinkedList, ItemSelectable } from "../../../../types";
import TableWrapper from "../../../Layout/Table/TableWrapper";
import { useTheme } from "../../../Theme/ThemeWrapper";
import CardsWrapper from "../../Items/CardsWrapper";
import ItemCard from "../../Items/ItemCard";
import VariationItemSelectableMultipleAdd, { VariationItemSelectableMultipleProps } from "./VariationItemSelectableMultipleAdd";
import VariationItemSelectableMultipleSub from "./VariationItemSelectableMultipleSub";
import VariationItemSelectableSingle from "./VariationItemSelectableSingle";

interface VariationItemLinkedListProps {
	currentCartItem: CartItem;
	setCurrentCartItem: (cartItem: CartItem) => void;
	itemLinkedList: ItemLinkedList;
	oldOptions: CartItem[];
	linkedListWithIssue: number[];
	setLinkedListWithIssue: (linkedListWithIssue: number[]) => void;
	activeStep?: boolean;
	isSetMenu?: boolean;
}

const VariationItemLinkedList = ({
	currentCartItem,
	setCurrentCartItem,
	itemLinkedList,
	oldOptions,
	linkedListWithIssue,
	setLinkedListWithIssue,
	activeStep = true,
	isSetMenu = false
}: VariationItemLinkedListProps) => {
	const { theme } = useTheme();
	const [availableSelItems, setAvailableSelItems] = useState<number>(1000);
	const [options, setOptions] = useState<CartItem[]>([]);

	const elaborate = (): void => {
		const calculatedOptions: CartItem[] = [];
		itemLinkedList.itemSelectable?.forEach((itemSelectable: ItemSelectable) => {
			// find existing option
			const existingOption: CartItem | undefined = currentCartItem.options.find(
				(existingCartItem: CartItem) => existingCartItem.uid === cart_generateVariationUid(itemSelectable.itemInfo.uid, itemLinkedList.tempId)
			);
			// otherwise create a 0 quantity cartItem for each itemSelectable
			calculatedOptions.push(existingOption ?? cart_createEmpty(itemSelectable, 0));
		});

		setOptions(calculatedOptions);

		const totalQuantityByLinkedList: number = cart_calculateQuantity(calculatedOptions);
		setAvailableSelItems(itemLinkedList.maxSelItems - totalQuantityByLinkedList);

		// handle validation: whether the linked list has selected the minimum amount of Selectable Items
		const hasIssue: boolean = totalQuantityByLinkedList - itemLinkedList.minSelItems < 0;
		const issues: number[] = [...linkedListWithIssue];

		const indexTempId: number = parseInt(itemLinkedList.tempId.split("|")[0]);
		if (hasIssue) {
			if (!issues.includes(indexTempId)) issues.push(indexTempId);
		} else {
			if (issues.includes(indexTempId)) {
				issues.splice(issues.indexOf(indexTempId), 1);
			}
		}
		if (issues.length !== linkedListWithIssue.length) setLinkedListWithIssue(issues);
	};

	useEffect(() => {
		elaborate();
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [itemLinkedList, currentCartItem]);

	const handleChangeGroup = (selectedUid: string) => {
		const selectedOption: CartItem | undefined = options.find((cartItem: CartItem) => {
			let uidToCheck: string = cartItem.uid;
			if (isSetMenu) uidToCheck = cart_getSimplifiedUid(cartItem.uid);
			return uidToCheck === selectedUid;
		});
		if (!selectedOption) return;
		if (selectedOption.item.itemInfo.snoozed === "true") return;
		let newOptions: CartItem[] = currentCartItem.options;
		const newOption: CartItem = {
			...selectedOption,
			quantity: 1,
			uid: cart_generateVariationUid(selectedOption.item.itemInfo.uid, itemLinkedList.tempId)
		};

		// Reset options
		options?.forEach((cartItem: CartItem) => {
			newOptions = cart_remove(cartItem.uid, newOptions);
		});

		newOptions = cart_optionAdd(newOption, newOptions);

		setCurrentCartItem({
			...currentCartItem,
			options: newOptions,
			resultingPrice: currentCartItem.item.itemInfo.price + cart_calculatePrice(newOptions)
		});
	};

	const getVariationItemSelectableMultipleProps = useCallback(
		(cartItem: CartItem): VariationItemSelectableMultipleProps => {
			return {
				option: cartItem,
				min: 0,
				max: cartItem.quantity + availableSelItems,
				initialQuantity: oldOptions.find((oldCartItem: CartItem) => oldCartItem.uid === cartItem.uid)?.quantity,
				currentCartItem: currentCartItem,
				setCurrentCartItem: setCurrentCartItem,
				itemLinkedListId: itemLinkedList.tempId
			};
		},
		[availableSelItems, currentCartItem, itemLinkedList.minSelItems, itemLinkedList.tempId, oldOptions, setCurrentCartItem]
	);

	return (
		<Box sx={!activeStep ? { display: "none" } : null}>
			<Divider sx={{ mt: "1.5rem", mb: "1rem" }} textAlign="left">
				<Typography color={theme.systemTheme.palette.text.primary}>{itemLinkedList.listTitle}</Typography>
			</Divider>

			{itemLinkedList.itemListMode === ItemLinkedListItemMode.subSingle ? (
				isSetMenu ? (
					<CardsWrapper menuColumn={3}>
						{options.map((cartItem: CartItem) => {
							const doNothing = () => {};
							return (
								<ItemCard
									key={`${itemLinkedList.tempId}_${cartItem.item.itemInfo.uid}`}
									itemSelectable={cartItem.item}
									menuColumn={3}
									itemCount={0}
									onNewCartItem={(itemSelectable: ItemSelectable) => handleChangeGroup(itemSelectable.itemInfo.uid)}
									decreaseItemCart={doNothing}
									compactMode={true}
								/>
							);
						})}
					</CardsWrapper>
				) : (
					<FormControl>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							defaultValue={options.find((c: CartItem) => c.item.itemInfo.selectedAsDefault === "true")?.uid}
							value={options.find((c: CartItem) => c.quantity === 1)?.uid ?? 0}
							onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeGroup(event.target.value)}
							name="radio-buttons-group"
						>
							{options.map((cartItem: CartItem, index: number) => (
								<VariationItemSelectableSingle key={`${itemLinkedList.tempId}_${index}`} option={cartItem} />
							))}
						</RadioGroup>
					</FormControl>
				)
			) : (
				<TableWrapper>
					{options.map((cartItem: CartItem, index: number) =>
						itemLinkedList.listMode === ItemLinkedListMode.add ? (
							<VariationItemSelectableMultipleAdd
								key={`${itemLinkedList.tempId}_${index}`}
								{...getVariationItemSelectableMultipleProps(cartItem)}
							/>
						) : (
							<VariationItemSelectableMultipleSub
								key={`${itemLinkedList.tempId}_${index}`}
								{...getVariationItemSelectableMultipleProps(cartItem)}
							/>
						)
					)}
				</TableWrapper>
			)}
		</Box>
	);
};

export default memo(VariationItemLinkedList);
