import { Box, Button, Stack, Typography } from "@mui/material";
import { Dispatch, memo, SetStateAction, useCallback, type JSX } from "react";
import { useTranslation } from "react-i18next";
import { Cc, getPrinterTypeName, PrinterType } from "../../../constants";
import useCcPrinter from "../../../services/Coatcheck/useCcPrinter";
import { MenuTicketFormat, PrintCcResponseData, PrintingResultCc, SettingsConfig } from "../../../types";
import InputSave from "../../Layout/Form/InputSave";
import { useMessage } from "../../MessageHandler/MessageService";
import AdminSettingsCcTickets from "./AdminSettingsCcTickets";

interface AdminSettingsCcProps {
	isAdmin: boolean;
	currentSettings: SettingsConfig;
	setCurrentSettings: Dispatch<SetStateAction<SettingsConfig>>;
	menuTickets: MenuTicketFormat[];
	onOpenWebPage: (url: string) => void;
}

const AdminSettingsCc = ({ isAdmin, currentSettings, setCurrentSettings, menuTickets, onOpenWebPage }: AdminSettingsCcProps) => {
	const { t } = useTranslation();
	const message = useMessage();
	const { CC, resetPrintingQueue, processPrintingQueue, retrievePrintingQueue } = useCcPrinter();

	const processQueueConfirm = useCallback((): void => {
		const queue = retrievePrintingQueue();
		if (queue.queue.length === 0) {
			message({
				title: t("system.admin.settings.cashSystem").toUpperCase(),
				description: t("system.admin.settings.isQueueEmpty"),
				okCallback: () => {},
				okLabel: t("common.ok").toUpperCase()
			});
		} else {
			message({
				title: t("system.admin.settings.cashSystem").toUpperCase(),
				description: t("system.admin.settings.processQueue", { item: queue.queue.length.toString() }),
				okLabel: t("common.print").toUpperCase(),
				okCallback: async () => {
					processPrintingQueue();
				},
				cancelLabel: t("common.cancel").toUpperCase() ?? "",
				cancelCallback: () => {},
				thirdActionLabel: t("system.admin.settings.actionEmptyQueue").toUpperCase() ?? "",
				thirdActionCallback: resetPrintingQueue
			});
		}
	}, [message, processPrintingQueue, resetPrintingQueue, retrievePrintingQueue, t]);

	const formatCcResponseWithModal = useCallback(
		(response: PrintingResultCc): void => {
			let isResponseOk: boolean = false;
			let genericErrorCode: string | undefined = undefined;
			let ccErrorCode: number | undefined = undefined;

			if (response.data !== null) {
				const data = response.data as PrintCcResponseData;
				isResponseOk = data.result === Cc.ErrorCode.noError;
				ccErrorCode = data.result;
			} else {
				genericErrorCode = response.message ?? undefined;
			}

			const formattedDescription: JSX.Element = (
				<Box>
					{isResponseOk
						? null
						: genericErrorCode
							? t(`system.error.${genericErrorCode}`)
							: (ccErrorCode as Cc.ErrorCode)
								? t(`system.error.cashSystem.cc.${ccErrorCode}`)
								: null}
					<Box fontSize={"0.8rem"}>
						<Typography fontSize={"0.8rem"}>{t("common.log")}</Typography>
						<pre>{JSON.stringify(response.data, null, 4)}</pre>
					</Box>
				</Box>
			);

			message({
				title: t("system.admin.settings.printers") + ": " + t(isResponseOk ? "common.ok" : "common.error"),
				description: formattedDescription,
				okCallback: () => {},
				okLabel: t("common.ok")
			});
		},
		[message, t]
	);

	const handlePrinterTest = useCallback(async () => {
		message({
			title: getPrinterTypeName(PrinterType.coatcheck),
			description: t("system.admin.settings.alertWaitAction"),
			okCallback: () => {},
			okLabel: ""
		});

		await CC.printerTest(currentSettings.ipCoatCheck).then((response: PrintingResultCc) => formatCcResponseWithModal(response));
	}, [CC, formatCcResponseWithModal, message, t, currentSettings.ipCoatCheck]);

	const handleCoatcheckIPChange = useCallback(
		async (value: string) => {
			setCurrentSettings((prev: SettingsConfig) => ({ ...prev, ipCoatCheck: value }));
		},
		[setCurrentSettings]
	);

	const handleOpenVirtual = useCallback(() => {
		onOpenWebPage("http://" + currentSettings.ipCoatCheck);
	}, [currentSettings.ipCoatCheck, onOpenWebPage]);

	const handlePrinterClosure = useCallback(async () => {
		message({
			title: "",
			description: t("system.admin.settings.dailyClosureMsg"),
			okCallback: async () => {
				setTimeout(async function () {
					message({
						title: t("system.admin.settings.printers"),
						description: t("system.admin.settings.alertWaitAction"),
						okCallback: () => {},
						okLabel: ""
					});
					await CC.printerClosure(currentSettings.ipCoatCheck).then((response: PrintingResultCc) => formatCcResponseWithModal(response));
				}, 500);
			},
			okLabel: t("common.yes"),
			cancelCallback: () => {},
			cancelLabel: t("common.cancel") ?? ""
		});
	}, [t, message, CC, formatCcResponseWithModal, currentSettings.ipCoatCheck]);

	return (
		<>
			<Stack direction="row" justifyContent="space-between">
				<Stack direction="row" sx={{ width: "58%" }}>
					<InputSave
						color="secondary"
						label={t("system.admin.settings.coatcheckIP")}
						value={currentSettings.ipCoatCheck}
						onSave={(value) => handleCoatcheckIPChange(value.toString())}
						inputProps={{ maxLength: 15, minLength: 7 }}
						disabled={!isAdmin}
						sx={{ width: "100%" }}
					/>
				</Stack>
			</Stack>
			<Stack direction="row" justifyContent="space-between" sx={{ mt: "0.75rem", "& > button": { lineHeight: 1.25, minHeight: "3.5rem" } }}>
				<Button
					color="success"
					disabled={currentSettings.ipCoatCheck === ""}
					sx={{ width: "15%" }}
					variant="contained"
					onClick={handlePrinterTest}
					size="small"
				>
					{t("common.test").toUpperCase()}
				</Button>
				<Button
					color="success"
					disabled={currentSettings.ipCoatCheck === "" || !isAdmin}
					sx={{ width: "15%" }}
					variant="contained"
					onClick={handleOpenVirtual}
					size="small"
				>
					{t("common.open").toUpperCase()}
				</Button>
				<Button
					color="success"
					disabled={currentSettings.ipCoatCheck === ""}
					sx={{ width: "34%" }}
					variant="contained"
					onClick={handlePrinterClosure}
					size="small"
				>
					{t("system.admin.settings.dailyClosureCashSystem").toUpperCase()}
				</Button>
				<Button
					color="success"
					disabled={currentSettings.ipCoatCheck === ""}
					sx={{ width: "34%" }}
					variant="contained"
					onClick={processQueueConfirm}
					size="small"
				>
					{t("system.admin.settings.processQueueAction").toUpperCase()}
				</Button>
			</Stack>
			&nbsp;
			{isAdmin ? <AdminSettingsCcTickets currentSettings={currentSettings} setCurrentSettings={setCurrentSettings} menuTickets={menuTickets} /> : null}
		</>
	);
};

export default memo(AdminSettingsCc);
