import { useNavigate } from "react-router";
import { RoutePath } from "../constants";
import { callCloseApp, callReloadApp } from "../services/WebviewV1/WebviewV1";
import { useBridgeWrapper } from "./useBridgeWrapper";

export const useWebview = () => {
	const navigate = useNavigate();
	const { hasBridge } = useBridgeWrapper();

	const appClose = () => {
		if (hasBridge) {
			navigate(RoutePath.closeTrigger);
		} else {
			callCloseApp();
			navigate(RoutePath.homePage);
		}
	};

	const appReload = () => {
		if (hasBridge) {
			navigate(RoutePath.reloadTrigger);
		} else {
			callReloadApp();
			navigate(RoutePath.homePage);
		}
	};

	return {
		appClose,
		appReload
	};
};
