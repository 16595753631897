import { Barcode } from "../types/Barcode";

/**
 * Parse a barcode string into a Barcode object.
 * @param barcode The version string.
 */
export function parseBarcode(barcode: string): Barcode | null {
	if (!barcode) {
		return null;
	}
	// arbitrary barcode length limits
	if (barcode.length <= 3 || barcode.length > 128) {
		return null;
	}

	const barcodeResult: Barcode = {
		source: barcode,
		isEan13: false,
		isWeightOpen: false,
		prefix: null,
		productCode: null,
		value: null,
		check: null
	};

	if (barcode.length === 13 && /^\d+$/.test(barcode)) {
		barcodeResult.isEan13 = true;
		barcodeResult.prefix = parseInt(barcode[0]);
		barcodeResult.productCode = parseInt(barcode.substring(1, 7));
		barcodeResult.value = parseInt(barcode.substring(7, 12));
		barcodeResult.check = parseInt(barcode[12]);
		if (barcodeResult.prefix === 2) {
			barcodeResult.isWeightOpen = true;
		}
	}

	return barcodeResult;
}
