import { useState } from "react";
import { Service4DeliveryEndpoints } from "..";
import { LogChannel } from "../../../constants";
import { defaultKioskMenu } from "../../../constants/defaults";
import { useCdnAssets } from "../../../hooks/useCdnAssets";
import { useLogger } from "../../../hooks/useLogger";
import { callMenuResponse, KioskMenu, useMenuResponse } from "../../../types";
import { callAxios, callAxiosResponseInterface } from "../../../utils/httpClient/AxiosWrapper";
import { menuAdapter } from "../../../utils/menuAdapter";
import { getStorageObject, setStorageObject } from "../../../utils/StorageWrapper";

export const useMenu = (): useMenuResponse => {
	const { log, warn } = useLogger();
	const { loadProductImages } = useCdnAssets();

	const [menuLoading, setMenuLoading] = useState<boolean>(false);
	const [menuError, setMenuError] = useState<boolean>(false);
	const [menuNotified, setMenuNotified] = useState<boolean>(true);

	const downloadMenu = async (isForced: boolean = false) => {
		if (isForced === true) {
			localStorage.setItem("menuHash", "forced");
			localStorage.setItem("barcodeHash", "forced");
		}

		setMenuLoading(true);

		await MenuLoad()
			.then((result: boolean) => {
				setMenuError(!result);
			})
			.catch(() => {
				setMenuError(true);
			})
			.finally(() => {
				setMenuNotified(false);
				setMenuLoading(false);
			});
	};

	const MenuLoad = async (): Promise<boolean> => {
		const menuHash = localStorage.getItem("menuHash") ?? "";
		const barcodeHash = localStorage.getItem("barcodeHash") ?? "";
		const restaurantId = localStorage.getItem("username") ?? "";
		const oldMenu = GetMenuFromStorage();

		return new Promise((resolve) => {
			callAxios({
				method: "post",
				url: Service4DeliveryEndpoints.menuGet,
				body: JSON.stringify({ hash: menuHash, barcodes_hash: barcodeHash }),
				headers: JSON.stringify({})
			})
				.then((response: callAxiosResponseInterface) => {
					const goodResponse = response as callMenuResponse;
					let adaptedMenu: KioskMenu | null = null;

					// the menu hash is different, we need to update the menu
					if (menuHash !== goodResponse.data.hash && goodResponse.data.menu) {
						adaptedMenu = new menuAdapter(goodResponse.data.menu).adapt();
						adaptedMenu.barcodes = oldMenu.barcodes;
						setStorageObject("menu", adaptedMenu);
						localStorage.setItem("menuHash", goodResponse.data.hash);

						loadProductImages(adaptedMenu.images ?? [], restaurantId).catch((error) => {
							/* log(error);*/
						});
						log("Menu downloaded", LogChannel.cloudSync);
					}

					// the barcodes hash is different, we need to update the barcodes
					if (barcodeHash !== goodResponse.data.barcodes_hash && goodResponse.data.barcodes && goodResponse.data.barcodes.Items) {
						if (!adaptedMenu) {
							adaptedMenu = oldMenu;
						}
						adaptedMenu = new menuAdapter(adaptedMenu).addBarcodes(goodResponse.data.barcodes.Items);
						setStorageObject("menu", adaptedMenu);
						localStorage.setItem("barcodeHash", goodResponse.data.barcodes_hash);
						log("Barcodes downloaded", LogChannel.cloudSync);
					}

					resolve(true);
				})
				.catch((err) => {
					warn(["Menu - get error: ", err], LogChannel.cloudSync);
					resolve(false);
				});
		});
	};

	return { downloadMenu, setMenuNotified, menuLoading, menuError, menuNotified };
};

export const GetMenuFromStorage = (): KioskMenu => {
	return (getStorageObject("menu") as KioskMenu) ?? defaultKioskMenu;
};
