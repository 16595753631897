import { Translate } from "@mui/icons-material";
import { Checkbox, Stack } from "@mui/material";
import { Dispatch, memo, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "../../../constants";
import { defaultConfig } from "../../../constants/defaults";
import { useLanguage } from "../../../hooks/useLanguage";
import { SettingsConfig } from "../../../types";
import LanguageFlag from "../../LanguageFlags/LanguageFlag";
import AdminDivider from "../../Layout/Divider/AdminDivider";
import { useMessage } from "../../MessageHandler/MessageService";

interface AdminSettingsLanguageProps {
	currentSettings: SettingsConfig;
	setCurrentSettings: Dispatch<SetStateAction<SettingsConfig>>;
}

const AdminSettingsLanguage = ({ currentSettings, setCurrentSettings }: AdminSettingsLanguageProps) => {
	const { t } = useTranslation();
	const message = useMessage();
	const { language: currentLanguage } = useLanguage();

	const handleAvailableLanguagesChange = useCallback(
		(changedLanguage: Language) => {
			const languages: Language[] = [...currentSettings.availableLanguages];
			const index: number = languages.indexOf(changedLanguage);

			if (index === -1) {
				languages.push(changedLanguage);
			} else {
				if (changedLanguage === currentLanguage) return null;
				if (languages.length === 1) {
					message({
						title: t("common.warning"),
						description: t("system.admin.settings.languageMandatory"),
						okCallback: () => {},
						okLabel: t("common.ok")
					});
					return null; // at least one language must be enabled
				}
				languages.splice(index, 1);
			}

			setCurrentSettings((prev: SettingsConfig) => ({ ...prev, availableLanguages: languages }));
		},
		[setCurrentSettings, currentSettings.availableLanguages, currentLanguage, message, t]
	);

	return (
		<>
			<AdminDivider label={t("system.admin.settings.languages")} icon={<Translate />} />
			<Stack direction="row" alignItems="center" justifyContent="left" flexWrap="wrap">
				{Object.values(Language).map((value: string, index, array) => {
					const language: Language = value as Language;
					const availableLanguages = currentSettings.availableLanguages ?? defaultConfig.settings.availableLanguages;
					const existing: boolean = availableLanguages.some((searchLang: string) => searchLang === value);
					const last: boolean = array.length === index + 1;

					const onChange = () => handleAvailableLanguagesChange(language);

					return (
						<Stack key={value} direction="row" alignItems="center" justifyContent="left" sx={{ mr: last ? 0 : "2rem" }}>
							<Checkbox color="success" checked={existing} onChange={onChange} sx={{ p: 0, "& .MuiSvgIcon-root": { fontSize: "3rem" } }} />
							<LanguageFlag small={true} language={language} />
						</Stack>
					);
				})}
			</Stack>
		</>
	);
};

export default memo(AdminSettingsLanguage);
