import { DeveloperBoard } from "@mui/icons-material";
import { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { defaultFirmwareVersion } from "../../../constants/defaults";
import { useBridgeWrapper } from "../../../hooks/useBridgeWrapper";
import { SettingsConfig } from "../../../types";
import AdminDivider from "../../Layout/Divider/AdminDivider";
import ToggleWithLabel from "../../Layout/Toggle/ToggleWithLabel";

interface AdminSettingsScannerProps {
	isAdmin: boolean;
	currentSettings: SettingsConfig;
	settingToggler: (key: keyof SettingsConfig) => void;
}

const AdminSettingsScanner = ({ isAdmin, currentSettings, settingToggler }: AdminSettingsScannerProps) => {
	const { t } = useTranslation();
	const { hasBridge, uspFirmwareVersion } = useBridgeWrapper();

	// Check if the communication with the USP is available
	const isUspAvailable = useMemo(() => {
		if (hasBridge) {
			return uspFirmwareVersion !== "" && uspFirmwareVersion !== defaultFirmwareVersion;
		}
		return false;
	}, [hasBridge, uspFirmwareVersion]);

	const handleScannerEnabledChange = useCallback(() => {
		settingToggler("isScannerEnabled");
	}, [settingToggler]);

	const handleProximityEnabledChange = useCallback(() => {
		settingToggler("isProximityEnabled");
	}, [settingToggler]);

	return (
		<>
			<AdminDivider label={t("system.admin.access.hardware")} icon={<DeveloperBoard />} />
			<ToggleWithLabel
				color="success"
				checked={isUspAvailable && currentSettings.isScannerEnabled}
				onChange={handleScannerEnabledChange}
				disabled={!isAdmin || !isUspAvailable}
				label={t("system.admin.settings.enableScanner")}
				sx={{ my: 3 }}
			/>

			<ToggleWithLabel
				color="success"
				checked={isUspAvailable && currentSettings.isProximityEnabled}
				onChange={handleProximityEnabledChange}
				disabled={!isAdmin || !isUspAvailable}
				label={t("system.admin.settings.enableProximity")}
				sx={{ my: 3 }}
			/>
		</>
	);
};

export default memo(AdminSettingsScanner);
