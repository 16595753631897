import { cloneDeep } from "lodash";
import { GoodType, ItemInfoTypology, ItemLinkedListMode, TicketFormatType } from "../constants";
import { upSellingItemNotOrdered } from "../constants/defaults";
import {
	BarcodeItemInfo,
	Category,
	CategoryChild,
	CategoryList,
	CheckoutMenu,
	ItemInfo,
	ItemInfoTranslations,
	ItemLinkedList,
	ItemSelectable,
	KioskMenu,
	MenuTicketFormat,
	UpSellingItem,
	Vat
} from "../types";
import { valueToCents } from "./Numbers";
import { asciiSafeConverter } from "./Strings";

export interface FieldChecker {
	hasCategoryListLink: boolean;
	hasCategoryOptListLink: boolean;
	hasItemLinkedList: boolean;
	hasMandatoryFields: boolean;
	hasFields: boolean;
	isSetMenu: boolean;
	hasDepthLevel3: boolean;
}

export class menuAdapter {
	kioskMenu: KioskMenu;
	categoryList: CategoryList[];
	barcodes: BarcodeItemInfo[];
	imageList: string[];
	vatList: Vat[];
	upSellingList: UpSellingItem[];
	tickets: MenuTicketFormat[];
	shortTextMaxLength: number = 80;

	constructor(kioskMenu: KioskMenu) {
		this.kioskMenu = kioskMenu;
		this.categoryList = this._removeCategoryListDuplicates(this.kioskMenu.categoryList);
		this.barcodes = kioskMenu.barcodes ?? [];
		this.imageList = [];
		this.vatList = [];
		this.upSellingList = [];
		this.tickets = [];
	}

	/**
	 * Loops through out the Menu structure and adapt it to the App needs:
	 * - TEMP - variation list of "subtraction" type has max Selling Items forced to 1
	 * - remove category list clones
	 * - create a list of products images path (ready to be pre-loaded)
	 * - create a list of Vats (to be mapped afterward in admin configuration)
	 * - create a list of Plu Items for upSelling (to be cross-checked with previously uploaded ordered list in configuration, if any)
	 * - mark categories containing only snoozed products as "snoozed"
	 * - convert prices to current format
	 * - trim shortText item description too shortTextMaxLength characters
	 * - add additional infos to single products (hasFields, hasMandatoryFields, isSetMenu, hasThirdLevel etc...)
	 * @returns KioskMenu
	 */
	adapt(): KioskMenu {
		this.imageList = [];
		this.vatList = [];
		this.upSellingList = [];
		this.tickets = [];

		const copiedCategoryList: CategoryList[] = cloneDeep(this.categoryList);
		this.categoryList = copiedCategoryList.map((categoryList: CategoryList) => {
			return {
				...categoryList,
				itemLinkedList: categoryList.itemLinkedList?.map((itemLinkedList: ItemLinkedList) => {
					return {
						...this._temporaryAlterItemLinkedListSubtraction(itemLinkedList),
						itemSelectable: this._recursivelyElaborateItemSelectable(itemLinkedList.itemSelectable, this.categoryList, "", ""),
						itemSelectableAllSnoozed: this._isItemSelectableAllSnoozed(itemLinkedList.itemSelectable),
						tempId: ""
					};
				})
			};
		});

		return {
			...this.kioskMenu,
			checkoutMenus: this.kioskMenu.checkoutMenus.map((checkoutMenu: CheckoutMenu) => {
				return {
					...checkoutMenu,
					categories: checkoutMenu.categories.map((category: Category) => {
						const parentCategoryIcon: string = category.icon;
						return {
							...category,
							children: category.children.map((categoryChild: CategoryChild) => {
								const catIdIndex: number = this.tickets.findIndex((mtf: MenuTicketFormat) => mtf.id === categoryChild.categoryCode);
								if (catIdIndex === -1) {
									this.tickets.push({
										id: categoryChild.categoryCode,
										desc: categoryChild.categoryTitle,
										type: TicketFormatType.categoryChild,
										cat: "",
										plu: []
									});
								}
								return {
									...categoryChild,
									itemSelectable: this._recursivelyElaborateItemSelectable(
										categoryChild.itemSelectable,
										this.categoryList,
										parentCategoryIcon,
										categoryChild.categoryCode,
										true
									),
									itemSelectableAllSnoozed: this._isItemSelectableAllSnoozed(categoryChild.itemSelectable)
								};
							})
						};
					})
				};
			}),
			categoryList: this.categoryList,
			barcodes: this.barcodes,
			images: this.imageList,
			vatList: this.vatList.sort((a, b) => (a.id < b.id ? -1 : 1)),
			upSellingList: this.upSellingList,
			tickets: this.tickets
		};
	}

	/**
	 * Add updated barcode list to the kiosk menu
	 * @param items
	 * @returns KioskMenu
	 */
	addBarcodes(items: BarcodeItemInfo[]): KioskMenu {
		this.barcodes = [];

		return {
			...this.kioskMenu,
			barcodes: this._adaptBarcodes(items)
		};
	}

	_adaptBarcodes(barcodes: BarcodeItemInfo[]): BarcodeItemInfo[] {
		return barcodes.map((barcode: BarcodeItemInfo) => {
			return this._processItemInfoCore(barcode, "", true, true) as BarcodeItemInfo;
		});
	}

	_processItemInfoCore(itemInfo: ItemInfo | BarcodeItemInfo, cat: string, isPlu: boolean, isBarcode?: boolean): ItemInfo | BarcodeItemInfo {
		// Populate VAT rates list
		let calculatedVatRef: string = itemInfo.vatRef ?? "";
		if (isPlu && itemInfo.price !== 0) {
			const newVat: Vat | null = this._createVatFromMenu(itemInfo.vatRef, itemInfo.vat);
			if (newVat !== null) {
				const itemIndex: number = this.vatList.findIndex((item) => item.id === newVat.id);
				calculatedVatRef = newVat.id ?? "";
				if (itemIndex === -1) this.vatList.push(newVat);
			}
		}

		// Tickets
		if (isPlu && cat) {
			const parentTicket = this.tickets.find((mtf: MenuTicketFormat) => cat === mtf.id);
			if (parentTicket) {
				const pluIdIndex2: number = parentTicket.plu.findIndex((mtf: MenuTicketFormat) => mtf.id === itemInfo.uid);
				if (pluIdIndex2 === -1) {
					parentTicket.plu.push({
						id: itemInfo.uid,
						desc: itemInfo.shortText,
						type: TicketFormatType.plu,
						cat: cat,
						plu: []
					});
				}
			}
		}

		// Item info
		const trimmedShortText = itemInfo.shortText.substring(0, this.shortTextMaxLength);
		const newItemInfo: ItemInfo | BarcodeItemInfo = {
			...itemInfo,
			shortText: trimmedShortText,
			price: isBarcode ? itemInfo.price : valueToCents(itemInfo.price),
			vatRef: calculatedVatRef,
			translations: itemInfo.translations?.map((translation: ItemInfoTranslations) => {
				const newTranslation: ItemInfoTranslations = translation;
				const trimmedTranslationShortText = translation.shortText.substring(0, this.shortTextMaxLength);
				newTranslation.shortText = trimmedTranslationShortText;
				const translationShortTextAsciiSafe = asciiSafeConverter(trimmedTranslationShortText);
				if (trimmedTranslationShortText !== translationShortTextAsciiSafe) newTranslation.shortTextAsciiSafe = translationShortTextAsciiSafe;
				return newTranslation;
			})
		};
		const shortTextAsciiSafe = asciiSafeConverter(trimmedShortText);
		if (trimmedShortText !== shortTextAsciiSafe) newItemInfo.shortTextAsciiSafe = shortTextAsciiSafe;

		return newItemInfo;
	}

	_recursivelyElaborateItemSelectable(
		items: ItemSelectable[],
		lists: CategoryList[],
		parentCategoryIcon: string,
		cat: string,
		isFirstLevel: boolean = false
	): ItemSelectable[] {
		const newItems: ItemSelectable[] = cloneDeep(items);

		return newItems.map((itemSelectable: ItemSelectable) => {
			const { hasCategoryListLink, hasCategoryOptListLink, hasItemLinkedList, hasMandatoryFields, hasFields, isSetMenu, hasDepthLevel3 }: FieldChecker =
				this._checkLinkedLists(itemSelectable, lists);

			// Populate image list
			if (itemSelectable.itemInfo.imageName) {
				this.imageList.push(itemSelectable.itemInfo.imageName);
			}

			const newItemInfo: ItemInfo | BarcodeItemInfo = this._processItemInfoCore(
				itemSelectable.itemInfo,
				cat,
				itemSelectable.itemInfo.tipology === ItemInfoTypology.plu
			);

			const calculatedItemSelectable: ItemSelectable = {
				...itemSelectable,
				itemInfo: newItemInfo as ItemInfo,
				parentCategoryIcon: parentCategoryIcon,
				hasItemLinkedList: hasItemLinkedList,
				hasCategoryListLink: hasCategoryListLink,
				hasCategoryOptListLink: hasCategoryOptListLink,
				hasMandatoryFields: hasMandatoryFields,
				hasFields: hasFields,
				isSetMenu: isSetMenu,
				hasDepthLevel3: hasDepthLevel3,
				itemLinkedList: itemSelectable.itemLinkedList?.map((itemLinkedList: ItemLinkedList) => {
					return {
						...this._temporaryAlterItemLinkedListSubtraction(itemLinkedList),
						itemSelectable: this._recursivelyElaborateItemSelectable(itemLinkedList.itemSelectable, lists, "", ""),
						itemSelectableAllSnoozed: this._isItemSelectableAllSnoozed(itemLinkedList.itemSelectable),
						tempId: ""
					};
				})
			};

			//Populate UpSelling list
			if (isFirstLevel && itemSelectable.itemInfo.tipology === ItemInfoTypology.plu) {
				const pluIdIndex: number = this.upSellingList.findIndex((item: UpSellingItem) => item.pluId === itemSelectable.itemInfo.uid);
				if (pluIdIndex === -1) {
					this.upSellingList.push({
						pluId: itemSelectable.itemInfo.uid,
						itemSelectable: calculatedItemSelectable,
						order: upSellingItemNotOrdered,
						excluded: false
					});
				}
			}

			return calculatedItemSelectable;
		});
	}

	/**
	 * Check if the item has linked lists and if they have mandatory fields
	 * On top of that, it checks if the item is a Set Menu and if it has a third level of depth (i.e. a linked list with nested linked lists)
	 *
	 * @param itemSelectable
	 * @param lists
	 * @returns
	 */
	_checkLinkedLists(itemSelectable: ItemSelectable, lists: CategoryList[]): FieldChecker {
		// Linked list without category
		let itemLinkedLists: ItemLinkedList[] = itemSelectable.itemLinkedList;

		// Category List
		const categoryListLink: CategoryList[] = lists.filter((category: CategoryList) => itemSelectable.categoryListLink.includes(category.categoryListId));
		categoryListLink?.forEach((category: CategoryList) => {
			itemLinkedLists = [...itemLinkedLists, ...category.itemLinkedList];
		});

		// Optional Category List
		const categoryOptListLink: CategoryList[] = lists.filter((category: CategoryList) =>
			itemSelectable.categoryOptListLink.includes(category.categoryListId)
		);
		categoryOptListLink?.forEach((category: CategoryList) => {
			itemLinkedLists = [...itemLinkedLists, ...category.itemLinkedList];
		});

		// Search for linked list where an action is necessary
		const mandatory: ItemLinkedList | undefined = itemLinkedLists?.find((itemLinkedList: ItemLinkedList) => itemLinkedList.minSelItems > 0);

		// Is it a Set Menu? (i.e. this PLU has at least one linked list made of Items of type PLU)
		const isSetMenu: boolean = itemLinkedLists?.some((itemLinkedLists: ItemLinkedList) =>
			itemLinkedLists.itemSelectable.some((itemSelectable: ItemSelectable) => itemSelectable.itemInfo.tipology === ItemInfoTypology.plu)
		);

		// Menu Depth of Level 3? (e.g. 1. Set Menu -> 2. Select between PLUs options -> 3. Mandatory cond_add)
		let hasDepthLevel3: boolean = false;
		if (isSetMenu) {
			hasDepthLevel3 = itemLinkedLists?.some((itemLinkedLists: ItemLinkedList) =>
				itemLinkedLists.itemSelectable.some((itemSelectable: ItemSelectable) => itemSelectable.hasMandatoryFields === true)
			);
		}

		return {
			hasCategoryListLink: categoryListLink.length > 0,
			hasCategoryOptListLink: categoryOptListLink.length > 0,
			hasItemLinkedList: itemSelectable.itemLinkedList.length > 0,
			hasMandatoryFields: mandatory !== undefined,
			hasFields: itemLinkedLists.length > 0,
			isSetMenu: isSetMenu,
			hasDepthLevel3: hasDepthLevel3
		};
	}

	/**
	 * Check if all items in a list are snoozed
	 * @param items
	 * @returns
	 */
	_isItemSelectableAllSnoozed(items: ItemSelectable[]): boolean {
		const newItems: ItemSelectable[] = cloneDeep(items);
		let allSnoozed: boolean = true;

		newItems.forEach((itemSelectable: ItemSelectable) => {
			allSnoozed = allSnoozed && itemSelectable.itemInfo.snoozed === "true";
		});

		return allSnoozed;
	}

	/**
	 * Remove duplicate Category List
	 * When the same list is used in multiple checkout menus, the list is duplicated. This function removes the duplicates.
	 * @param lists
	 * @returns
	 */
	_removeCategoryListDuplicates(lists: CategoryList[]): CategoryList[] {
		const newLists: CategoryList[] = cloneDeep(lists);

		return newLists.filter((value, index, self) => index === self.findIndex((t) => t.categoryListId === value.categoryListId));
	}

	/**
	 *	TEMPORARY HACK - Currently Cash system does not handle subtraction list item variations cardinality.
	 *	For this reason subtraction list items must be handled as single quantity variation (No matter maxSelItems of the list says).
	 *	e.g. For a Sandwich you can subtract "lettuce". You can't remove 2 portions of Lettuce.
	 *
	 *  Hence, here, the maxSelItems is capped to the number of variations for this list.
	 *  Not enough! To make each variation limited to just 1 quantity, a JSX component check is needed.
	 */
	_temporaryAlterItemLinkedListSubtraction(item: ItemLinkedList): ItemLinkedList {
		return {
			...item,
			maxSelItems: item.listMode === ItemLinkedListMode.sub ? Math.min(item.itemSelectable.length, item.maxSelItems) : item.maxSelItems
		};
	}

	/**
	 * Create a VAT object from a VAT reference and a VAT rate
	 *
	 * @param vatRef a JSON string representing a VAT reference
	 * @param vat a VAT rate
	 * @returns a VAT object or null if the input is not valid
	 *
	 * @example
	 * _createVatFromMenu('{"goodType":"goods","breakdown":false}', '10.00')
	 * returns:
	 * { id: '_10.00__goods_false', refCode: '', rate: '10.00', exemption: '', goodType: 'goods', breakdown: false, cashDepartment: '' }
	 *
	 * @example
	 * _createVatFromMenu('{"refCode":"47.26.00","goodType":"services","breakdown":false, "exemption":"N2.1"}', '22.00')
	 * returns:
	 * { id: '47.26.00_22.00_N2_services_false', refCode: '47.26.00', rate: '22.00', exemption: 'N2', goodType: 'services', breakdown: false, cashDepartment: '' }
	 *
	 */
	_createVatFromMenu(vatRef: string, vat: string): Vat | null {
		// If vat or vatRef are not provided or are empty, return null
		if (vat == null || vatRef == null || vat === "" || vatRef === "") return null;

		try {
			const parsed: Vat = JSON.parse(vatRef);

			// check if the parsed object has the expected fields (goodType and breakdown)
			if (parsed.goodType === undefined || parsed.breakdown === undefined) return null;
			// check if the goodType is a valid value
			if (!Object.values(GoodType).includes(parsed.goodType)) return null;
			// check if the breakdown is a boolean
			if (typeof parsed.breakdown !== "boolean") return null;

			const refCode = parsed.refCode ?? "";
			const rate = vat.padStart(5, "0");
			// N2.1 and N2.2 are the same exemption type for the cash system (N2)
			const exemption = parsed.exemption
				? parsed.exemption.includes(".")
					? parsed.exemption.substring(0, parsed.exemption.indexOf("."))
					: parsed.exemption
				: "";
			const goodType = parsed.goodType;
			const breakdown = parsed.breakdown;
			const id = [refCode, rate, exemption, goodType, breakdown].join("_");

			return { id, refCode, rate, exemption, goodType, breakdown, cashDepartment: "" };
		} catch (error) {
			return null;
		}
	}
}
