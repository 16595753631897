import { HttpStatusCode } from "axios";
import { useTheme } from "../../components/Theme/ThemeWrapper";
import { Cc, LogChannel } from "../../constants";
import { useLogger } from "../../hooks/useLogger";
import { PrintCcResponse, PrintingItemCc, PrintingResultCc } from "../../types";
import { callAxios, callAxiosResponseInterface } from "../../utils/httpClient/AxiosWrapper";

/**
 * Custom hook for managing CoatCheck printing operations.
 *
 * This hook provides functions to check the status of the printer,
 * print single items, and test the printer by printing an example item.
 *
 * @returns {Object} An object containing the following functions:
 * - `printSingleItem`: Prints a single printing item and returns the result.
 * - `printerCheckStatus`: Checks the status of the printer.
 * - `printerTest`: Tests the printer by printing an example item.
 * - `printerClosure`: Tests the printer by printing an example item.
 */
export const useCc = () => {
	const { log, warn } = useLogger();
	const { settings } = useTheme();

	/**
	 * Prints a single printing item and returns the result of the printing operation.
	 *
	 * @param {PrintingItemCc} printingItem - The item to be printed.
	 * @param {string} [ip] - Optional IP address of the printer.
	 * @returns {Promise<PrintingResultCc>} A promise that resolves to the result of the printing operation.
	 *
	 * The function performs the following steps:
	 * 1. Initializes a `PrintingResultCc` object with default values.
	 * 2. Checks the status of the printer.
	 * 3. If the printer status is OK, it attempts to print the item.
	 * 4. Handles various error codes and updates the `PrintingResultCc` object accordingly.
	 * 5. Catches any errors and updates the `PrintingResultCc` object with error information.
	 *
	 * Possible error messages:
	 * - "printer-not-ready": The printer is not ready.
	 * - "out-of-paper": The printer is out of paper.
	 * - "non-blocking-error": A non-blocking error occurred.
	 * - Other error codes from the response status.
	 */
	const printSingleItem = async (printingItem: PrintingItemCc, ip?: string): Promise<PrintingResultCc> => {
		const printingResult: PrintingResultCc = {
			data: null,
			isPrinted: true,
			isPrinting: false,
			isInError: false,
			message: ""
		};

		await printerCheckStatus(ip)
			.then(async (responseStatus: PrintCcResponse | callAxiosResponseInterface) => {
				if (responseStatus?.status === HttpStatusCode.Ok) {
					printingResult.data = responseStatus.data;
					if (responseStatus?.data?.result === Cc.ErrorCode.noError) {
						await callPrinter(printingItem, 1, ip).then((responsePrint) => {
							if (responsePrint?.status === HttpStatusCode.Ok) {
								printingResult.data = responsePrint.data;
								if (responsePrint?.data?.result !== Cc.ErrorCode.noError) {
									if (responsePrint?.data?.result === Cc.ErrorCode.THE_PRINTER_IS_NOT_READY) {
										printingResult.message = "printer-not-ready";
										printingResult.isPrinted = false;
										printingResult.isPrinting = true;
									} else if (responsePrint?.data?.result === Cc.ErrorCode.PRINTER_STATUS_HEADER_OPEN_OR_PAPER_END) {
										printingResult.message = "out-of-paper";
										printingResult.isPrinted = false;
									} else {
										throw new Error("non-blocking-error");
									}
								}
							} else {
								throw new Error(responseStatus.data.error_code);
							}
						});
					} else {
						if (responseStatus?.data?.result === Cc.ErrorCode.PRINTER_STATUS_HEADER_OPEN_OR_PAPER_END) {
							printingResult.message = "out-of-paper";
							printingResult.isPrinted = false;
						} else if (responseStatus?.data?.result === Cc.ErrorCode.THE_PRINTER_IS_NOT_READY) {
							printingResult.message = "printer-not-ready";
							printingResult.isPrinted = false;
							printingResult.isPrinting = true;
						} else {
							throw new Error("non-blocking-error");
						}
					}
				} else {
					throw new Error(responseStatus.data.error_code);
				}
			})
			.catch((errCode: string) => {
				printingResult.message = errCode;
				printingResult.isPrinted = false;
				printingResult.isPrinting = false;
				printingResult.isInError = true;
			});

		return printingResult;
	};

	/**
	 * Checks the status of the cash system by sending a command to the printer.
	 *
	 * @param {string} [ip] - Optional IP address of the printer.
	 * @returns {Promise<PrintCcResponse>} A promise that resolves to the response from the printer.
	 */
	const printerCheckStatus = (ip?: string): Promise<PrintCcResponse> => {
		const printingItem: PrintingItemCc = {
			commandType: Cc.Command.getStatus
		};

		return callPrinter(printingItem, undefined, ip);
	};

	/**
	 * Sends a Z-report command to the printer and returns the response.
	 *
	 * @param {string} [ip] - Optional IP address of the printer.
	 * @returns {Promise<PrintingResultCc>} A promise that resolves to the printer's response.
	 */
	const printerClosure = (ip?: string): Promise<PrintingResultCc> => {
		const printingItem: PrintingItemCc = {
			commandType: Cc.Command.zReport
		};

		return printSingleItem(printingItem, ip);
	};

	/**
	 * Tests the cash system by printing an example item.
	 *
	 * @param {string} [ip] - Optional IP address of the printer.
	 * @returns {Promise<PrintingResultCc>} A promise that resolves to the result of the printing operation.
	 */
	const printerTest = async (ip?: string): Promise<PrintingResultCc> => {
		const printingItem: PrintingItemCc = {
			commandType: Cc.Command.printExample,
			ID: Cc.Format.A
		};

		return printSingleItem(printingItem, ip);
	};

	/**
	 * Sends a print command to the CoatCheck printer.
	 *
	 * @param {PrintingItemCc} printingItem - The item to be printed, containing necessary print details.
	 * @param {number} [retries=3] - Optional number of retry attempts in case of failure.
	 * @param {string} [ip] - Optional IP address of the printer.
	 * @returns {Promise<PrintCcResponse | callAxiosResponseInterface>} - A promise that resolves to the print response or an error response.
	 */
	const callPrinter = (printingItem: PrintingItemCc, retries?: number, ip?: string): Promise<PrintCcResponse | callAxiosResponseInterface> => {
		const HOST = ip !== undefined ? ip : (settings.ipCoatCheck ?? "");
		const url = "http://" + HOST + "/update/command.php";

		const textBody = buildTextBody(printingItem);

		return new Promise((resolve) => {
			callAxios(
				{
					method: "post",
					url: url,
					body: textBody,
					headers: JSON.stringify({}),
					cashsystem: true
				},
				retries ?? 3
			)
				.then((response: PrintCcResponse) => {
					const castResponse = response as PrintCcResponse;
					log(`CC ${printingItem.commandType}: ${castResponse.data.msg}`, LogChannel.cashSystem);
					resolve(castResponse);
				})
				.catch((err: callAxiosResponseInterface) => {
					warn(`CC ${printingItem.commandType} - error ${err.status ?? undefined}: ${err.data.error_code ?? undefined}`, LogChannel.cashSystem);
					resolve(err);
				});
		});
	};

	/**
	 * Constructs a FormData object from a given PrintingItemCc object.
	 *
	 * This function iterates over the key-value pairs of the provided
	 * printingItem and appends each pair to a new FormData object.
	 *
	 * @param printingItem - An object of type PrintingItemCc containing
	 *                       key-value pairs to be added to the FormData.
	 * @returns A FormData object populated with the key-value pairs from
	 *          the provided printingItem.
	 */
	const buildTextBody = (printingItem: PrintingItemCc): FormData => {
		const formData = new FormData();

		for (const [key, value] of Object.entries(printingItem)) {
			formData.append(key, value);
		}

		return formData;
	};

	return {
		printSingleItem,
		printerCheckStatus,
		printerTest,
		printerClosure
	};
};
