import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PaymentStepDrawer, PaymentStepServer } from "../../../../../constants";
import { DrawerErrorCode } from "../../../../../constants/enum/SmartTde/SmartTdeErrors";
import { useDrawer } from "../../../../../services/SmartTDE";
import { TransactionStatusDrawer } from "../../../../../types/SmartTde";
import { useMessage } from "../../../../MessageHandler/MessageService";

interface CreateCashTransactionProps {
	amount: number;
	setRequestStatus: Dispatch<SetStateAction<PaymentStepServer | PaymentStepDrawer>>;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	cancelPayment: () => void;
	setCashTransaction: Dispatch<SetStateAction<TransactionStatusDrawer | null>>;
}
const CreateCashTransaction = ({ amount, setRequestStatus, setIsLoading, cancelPayment, setCashTransaction }: CreateCashTransactionProps) => {
	const { t } = useTranslation();
	const message = useMessage();
	const { postStartTransaction } = useDrawer();

	const create = async () => {
		setIsLoading(true);

		try {
			const response = await postStartTransaction(amount);
			if (response.result && response.data.transaction_id) {
				setCashTransaction({
					operation_result: true,
					transaction_id: response.data.transaction_id,
					to_pay: amount,
					is_closed: false,
					non_erogato: 0
				});
				setRequestStatus(PaymentStepDrawer.checkingCashTransaction);
			} else {
				message({
					title: response.errorMessage ?? t(`system.error.smartTde.drawer.${DrawerErrorCode.TRY_AGAIN_LATER}`),
					description: t("common.retryQuestion"),
					okCallback: create,
					okLabel: t("common.yes").toUpperCase(),
					cancelCallback: cancelPayment,
					cancelLabel: t("common.cancel").toUpperCase()
				});
			}
		} catch (err: unknown) {
			/* */
		}
		setIsLoading(false);
	};

	useEffect(() => {
		/* eslint-disable react-hooks/exhaustive-deps */
		create();
	}, []);

	return null;
};

export default CreateCashTransaction;
