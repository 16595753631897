import { Add, DeleteOutline, Remove } from "@mui/icons-material";
import { ButtonGroup, alpha } from "@mui/material";
import { memo } from "react";
import { SoundEffectProps } from "../../../hooks/effects/useSoundEffect";
import { isDarkColor } from "../../../utils/color";
import { useTheme } from "../../Theme/ThemeWrapper";
import "./CounterButtonGroup.scss";
import TouchButton from "./TouchButton";

interface CounterButtonGroupProps extends SoundEffectProps {
	counter: number;
	min?: number; // counter min value
	max?: number; // counter max value
	quantityToCompare?: number; // "minus" and "plus" buttons become colored when the counter is grater or lower than this "quantityToCompare"
	isRemover?: boolean; // If true, the "minus" button icon becomes a "trash bin" when its value is 1
	onDecrement: () => void;
	onIncrement: () => void;
	size?: "medium" | "large";
}

const CounterButtonGroup = ({
	min = 0,
	max = 1000,
	quantityToCompare = undefined,
	isRemover = false,
	size = "medium",
	muteSound,
	tone,
	...rest
}: CounterButtonGroupProps) => {
	const { theme } = useTheme();

	return (
		<ButtonGroup
			className={`counterGroup ${size}`}
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			<TouchButton
				title="remove"
				color={quantityToCompare != null && rest.counter < quantityToCompare ? "success" : "info"}
				variant="contained"
				size="large"
				startIcon={rest.counter === 1 && (isRemover ?? false) ? <DeleteOutline /> : <Remove />}
				disabled={rest.counter <= min}
				onClick={rest.onDecrement}
				muteSound={muteSound}
			/>

			<TouchButton
				title="counter"
				className="counter"
				variant="text"
				color="info"
				sx={{
					borderColor: `${alpha(
						isDarkColor(theme.customTheme.palette.background.paper) ? theme.customTheme.palette.grey[600] : theme.customTheme.palette.grey[400],
						0.25
					)} !important`,
					opacity: "0.75"
				}}
				muteSound={true}
			>
				{rest.counter}
			</TouchButton>

			<TouchButton
				title="add"
				color={quantityToCompare != null && rest.counter > quantityToCompare ? "success" : "info"}
				variant="contained"
				size="large"
				startIcon={<Add />}
				disabled={rest.counter >= max}
				onClick={rest.onIncrement}
				muteSound={muteSound}
				tone={tone}
			/>
		</ButtonGroup>
	);
};

export default memo(CounterButtonGroup);
