import { Container, Dialog } from "@mui/material";
import { HttpStatusCode } from "axios";
import { MouseEvent as ReactMouseEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router";
import { AdminRole, Cc, CheckoutMethod, LogChannel, ModalViewName, PrinterType, RoutePath } from "../../constants";
import { defaultAppConfig } from "../../constants/defaults";
import useErrorBoundaryLog from "../../hooks/useErrorBoundaryLog";
import { useLogger } from "../../hooks/useLogger";
import { useModal } from "../../hooks/useModal";
import useOrderDocumentStack from "../../hooks/useOrderDocumentStack";
import { useWebview } from "../../hooks/useWebview";
import { GetMenuFromStorage, useRestaurantInfo } from "../../services/4Delivery";
import useCcPrinter from "../../services/Coatcheck/useCcPrinter";
import useRtPrinter from "../../services/Rt/useRtPrinter";
import { CheckoutMenu, KioskMenu, MenuTicketFormat, PrintCcResponse, PrintRtResponse, TicketFormat, Vat } from "../../types";
import { getStorageObject } from "../../utils/StorageWrapper";
import PinPickerModal from "../Checkout/Modals/PinPickerModal";
import LanguageFlagStrip from "../LanguageFlags/LanguageFlagStrip";
import HiddenAdminButton from "../Layout/Buttons/HiddenAdminButton";
import TouchArea from "../Layout/Buttons/TouchArea";
import { useMessage } from "../MessageHandler/MessageService";
import { useTheme } from "../Theme/ThemeWrapper";
import "./Home.scss";
import HomeBackground from "./HomeBackground";

const Home = () => {
	useErrorBoundaryLog();
	const message = useMessage();
	const { log } = useLogger();
	const { theme, settings } = useTheme();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { modal, openModal, closeModal } = useModal();
	const { RT } = useRtPrinter();
	const { CC } = useCcPrinter();
	const { processDocumentStack } = useOrderDocumentStack();
	const { getCloudConfigAndMenu } = useRestaurantInfo();
	const { appReload } = useWebview();
	const kioskMenu: KioskMenu = GetMenuFromStorage();

	const [isLoadingFromBack, setIsLoadingFromBack] = useState(false);

	const existingMethods: CheckoutMethod[] = useMemo(() => {
		// Read available checkout method from Configuration
		// and refine the list excluding those not present in the json menu
		return settings.availableCheckoutMethods.filter((method: CheckoutMethod) =>
			kioskMenu.checkoutMenus.some((checkoutMenu: CheckoutMenu) => checkoutMenu.checkout === method)
		);
	}, [settings.availableCheckoutMethods, kioskMenu]);

	const isMissingLocalStorageRequirements = (): boolean => {
		const requiredItems = ["username", "password", "menuHash"];
		const hasSomeMissingItem = requiredItems.some((item) => !localStorage.getItem(item));
		const menu = getStorageObject("menu");
		return hasSomeMissingItem || !menu;
	};

	const checkPrinterRequirements = (): { errorMessage?: string } => {
		if (!settings.isCashSystemEnabled) return {};

		// BASIQ printer
		if (settings.printerType === PrinterType.basiq) {
			if (!settings.cashSystemIP) return { errorMessage: t("system.admin.settings.printerNotSet") };

			if (!settings.isFiscalMode) return {};

			// Do not access checkout if fiscal mode is enabled and not all the Vats are mapped
			let allVatsAreMapped: boolean = true;
			kioskMenu.vatList?.forEach((vat: Vat) => {
				const vatIsMapped: boolean = settings.vatAssociations.some((searchVat: Vat) => searchVat.id === vat.id);
				if (!vatIsMapped) allVatsAreMapped = false;
			});
			if (allVatsAreMapped) {
				return {};
			} else {
				return { errorMessage: t("system.admin.settings.vatMappingMandatory") };
			}
		}

		// COATCHECK printer
		if (settings.printerType === PrinterType.coatcheck) {
			if (!settings.ipCoatCheck) return { errorMessage: t("system.admin.settings.printerNotSet") };

			// Do not access checkout if tickets layout are not mapped
			let allPlusAreMapped: boolean = true;
			kioskMenu.tickets?.forEach((mtf: MenuTicketFormat) => {
				const isCategoryMapped = settings.ticketFormats.some((tf: TicketFormat) => tf.id === mtf.id && tf.type === mtf.type && tf.code !== undefined);
				if (isCategoryMapped) return; // if the category is mapped no need to check the children plus

				mtf.plu.forEach((pluMtf: MenuTicketFormat) => {
					const isPluMapped = settings.ticketFormats.some(
						(pluTf: TicketFormat) => pluTf.id === pluMtf.id && pluTf.type === pluMtf.type && pluTf.cat === pluMtf.cat && pluTf.code !== undefined
					);
					if (!isPluMapped) allPlusAreMapped = false;
				});
			});

			if (allPlusAreMapped) {
				return {};
			} else {
				return { errorMessage: t("system.admin.settings.ticketMappingMandatory") };
			}
		}
		return {};
	};

	const gotoCheckoutChoose = (e: ReactMouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();

		// If Fast Sync is enabled always reload menu on any interaction
		if (settings.hasFastSync) {
			getCloudConfigAndMenu();
		}

		const check = checkPrinterRequirements();

		if (check.errorMessage) {
			message({
				title: t("common.warning"),
				description: check.errorMessage,
				okCallback: () => {},
				okLabel: t("common.ok")
			});
			return;
		}

		// Do not access "Choose Page" if less than 2 checkout methods are available and actually exist in the Menu
		// If there are available and existing checkout methods... go to choose page
		if (existingMethods.length > 1) {
			navigate(RoutePath.choosePage);
		} else if (existingMethods.length === 1) {
			// if there is just one method... skip choose page
			navigate(RoutePath.orderPage.replace(":method", existingMethods[0] as unknown as string));
		} else {
			//...otherwise warn and stay on homepage
			message({
				title: t("common.warning"),
				description: t("system.admin.settings.checkoutMandatory"),
				okCallback: () => {},
				okLabel: t("common.ok")
			});
		}
	};

	const handleConfirmPin = (pin: string): void => {
		if (pin === (theme.config.pin ?? defaultAppConfig.pin)) {
			navigate(RoutePath.adminPage.replace(":role", AdminRole.administrator as unknown as string));
		} else if (pin === (theme.config.pinOperator ?? defaultAppConfig.pinOperator)) {
			navigate(RoutePath.adminPage.replace(":role", AdminRole.operator as unknown as string));
		} else {
			closeModal();
		}
	};

	const handleOpenModal = (): void => {
		openModal(ModalViewName.pinPicker);
	};

	useEffect(() => {
		const initialize = async () => {
			if (isMissingLocalStorageRequirements()) {
				navigate(RoutePath.loginPage);
			} else {
				setIsLoadingFromBack(true);
				await getCloudConfigAndMenu();
				setIsLoadingFromBack(false);
				processDocumentStack();
			}
		};

		initialize();

		// Initialize last update since reopening the app the service worker is fresh
		if (sessionStorage.getItem("lastUpdate") === null) sessionStorage.setItem("lastUpdate", new Date().toISOString());
		/* eslint-disable react-hooks/exhaustive-deps */
	}, []);

	const timer = useIdleTimer({
		name: "30minutes-inactive-timer-home-page",
		onIdle: () => {
			getCloudConfigAndMenu();
			timer.reset();

			// if 24 hours have passed since the last update, reload the app
			const lastUpdate = new Date(sessionStorage.getItem("lastUpdate") ?? "");
			const now = new Date();
			if (now.valueOf() - lastUpdate.valueOf() > 1000 * 60 * 60 * 24) {
				log("24 hours of inactivity", LogChannel.generic);
				appReload();
			}
		},
		timeout: 1000 * 60 * 30
	});

	const checkStatusCashSystem = useIdleTimer({
		name: "5minutes-inactive-check-cash-system-and-fiscal-document-to-send",
		onIdle: () => {
			if (settings.isCashSystemEnabled) {
				if (settings.printerType === PrinterType.basiq) {
					checkRtStatus();
				} else {
					checkCcStatus();
				}
				checkStatusCashSystem.reset();
			}
			processDocumentStack();
		},
		timeout: 1000 * 60 * 5
	});

	const checkRtStatus = async () => {
		await RT.cashSystemCheckStatus().then((response: PrintRtResponse) => {
			// When idling at home, the cash system error is only displayed if the response is successful
			// and it's in error, it's in inactivity or key != 1
			if (
				response.status === HttpStatusCode.Ok &&
				(response.data.status.error === true || response.data.status.inattivita === true || response.data.status.key !== 1)
			) {
				message({
					title: "",
					description: t("system.error.cashSystem.statusError"),
					okCallback: checkRtStatus,
					okLabel: t("common.retry"),
					cancelCallback: () => navigate(RoutePath.homePage),
					cancelLabel: t("common.cancel") ?? ""
				});
			}
		});
	};

	const checkCcStatus = async () => {
		await CC.printerCheckStatus().then((response: PrintCcResponse) => {
			// When idling at home, the cash system error is only displayed if the response is successful
			if (response.status === HttpStatusCode.Ok && response?.data?.result !== Cc.ErrorCode.noError) {
				message({
					title: "",
					description: t("system.error.cashSystem.statusError"),
					okCallback: checkCcStatus,
					okLabel: t("common.retry"),
					cancelCallback: () => navigate(RoutePath.homePage),
					cancelLabel: t("common.cancel") ?? ""
				});
			}
		});
	};

	const handleContainerClick = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
		modal.open || isLoadingFromBack ? e.stopPropagation() : gotoCheckoutChoose(e);
	};

	return (
		<Container className="homeContainer">
			<TouchArea className={`bgLayer`} onClick={handleContainerClick}>
				<HomeBackground />
			</TouchArea>

			<HiddenAdminButton handleAction={handleOpenModal} />

			<LanguageFlagStrip onClick={gotoCheckoutChoose} />

			<Dialog open={modal.open} fullScreen={!modal.overlay} fullWidth={true} maxWidth="xl" onClose={closeModal} scroll="paper">
				{modal.name === ModalViewName.pinPicker && <PinPickerModal closeModal={closeModal} confirmPin={handleConfirmPin} />}
			</Dialog>

			<Dialog open={isLoadingFromBack} fullScreen={true} fullWidth={true} maxWidth="xl" scroll="paper" className="menuLoading">
				<br></br>
				&nbsp;&nbsp;&nbsp;{t("common.crud.loading", { item: null }) + t("common.ellipsis")}
			</Dialog>
		</Container>
	);
};

export default Home;
