/**
 * Enumeration of the LED colors
 * The serial port controls three LEDs, one for each color: red, green, and blue (RGB)
 * The enum is a decimal representation of the three digits of the RGB code
 *
 * @example
 * 000 = black
 * 001 = blue
 * 010 = green
 * 011 = cyan
 * 100 = red
 * 101 = magenta
 * 110 = yellow
 * 111 = white
 */
export enum UspLedColor {
	black = 0,
	blue = 1,
	green = 2,
	cyan = 3,
	red = 4,
	magenta = 5,
	yellow = 6,
	white = 7
}
