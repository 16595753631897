import { isEqual } from "lodash";
import { Service4DeliveryEndpoints } from "..";
import { buildTheme } from "../../../components/Theme/ThemeConfig";
import { useTheme } from "../../../components/Theme/ThemeWrapper";
import { LogChannel } from "../../../constants";
import { defaultRestaurantInfo } from "../../../constants/defaults";
import { useBridgeWrapper } from "../../../hooks/useBridgeWrapper";
import { useCdnAssets } from "../../../hooks/useCdnAssets";
import { useLogger } from "../../../hooks/useLogger";
import { useRestaurantInfoVersionMapper } from "../../../hooks/useRestaurantInfoVersionMapper";
import { AppConfig, getCloudConfigResponse, RestaurantInfo } from "../../../types";
import { callAxios, callAxiosResponseInterface, Payload } from "../../../utils/httpClient/AxiosWrapper";
import { calculateKioskIdSetting } from "../../../utils/kioskProfile";
import { getStorageObject, setStorageObject } from "../../../utils/StorageWrapper";
import { useMenu } from "../Menu/useMenu";

export const useRestaurantInfo = () => {
	const { loadMediaAssets } = useCdnAssets();
	const { log, warn } = useLogger();
	const { settings, restaurantInfo, setContextTheme, setContextSetting, setContextRestaurantInfo, setContextHideSnoozed, setContextStripeLocationId } =
		useTheme();
	const { downloadMenu } = useMenu();
	const { versionMapper } = useRestaurantInfoVersionMapper();
	const { appVersion, uspFirmwareVersion, isInternetReachable: isOnline } = useBridgeWrapper();

	const getCloudConfigAndMenu = async () => {
		const restaurantId = localStorage.getItem("username") ?? "";
		const localStripeLocationId = localStorage.getItem("localStripeLocationId") ?? "";
		const storedRestaurantInfo: object | null = getStorageObject("restaurant_info");
		const fallbackRestaurantInfo = storedRestaurantInfo ? (storedRestaurantInfo as RestaurantInfo) : defaultRestaurantInfo;

		const newRestaurantInfo: RestaurantInfo = isOnline ? await getCloudConfig() : fallbackRestaurantInfo;

		if (localStripeLocationId !== newRestaurantInfo.default_stripe_location) {
			localStorage.setItem("localStripeLocationId", newRestaurantInfo.default_stripe_location ?? "");
			if (setContextStripeLocationId) setContextStripeLocationId(newRestaurantInfo.default_stripe_location);
		}

		const newSettings = calculateKioskIdSetting(newRestaurantInfo.config.settings);

		setStorageObject("restaurant_info", newRestaurantInfo);
		setStorageObject("localConfig", newRestaurantInfo.config ?? defaultRestaurantInfo.config);
		localStorage.setItem("localHideSnoozed", String(newRestaurantInfo.hide_snoozed ?? defaultRestaurantInfo.hide_snoozed));
		if (setContextTheme && setContextSetting && setContextRestaurantInfo && setContextHideSnoozed) {
			// propagate in the context if the new restaurantInfo is different from the current one
			if (!isEqual(restaurantInfo, newRestaurantInfo)) {
				setContextRestaurantInfo(newRestaurantInfo);
				setContextTheme(buildTheme(newRestaurantInfo.config));
			}
			// propagate in the context if the new settings are different from the current ones
			if (!isEqual(settings, newSettings)) {
				setContextSetting(newSettings);
			}
			setContextHideSnoozed(newRestaurantInfo.hide_snoozed ?? defaultRestaurantInfo.hide_snoozed);
		}

		if (isOnline) {
			loadMediaAssets(newRestaurantInfo.media, newRestaurantInfo.media_baseurl, restaurantId).catch(() => {
				// warn(["Loading media assets: ", error], LogChannel.cloudSync);
			});
		}

		const forceMenuDownload: boolean = newRestaurantInfo.config.versionNumber !== defaultRestaurantInfo.config.versionNumber;
		if (forceMenuDownload) {
			newRestaurantInfo.config.versionNumber = defaultRestaurantInfo.config.versionNumber;
			if (isOnline) {
				await setCloudConfig(newRestaurantInfo.config);
			}
		}

		if (isOnline) {
			await downloadMenu(forceMenuDownload);
		}
	};

	const getCloudConfig = async (): Promise<RestaurantInfo> => {
		return new Promise((resolve) => {
			callAxios({
				method: "post",
				url: Service4DeliveryEndpoints.configGet,
				body: JSON.stringify({}),
				headers: JSON.stringify({ accept: "*/*" })
			})
				.then((response: callAxiosResponseInterface) => {
					log("RestaurantInfo downloaded", LogChannel.cloudSync);
					const goodResponse = response as getCloudConfigResponse;

					// adapt the new restaurantInfo to the current version of the software
					resolve(versionMapper(goodResponse.data, appVersion, uspFirmwareVersion));
				})
				.catch((err) => {
					warn(["RestaurantInfo - get error: ", err], LogChannel.cloudSync);

					// try to load restaurantInfo from storage;
					const storedRestaurantInfo: object | null = getStorageObject("restaurant_info");
					resolve(storedRestaurantInfo ? (storedRestaurantInfo as RestaurantInfo) : defaultRestaurantInfo);
				});
		});
	};

	const setCloudConfig = async (currentConfig: AppConfig, hideSnoozed?: number): Promise<boolean> => {
		const payload: Payload = {
			config: JSON.stringify(currentConfig)
		};
		if (hideSnoozed !== undefined) payload.hide_snoozed = hideSnoozed;

		return new Promise((resolve) => {
			callAxios({
				method: "post",
				url: Service4DeliveryEndpoints.configSet,
				body: JSON.stringify(payload),
				headers: JSON.stringify({ accept: "*/*" })
			})
				.then((response: callAxiosResponseInterface) => {
					log("RestaurantInfo - Config uploaded", LogChannel.cloudSync);
					resolve(true);
				})
				.catch((err) => {
					warn(["RestaurantInfo - Set config error: ", err], LogChannel.cloudSync);
					resolve(false);
				});
		});
	};

	return { getCloudConfigAndMenu, setCloudConfig };
};
