import { linkBridge } from "@webview-bridge/web";
import { UspLedColor } from "../../constants";

export type BridgeResult = {
	success: boolean;
	errorMessage?: string;
	fallback?: boolean;
};
export type BridgeResultLegacy = Omit<BridgeResult, "fallback" | "success">;

export interface AvailableRelease {
	major: string;
	release: string;
	name: string;
}

export type AppBridgeState = {
	bridgeActive: boolean;
	isBridgeNewFormat: boolean;

	// Methods to be called exclusively from the PWA
	_collectUsbPayment(piClientSecret: string, isTest?: boolean): Promise<BridgeResult>;
	_cancelUsbPayment(isTest?: boolean): Promise<BridgeResult>;
	_testUsbStripeReader(isTest?: boolean): Promise<BridgeResult>;
	_persistLog(value: string, isTest?: boolean): Promise<BridgeResult>;
	_getLogFilesToUpload(kioskId: number, isTest?: boolean): Promise<BridgeResult & { data: string[] }>;
	_getLogLastLines(isTest?: boolean): Promise<BridgeResult & { data: string }>;
	_uploadLogFiles(kioskId: number, logFiles: string[], isTest?: boolean): Promise<BridgeResult>;
	_copyLogFilesToDocuments(isTest?: boolean): Promise<BridgeResult>;
	_changeWebviewUrl(url?: string, hasCloseButton?: boolean, isTest?: boolean): Promise<BridgeResult>;
	_checkUpdate(token: string, isTest?: boolean): Promise<BridgeResult & { data: AvailableRelease[] }>;
	_installUpdate(token: string, release: AvailableRelease, isTest?: boolean): Promise<BridgeResult>;
	_openAnyDesk(isTest?: boolean): Promise<BridgeResult>;
	_openSmartTde(backgroundMode?: boolean, isTest?: boolean): Promise<BridgeResult>;
	_toggleUspScanner(isActive?: boolean, isTest?: boolean): Promise<BridgeResult>;
	_toggleUspFan(isActive?: boolean, isTest?: boolean): Promise<BridgeResult>;
	_checkUspStatus(isCheckDone?: boolean, isTest?: boolean): Promise<BridgeResult>;
	_changeUspLedColor(color: number, isTest?: boolean): Promise<BridgeResult>;
	_setStripeLocationId(stripeLocationId: string | null, isTest?: boolean): Promise<BridgeResult>;
	_setUsername(username: string, isTest?: boolean): Promise<BridgeResult>;
	_setPassword(password: string, isTest?: boolean): Promise<BridgeResult>;
	_checkFirmwareUpdate(token: string, isTest?: boolean): Promise<BridgeResult & { data: AvailableRelease[] }>;
	_installFirmwareUpdate(token: string, release: AvailableRelease, isTest?: boolean): Promise<BridgeResult>;
	// legacy
	collectUsbPayment(piClientSecret: string): Promise<BridgeResultLegacy>;
	cancelUsbPayment(): Promise<boolean>;
	testUsbStripeReader(): Promise<boolean>;
	persistLog(value: string): Promise<void>;
	getLogFilesToUpload(kioskId: number): Promise<string[]>;
	uploadLogFiles(kioskId: number, logFiles: string[]): Promise<void>;
	copyLogFilesToDocuments(): Promise<void>;
	changeWebviewUrl(url: string, hasCloseButton?: boolean): Promise<void>;
	checkUpdate(token: string): Promise<AvailableRelease[]>;
	installUpdate(token: string, release: AvailableRelease): Promise<boolean>;
	openAnyDesk(): Promise<boolean>;
	setStripeLocationId(stripeLocationId: string | null): Promise<void>;
	setUsername(username: string): Promise<void>;
	setPassword(password: string): Promise<void>;

	// Shared state
	data: {
		stripeLocationId: string | null;
		isInternetReachable: boolean;
		username: string;
		password: string;
		webviewUrl: string;
		webviewHasCloseButton: boolean;
		webviewReferrer: string;
		appVersion: string;
		logFileName: string | undefined;
		uspForceScannerActive: boolean;
		uspForceFanActive: boolean;
		uspBinaryPath: string;
		uspForceUpdateActive: boolean;
		uspForceStatusOutdated: boolean;
		uspForceLedColor: number;
		uspFirmwareVersion: string;
		uspUid: string;
		uspStatuses: string;
	};
};

export const initialBridge: AppBridgeState = {
	bridgeActive: false,
	isBridgeNewFormat: false,
	_collectUsbPayment: async (piClientSecret: string) => {
		return { success: false, fallback: true, message: "_collectUsbPayment not supported" };
	},
	collectUsbPayment: async (piClientSecret: string) => {
		return {};
	},
	_cancelUsbPayment: async () => {
		return { success: false, fallback: true, message: "_cancelUsbPayment not supported" };
	},
	cancelUsbPayment: async () => false,
	_testUsbStripeReader: async () => {
		return { success: false, fallback: true, message: "_testUsbStripeReader not supported" };
	},
	testUsbStripeReader: async () => false,
	_persistLog: async (value: string) => {
		return { success: false, fallback: true, message: "_persistLog not supported" };
	},
	persistLog: async (value: string) => {},
	_getLogFilesToUpload: async (kioskId: number) => {
		return { success: false, fallback: true, message: "_getLogFilesToUpload not supported", data: [] };
	},
	getLogFilesToUpload: async (kioskId: number) => [],
	_getLogLastLines: async () => {
		return { success: false, fallback: true, message: "_getLogLastLines not supported", data: "" };
	},
	_uploadLogFiles: async (kioskId: number, logFiles: string[]) => {
		return { success: false, fallback: true, message: "_uploadLogFiles not supported" };
	},
	uploadLogFiles: async (kioskId: number, logFiles: string[]) => {},
	_copyLogFilesToDocuments: async () => {
		return { success: false, fallback: true, message: "_copyLogFilesToDocuments not supported" };
	},
	copyLogFilesToDocuments: async () => {},
	_changeWebviewUrl: async (url?: string, hasCloseButton?: boolean) => {
		return { success: false, fallback: true, message: "_changeWebviewUrl not supported" };
	},
	changeWebviewUrl: async (url: string, hasCloseButton?: boolean) => {},
	_checkUpdate: async (token: string) => {
		return { success: false, fallback: true, message: "_checkUpdate not supported", data: [] };
	},
	checkUpdate: async (token: string) => [],
	_installUpdate: async (token: string, release: AvailableRelease) => {
		return { success: false, fallback: true, message: "_installUpdate not supported" };
	},
	installUpdate: async (token: string, release: AvailableRelease) => false,
	_openAnyDesk: async () => {
		return { success: false, fallback: true, message: "_openAnyDesk not supported" };
	},
	openAnyDesk: async () => false,
	_openSmartTde: async (backgroundMode?: boolean) => {
		return { success: false, fallback: true, message: "_openSmartTde not supported" };
	},
	_toggleUspScanner: async (isActive?: boolean) => {
		return { success: false, fallback: true, message: "_toggleUspScanner not supported" };
	},
	_toggleUspFan: async (isActive?: boolean) => {
		return { success: false, fallback: true, message: "_toggleUspFan not supported" };
	},
	_checkUspStatus: async (isCheckDone?: boolean) => {
		return { success: false, fallback: true, message: "_checkUspStatus not supported" };
	},
	_changeUspLedColor: async (color: number) => {
		return { success: false, fallback: true, message: "_changeUspLedColor not supported" };
	},
	_setStripeLocationId: async (value: string | null) => {
		return { success: false, fallback: true, message: "_setStripeLocationId not supported" };
	},
	setStripeLocationId: async (value: string | null) => {},
	_setUsername: async (value: string) => {
		return { success: false, fallback: true, message: "_setUsername not supported" };
	},
	setUsername: async (value: string) => {},
	_setPassword: async (value: string) => {
		return { success: false, fallback: true, message: "_setPassword not supported" };
	},
	setPassword: async (value: string) => {},
	_checkFirmwareUpdate: async (token: string) => {
		return { success: false, fallback: true, message: "_checkFirmwareUpdate not supported", data: [] };
	},
	_installFirmwareUpdate: async (token: string, release: AvailableRelease) => {
		return { success: false, fallback: true, message: "_installFirmwareUpdate not supported" };
	},
	data: {
		stripeLocationId: null,
		isInternetReachable: false,
		username: "",
		password: "",
		webviewUrl: "",
		webviewHasCloseButton: false,
		webviewReferrer: "",
		appVersion: "",
		logFileName: undefined,
		uspForceScannerActive: false,
		uspForceFanActive: false,
		uspForceUpdateActive: false,
		uspBinaryPath: "",
		uspForceStatusOutdated: false,
		uspForceLedColor: UspLedColor.green,
		uspFirmwareVersion: "",
		uspUid: "",
		uspStatuses: ""
	}
};

export declare const appBridge: import("@webview-bridge/web").BridgeStore<AppBridgeState>;
export type AppBridge = typeof appBridge;

export const bridge = linkBridge<AppBridge>({
	throwOnError: true,
	initialBridge: initialBridge,
	timeout: 20000
});

export {};
