import { CloudOff } from "@mui/icons-material";
import { Box, Container, Dialog, Grid2 as Grid, LinearProgress, Paper } from "@mui/material";
import { t } from "i18next";
import { MouseEvent as ReactMouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate, useParams } from "react-router";
import {
	CheckoutFlowStep,
	CheckoutMethod,
	getPaymentMethodName,
	hasElectronicPaymentMethods,
	isCheckoutFlowStepLessThan,
	LogChannel,
	ModalViewName,
	PaymentMethod,
	PrinterType,
	RoutePath
} from "../../constants";
import { defaultCategory, defaultCheckoutMenu, defaultOrder, defaultPaymentInfo } from "../../constants/defaults";
import { useBarcodeDecoder } from "../../hooks/useBarcodeDecoder";
import { useBridgeWrapper } from "../../hooks/useBridgeWrapper";
import { cart_createEmpty, cart_getSimplifiedUid, useCart } from "../../hooks/useCart";
import { useGetRestaurantFileContent, useGetRestaurantMedia } from "../../hooks/useCdnAssets";
import { useLogger } from "../../hooks/useLogger";
import { useModal } from "../../hooks/useModal";
import { GetMenuFromStorage, orderCashDrawer, orderNoPayment } from "../../services/4Delivery";
import useCcPrinter from "../../services/Coatcheck/useCcPrinter";
import useRtPrinter from "../../services/Rt/useRtPrinter";
import {
	BarcodeItemInfo,
	CartItem,
	Category,
	CheckoutMenu,
	ItemSelectable,
	KioskMenu,
	Order,
	OrderNoPaymentResponse,
	PaymentErrorResponse,
	PaymentInfo
} from "../../types";
import { TransactionStatusDrawer } from "../../types/SmartTde";
import { getUpSellingItems } from "../../utils/UpSelling";
import LanguageFlagStrip from "../LanguageFlags/LanguageFlagStrip";
import { useMessage } from "../MessageHandler/MessageService";
import { useTheme } from "../Theme/ThemeWrapper";
import CartBottomBar from "./BottomBar/CartBottomBar";
import ItemsCategoriesList from "./Items/ItemsCategoriesList";
import ItemsCategoriesListSingleCategory from "./Items/ItemsCategoriesListSingleCategory";
import { EditableItemDialog, FilteredOrderReviewDialog, OrderReviewDialog, PaymentMethodModal, PaymentModal, PlacePickerModal, ReceiptModal } from "./Modals";
import ReceiptCCModal from "./Modals/ReceiptCCModal";
import "./OrderMaker.scss";
import Sidebar from "./Sidebar/Sidebar";

const OrderMaker = () => {
	const { theme, settings } = useTheme();
	const { isInternetReachable: isOnline } = useBridgeWrapper();
	const navigate = useNavigate();
	const message = useMessage();
	const { method } = useParams();
	const { log, warn } = useLogger();
	const { url: logoUrl, isFallback: isLogoFallback } = useGetRestaurantMedia("logo");
	const { content: disclaimer, isFallback: disclaimerNotFound } = useGetRestaurantFileContent("allergen");
	const { modal, openModal, closeModal, isModalRendered, setIsModalRendered } = useModal();
	const { cart, addItemToCart, updateItemCart, removeItemFromCart, resetCart } = useCart();
	const { isCashSystemOutOfPaper: isRtOutOfPaper, isCashSystemInError: isRtInError, processPrintingQueue: processRtQueue } = useRtPrinter();
	const { isCashSystemOutOfPaper: isCcOutOfPaper, isCashSystemInError: isCcInError, processPrintingQueue: processCcQueue } = useCcPrinter();
	const idleTimerInstance = useIdleTimer({
		name: "30seconds-inactive-timer",
		onIdle: () => {
			if (isCheckoutFlowStepLessThan(checkoutFlowStep, CheckoutFlowStep.paymentMethod)) {
				log(`Abandoned Cart for inactivity having amount ${cart.amount}`, LogChannel.order);
				cancelOrder();
			}
		},
		timeout: 1000 * 60 * 0.5 // 30 seconds
	});

	const kioskMenu: KioskMenu = useMemo(() => GetMenuFromStorage(), []);
	const checkoutMethod: CheckoutMethod = useMemo(() => Number(method) as CheckoutMethod, [method]);
	const menu: CheckoutMenu = useMemo(
		() => kioskMenu.checkoutMenus.find((cm: CheckoutMenu) => cm.checkout === checkoutMethod) ?? defaultCheckoutMenu,
		[checkoutMethod, kioskMenu]
	);
	const hasElectronicPayment: boolean = useMemo(() => hasElectronicPaymentMethods(settings.availablePaymentMethods), [settings.availablePaymentMethods]);
	const barcodeList: BarcodeItemInfo[] = useMemo(
		() => (settings.isScannerEnabled ? kioskMenu.barcodes : []),
		[settings.isScannerEnabled, kioskMenu.barcodes]
	);
	const { decodeBarcode } = useBarcodeDecoder(barcodeList, menu.categories);
	const recommendedItems = useMemo(
		() => getUpSellingItems(menu, theme.config.upSellingList, kioskMenu.upSellingList),
		[menu, theme.config.upSellingList, kioskMenu.upSellingList]
	);
	const isScanModalRenderingForTheFirstTime: boolean = useMemo(
		() => settings.isScannerEnabled && !isModalRendered,
		[settings.isScannerEnabled, isModalRendered]
	);

	const [langStripCondensed, setLangStripCondensed] = useState<boolean>(true);
	const [selectedCategory, setSelectedCategory] = useState<Category>(menu.categories[0] ?? defaultCategory);
	const [selectedCategoryChildCode, setSelectedCategoryChildCode] = useState<string>(menu.categories[0]?.children[0]?.categoryCode ?? "");
	const [order, setOrder] = useState<Order>({ ...defaultOrder, checkoutMethod: checkoutMethod, orderUid: String(Date.now()) });
	const [cartItemSelected, setCartItemSelected] = useState<CartItem>({} as CartItem);
	const [isNewCartItemSelected, setIsNewCartItemSelected] = useState<boolean>(true);
	const [filterUid, setFilterUid] = useState<string>("");
	const [checkoutFlowStep, setCheckoutFlowStep] = useState<CheckoutFlowStep>(
		settings.isScannerEnabled ? CheckoutFlowStep.orderReview : CheckoutFlowStep.orderMaking
	);

	const handleAddItemToCart = useCallback(
		(cartItem: CartItem) => {
			addItemToCart(cartItem);
		},
		[addItemToCart]
	);

	const newCartItem = useCallback(
		(itemSelectable: ItemSelectable, openDetail: boolean = false, referral?: ModalViewName): void => {
			const cartItem: CartItem = cart_createEmpty(itemSelectable, 1);

			if (openDetail || itemSelectable.hasMandatoryFields) {
				setCartItemSelected(cartItem);
				setIsNewCartItemSelected(true);
				openModal(ModalViewName.productDetail, undefined, referral ?? undefined);
			} else {
				handleAddItemToCart(cartItem);
			}
		},
		[handleAddItemToCart, openModal]
	);

	const decreaseItemCart = useCallback(
		(itemSelectable: ItemSelectable): void => {
			// looking for CartItems with the same simplified uid. i.e. two CartItem with different variations set are considered equal
			const filteredCartItems = cart.items.filter((cartItem: CartItem) => {
				return cart_getSimplifiedUid(cartItem.uid) === itemSelectable.itemInfo.uid;
			});

			// if just one variations found...
			if (filteredCartItems.length === 1) {
				const singleCartItem: CartItem = { ...filteredCartItems[0] };
				if (singleCartItem.quantity === 1) {
					removeItemFromCart(singleCartItem.uid);
				} else {
					singleCartItem.quantity = -1;
					handleAddItemToCart(singleCartItem);
				}
				setFilterUid("");
			} else {
				// ...otherwise open Modal and make user choose the one variation to remove
				setFilterUid(itemSelectable.itemInfo.uid);
				openModal(ModalViewName.filteredOrderReview, true);
			}
		},
		[openModal, removeItemFromCart, handleAddItemToCart, cart.items]
	);

	const editCartItem = useCallback(
		(cartItem: CartItem): void => {
			setCartItemSelected(cartItem);
			setIsNewCartItemSelected(false);
			openModal(ModalViewName.productDetail);
		},
		[openModal]
	);

	const confirmAddItem = useCallback(
		(cartItem: CartItem): void => {
			closeModal();

			if (!isNewCartItemSelected) {
				updateItemCart(cartItem);
				setIsNewCartItemSelected(true);
				openModal(ModalViewName.orderReview);
			} else {
				handleAddItemToCart(cartItem);
			}
		},
		[openModal, closeModal, updateItemCart, handleAddItemToCart, isNewCartItemSelected]
	);

	const cancelAddItem = useCallback((): void => {
		closeModal();

		if (!isNewCartItemSelected) {
			setIsNewCartItemSelected(true);
			openModal(ModalViewName.orderReview);
		}
	}, [isNewCartItemSelected, openModal, closeModal]);

	const backToOrderMaking = useCallback(() => {
		closeModal();
		setCheckoutFlowStep(CheckoutFlowStep.orderMaking);
	}, [closeModal]);

	const confirmReviewOrder = useCallback(() => {
		setCheckoutFlowStep(CheckoutFlowStep.orderReview);
	}, []);

	const confirmOrder = useCallback(() => {
		log(`Cart amount: ${cart.amount}`, LogChannel.order);
		setCheckoutFlowStep(CheckoutFlowStep.checkoutMethod);
	}, [cart.amount, log]);

	const confirmPlaceNumber = useCallback((newPlaceNumber: string) => {
		setOrder((prev: Order) => ({
			...prev,
			placeNumber: newPlaceNumber
		}));
		setCheckoutFlowStep(CheckoutFlowStep.placeNumber);
	}, []);

	const confirmPaymentMethod = useCallback(
		(paymentMethod: PaymentMethod) => {
			setOrder((prev: Order) => ({
				...prev,
				paymentMethod: paymentMethod
			}));
			log(`Payment method: ${getPaymentMethodName(paymentMethod)}`, LogChannel.order);
			setCheckoutFlowStep(CheckoutFlowStep.paymentMethod);
		},
		[log]
	);

	const confirmPayment = useCallback(
		async (newPaymentInfo: PaymentInfo, newKioskCartId: number | null) => {
			setOrder((prev: Order) => ({
				...prev,
				paymentInfo: newPaymentInfo,
				kioskCartId: newKioskCartId
			}));
			log(`KioskCartId: ${newKioskCartId}`, LogChannel.order);
			setCheckoutFlowStep(CheckoutFlowStep.paymentInfo);
		},
		[log]
	);

	const confirmReceipt = useCallback((): void => {
		setCheckoutFlowStep(CheckoutFlowStep.orderConfirmation);
	}, []);

	const cancelOrder = useCallback((): void => {
		setCheckoutFlowStep(CheckoutFlowStep.orderCancel);
	}, []);

	const resetAndExit = useCallback((): void => {
		resetCart();
		navigate(RoutePath.homePage);
	}, [navigate, resetCart]);

	const orderNoPay = useCallback(async () => {
		// since there's no payment, fiscal mode is forced to false
		await orderNoPayment(false, cart, order)
			.then((response: OrderNoPaymentResponse) => {
				const newPaymentInfo: PaymentInfo = { ...defaultPaymentInfo, order_id: response.order_id };
				log("Sent Order without payment", LogChannel.payment);
				confirmPayment(newPaymentInfo, response.kiosk_cart_id);
			})
			.catch((response: PaymentErrorResponse) => {
				if (!response.is_back_error) {
					warn(`Order without payment - error response: ${response.error_code}`, LogChannel.payment);
				} else {
					warn(`Order without payment - error response from back: ${response.error_code}`, LogChannel.payment);
				}
				message({
					title: t(`system.error.${response.error_code}`).toUpperCase(),
					description: t("system.error.order_not_created"),
					okCallback: cancelOrder,
					okLabel: t("common.ok").toUpperCase()
				});
			});
	}, [cart, message, confirmPayment, order, log, warn, cancelOrder]);

	const orderDrawer = useCallback(
		async (status: TransactionStatusDrawer) => {
			// since there's no payment, fiscal mode is forced to false
			await orderCashDrawer(settings.isCashSystemEnabled && settings.isFiscalMode, cart, order, status)
				.then((response: OrderNoPaymentResponse) => {
					const newPaymentInfo: PaymentInfo = { ...defaultPaymentInfo, order_id: response.order_id, non_erogato: status.non_erogato ?? undefined };
					log("Sent Cash drawer payment", LogChannel.payment);
					confirmPayment(newPaymentInfo, response.kiosk_cart_id);
				})
				.catch((response: PaymentErrorResponse) => {
					if (!response.is_back_error) {
						warn(`Order Cash drawer payment - error response: ${response.error_code}`, LogChannel.payment);
					} else {
						warn(`Order Cash drawer payment - error response from back: ${response.error_code}`, LogChannel.payment);
					}
					message({
						title: t(`system.error.${response.error_code}`).toUpperCase(),
						description: t("system.error.order_not_created"),
						okCallback: cancelOrder,
						okLabel: t("common.ok").toUpperCase()
					});
				});
		},
		[cart, message, confirmPayment, order, log, warn, cancelOrder, settings.isCashSystemEnabled, settings.isFiscalMode]
	);

	const handleCancelOrder = useCallback(() => {
		// if the cart is empty, the order is cancelled immediately
		if (cart.amount === 0 || settings.directPay || settings.availablePaymentMethods.length === 1) {
			cancelOrder();
			return;
		}

		idleTimerInstance.pause();
		message({
			title: t("checkout.cartBottomBar.cancelOrder").toUpperCase(),
			description: t("checkout.cartBottomBar.cancelOrderConfirm"),
			okCallback: () => {
				idleTimerInstance.resume();
				cancelOrder();
			},
			okLabel: t("common.yes").toUpperCase(),
			cancelCallback: () => {
				idleTimerInstance.resume();
			},
			cancelLabel: t("common.cancel").toUpperCase()
		});
	}, [message, cancelOrder, idleTimerInstance, cart.amount, settings.directPay, settings.availablePaymentMethods]);

	const cancelPayment = useCallback((): void => {
		if (settings.directPay || settings.availablePaymentMethods.length === 1) {
			handleCancelOrder();
		} else {
			setOrder((prev: Order) => ({
				...prev,
				orderUid: String(Date.now()),
				paymentMethod: PaymentMethod.CASH
			}));
			setCheckoutFlowStep(CheckoutFlowStep.placeNumber);
		}
	}, [settings.directPay, settings.availablePaymentMethods, handleCancelOrder]);

	const scrollToCategoryChild = useCallback((categoryCode?: string): void => {
		function delay(time: number) {
			return new Promise((resolve) => setTimeout(resolve, time));
		}
		// scroll after 100ms... react must render the component before the "scroll" function start looking for anchor DOM elements
		delay(100).then(() => {
			const elementId: string = categoryCode === undefined ? "init" : "category-" + categoryCode;
			const target = window.document.getElementById(elementId);
			if (target) {
				target.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
			}
		});
	}, []);

	const changeSelectedCategory = useCallback(
		(categoryTitle: string): void => {
			const newCategory: Category = menu.categories.find((category: Category) => category.title === categoryTitle) ?? menu.categories[0];

			setSelectedCategory(newCategory);
			setSelectedCategoryChildCode(newCategory.children[0].categoryCode);

			scrollToCategoryChild(newCategory.children[0].categoryCode);
		},
		[scrollToCategoryChild, menu.categories]
	);

	const changeSelectedCategoryChild = useCallback(
		(e: ReactMouseEvent<HTMLDivElement, MouseEvent>, categoryCode: string): void => {
			e.stopPropagation();

			setSelectedCategoryChildCode(categoryCode);
			scrollToCategoryChild(categoryCode);
		},
		[scrollToCategoryChild]
	);

	const handleChooseLanguage = useCallback(() => setLangStripCondensed((prev: boolean) => !prev), []);

	useEffect(() => {
		log(checkoutFlowStep, LogChannel.order);

		switch (checkoutFlowStep) {
			case CheckoutFlowStep.orderReview:
				// Show modal in which the user can Review the Cart ad eventually confirm the order
				openModal(ModalViewName.orderReview);
				break;

			case CheckoutFlowStep.checkoutMethod:
				// Show or skip PlacePicker modal depending on Checkout method
				if (checkoutMethod === CheckoutMethod.PLACE_NUMBER) {
					if (settings.skipPlaceNumberChoice) {
						// setting place number to 0 so the backend can handle it properly
						confirmPlaceNumber("0");
					} else {
						openModal(ModalViewName.placePicker);
					}
				} else {
					confirmPlaceNumber("");
				}
				break;

			case CheckoutFlowStep.placeNumber:
				// Show or skip Payment Method modal depending on Payment Method availability
				if (settings.availablePaymentMethods.length === 1) {
					confirmPaymentMethod(settings.availablePaymentMethods[0]);
				} else {
					openModal(ModalViewName.paymentMethod);
				}
				break;

			case CheckoutFlowStep.paymentMethod:
				// Show or skip Payment modal depending on Payment Method type (cash doesn't need payment)
				if (order.paymentMethod !== PaymentMethod.CASH) {
					openModal(ModalViewName.payment);
				} else {
					// make Order without actual Payment
					orderNoPay();
				}
				break;

			case CheckoutFlowStep.paymentInfo:
				// Show or skip Receipt modal depending on Cash System availability
				if (settings.isCashSystemEnabled) {
					openModal(ModalViewName.receipt);
				} else {
					confirmReceipt();
				}
				break;

			case CheckoutFlowStep.orderConfirmation:
			case CheckoutFlowStep.orderCancel:
				resetAndExit();
				break;

			default:
				break;
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [checkoutFlowStep]);

	useEffect(() => {
		if (settings.printerType === PrinterType.basiq) {
			processRtQueue();
		} else {
			processCcQueue();
		}
	}, [processRtQueue, processCcQueue, settings.printerType]);

	useEffect(() => {
		if (isRtOutOfPaper || isCcOutOfPaper) {
			message({
				title: t("system.error.cashSystem.error").toUpperCase(),
				description: t("system.error.cashSystem.statusError") + ": " + t("system.error.cashSystem.out-of-paper"),
				okCallback: () => {
					if (settings.printerType === PrinterType.basiq) {
						processRtQueue();
					} else {
						processCcQueue();
					}
				},
				okLabel: t("common.retry").toUpperCase(),
				cancelCallback: cancelOrder,
				cancelLabel: t("common.back").toUpperCase()
			});
		}
		if (isRtInError || isCcInError) {
			message({
				title: t("system.error.cashSystem.error").toUpperCase(),
				description: t("system.error.cashSystem.statusError"),
				okCallback: cancelOrder,
				okLabel: t("common.ok").toUpperCase()
			});
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [isRtOutOfPaper, isRtInError, isCcOutOfPaper, isCcInError]);

	return (
		<Container className={`menuContainer ${isScanModalRenderingForTheFirstTime ? "scanModalRendering" : ""}`}>
			{isOnline != null && !isOnline && hasElectronicPayment ? (
				<CloudOff color="error" sx={{ position: "absolute", right: "0.25rem", fontSize: "1.25rem" }} />
			) : null}
			<Box className="header" sx={{ backgroundColor: theme.customTheme.palette.background.default }}>
				<LanguageFlagStrip isCondensed={langStripCondensed} onClick={handleChooseLanguage} />
				{isLogoFallback ? null : (
					<Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
						<img className="customLogo" src={logoUrl} alt="logo" loading="lazy" />
					</Paper>
				)}
			</Box>

			<Box className="main">
				{menu ? (
					<>
						<Grid container spacing={0}>
							<Grid size={{ xs: menu.categories.length > 1 && !settings.singleCategory ? 2 : 1 }}>
								{menu.categories.length > 1 && !settings.singleCategory ? (
									<Sidebar
										menu={menu}
										changeSelectedCategory={changeSelectedCategory}
										changeSelectedCategoryChild={changeSelectedCategoryChild}
										selectedCategory={selectedCategory}
										selectedCategoryChildCode={selectedCategoryChildCode}
									/>
								) : null}
							</Grid>
							{!settings.singleCategory ? (
								<Grid size={{ xs: 10 }}>
									{selectedCategory.children.length > 0 ? (
										<ItemsCategoriesList
											selectedCategory={selectedCategory}
											cart={cart}
											onNewCartItem={newCartItem}
											decreaseItemCart={decreaseItemCart}
										/>
									) : null}
								</Grid>
							) : (
								<Grid size={{ xs: 10 }}>
									{menu.categories.length > 0 ? (
										<ItemsCategoriesListSingleCategory
											categories={menu.categories}
											cart={cart}
											onNewCartItem={newCartItem}
											decreaseItemCart={decreaseItemCart}
										/>
									) : null}
								</Grid>
							)}
						</Grid>
					</>
				) : (
					<LinearProgress />
				)}
			</Box>

			<Dialog
				open={modal.open}
				fullScreen={!modal.overlay}
				fullWidth={true}
				maxWidth="xl"
				onClose={closeModal}
				className={modal.overlay ? "overlayModal" : ""}
				scroll="paper"
				slotProps={{
					transition: {
						onEntered: () => setIsModalRendered(true)
					}
				}}
			>
				{modal.name === ModalViewName.productDetail && (
					<EditableItemDialog
						isNewCartItemSelected={isNewCartItemSelected}
						cartItem={cartItemSelected}
						closeModal={cancelAddItem}
						addItemToCart={confirmAddItem}
						categories={kioskMenu.categoryList}
						idleInstance={idleTimerInstance}
						disclaimer={disclaimer}
						disclaimerNotFound={disclaimerNotFound}
					/>
				)}

				{modal.name === ModalViewName.filteredOrderReview && (
					<FilteredOrderReviewDialog
						cart={cart}
						filterUid={filterUid}
						addItemToCart={handleAddItemToCart}
						removeItemFromCart={removeItemFromCart}
						closeModal={closeModal}
					/>
				)}

				{modal.name === ModalViewName.orderReview && (
					<OrderReviewDialog
						cart={cart}
						addItemToCart={handleAddItemToCart}
						removeItemFromCart={removeItemFromCart}
						closeModal={backToOrderMaking}
						confirmOrder={confirmOrder}
						cancelOrder={handleCancelOrder}
						goToItemCartEditor={editCartItem}
						recommendedItems={recommendedItems}
						onNewCartItem={newCartItem}
						decodeBarcode={decodeBarcode}
					/>
				)}

				{modal.name === ModalViewName.placePicker && <PlacePickerModal closeModal={backToOrderMaking} confirmPlaceNumber={confirmPlaceNumber} />}

				{modal.name === ModalViewName.paymentMethod && (
					<PaymentMethodModal closeModal={backToOrderMaking} confirmPaymentMethod={confirmPaymentMethod} />
				)}

				{modal.name === ModalViewName.payment && (
					<PaymentModal cancelPayment={cancelPayment} confirmPayment={confirmPayment} cart={cart} order={order} confirmCashPayment={orderDrawer} />
				)}

				{modal.name === ModalViewName.receipt ? (
					settings.printerType === PrinterType.coatcheck ? (
						<ReceiptCCModal closeModal={confirmReceipt} cart={cart} order={order} menuTickets={kioskMenu.tickets} />
					) : (
						<ReceiptModal closeModal={confirmReceipt} cart={cart} order={order} />
					)
				) : null}
			</Dialog>

			<CartBottomBar
				amount={cart.amount}
				itemsCount={cart.itemsCount}
				cancelOrder={handleCancelOrder}
				reviewOrder={confirmReviewOrder}
				confirmOrder={confirmOrder}
			/>
		</Container>
	);
};

export default OrderMaker;
