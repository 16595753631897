import { Add, Block, Exposure, Info, List, ViewColumn } from "@mui/icons-material";
import { Box, Slider, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Dispatch, memo, SetStateAction, useCallback, type JSX } from "react";
import { useTranslation } from "react-i18next";
import { ActionType, getActionTypeName } from "../../../constants";
import { SettingsConfig } from "../../../types";
import AdminDivider from "../../Layout/Divider/AdminDivider";
import { useTheme } from "../../Theme/ThemeWrapper";

interface AdminSettingsProductProps {
	currentSettings: SettingsConfig;
	setCurrentSettings: Dispatch<SetStateAction<SettingsConfig>>;
}

const AdminSettingsProduct = ({ currentSettings, setCurrentSettings }: AdminSettingsProductProps) => {
	const { t } = useTranslation();
	const { theme } = useTheme();

	const getIcon = useCallback((actionType: ActionType): JSX.Element => {
		switch (actionType) {
			case ActionType.none:
				return <Block />;
			case ActionType.add:
				return <Add />;
			case ActionType.details:
				return <Info />;
			case ActionType.addRemove:
				return <Exposure />;
			default:
				return <Block />;
		}
	}, []);

	const handleMenuColumnChange = useCallback(
		(event: Event, value: number | number[]) => {
			setCurrentSettings((prev: SettingsConfig) => ({ ...prev, menuColumn: value as number }));
		},
		[setCurrentSettings]
	);

	/**
	 * Render a group of toggle buttons for all the actions in the ActionType enum
	 * @param selectedAction - The currently selected action
	 * @param changeHandler	- The function to call when the action changes
	 * @param actionsDisabled - An array of ActionType values that should be disabled
	 * @returns
	 */
	const renderToggleButtons = useCallback(
		(
			selectedAction: ActionType,
			changeHandler: (event: React.MouseEvent<HTMLElement>, value: any) => void,
			actionsDisabled?: ActionType[]
		): JSX.Element => {
			return (
				<Stack direction={"row"} alignItems={"center"}>
					<ToggleButtonGroup size="large" value={selectedAction} exclusive onChange={changeHandler}>
						{Object.values(ActionType).map((value: string | ActionType) => {
							if (isNaN(Number(value))) return null; //looping over a "Numeric ENUM" returns both names and values. Get rid of names!
							const action: ActionType = Number(value) as ActionType;
							if ((actionsDisabled ?? []).includes(action)) return null;

							const isSelected: boolean = selectedAction === action;
							const formatBg = {
								backgroundColor: isSelected ? `${theme.systemTheme.palette.success.main} !important` : undefined,
								"& .MuiSvgIcon-root": { color: isSelected ? theme.systemTheme.palette.success.contrastText : undefined }
							};

							return (
								<ToggleButton key={action} value={action} sx={formatBg}>
									{getIcon(action)}
								</ToggleButton>
							);
						})}
					</ToggleButtonGroup>
					<Typography sx={{ ml: "2rem" }}>{t(`system.admin.settings.actionType.${getActionTypeName(selectedAction)}`)}</Typography>
				</Stack>
			);
		},
		[getIcon, theme.systemTheme.palette.success.contrastText, theme.systemTheme.palette.success.main, t]
	);

	const handleBoxActionChange = useCallback(
		(event: React.MouseEvent<HTMLElement>, value: any) => {
			if (value !== null) {
				setCurrentSettings((prev: SettingsConfig) => ({ ...prev, boxAction: value as number }));
			}
		},
		[setCurrentSettings]
	);

	const handleButtonActionChange = useCallback(
		(event: React.MouseEvent<HTMLElement>, value: any) => {
			if (value !== null) {
				setCurrentSettings((prev: SettingsConfig) => ({ ...prev, buttonAction: value as number }));
			}
		},
		[setCurrentSettings]
	);

	return (
		<>
			<AdminDivider label={t("system.admin.theme.productPreview")} icon={<List />} />

			<Box sx={{ py: "1rem" }}>
				<Typography>{t("system.admin.settings.menuColumns")}</Typography>
			</Box>
			<Stack justifyContent={"space-between"} direction={"row"} alignItems={"start"}>
				<List color="secondary" />
				<Slider
					color="secondary"
					sx={{ m: "1rem 2rem 4rem", width: "100%" }}
					value={currentSettings.menuColumn}
					min={1}
					max={3}
					marks={[
						{ value: 1, label: t("system.admin.theme.listView") },
						{ value: 2, label: "2 " + t("system.admin.theme.columns") },
						{ value: 3, label: "3 " + t("system.admin.theme.columns") }
					]}
					step={1}
					valueLabelDisplay="off"
					onChange={handleMenuColumnChange}
				/>
				<ViewColumn color="secondary" />
			</Stack>

			<Box sx={{ py: "1rem" }}>
				<Typography>{t("system.admin.settings.chooseBoxAction")}</Typography>
			</Box>
			{renderToggleButtons(currentSettings.boxAction, handleBoxActionChange, [ActionType.addRemove])}

			{currentSettings.menuColumn !== 1 ? (
				<>
					<Box sx={{ py: "1rem" }}>
						<Typography>{t("system.admin.settings.chooseButtonAction")}</Typography>
					</Box>
					{renderToggleButtons(currentSettings.buttonAction, handleButtonActionChange)}
				</>
			) : null}
		</>
	);
};

export default memo(AdminSettingsProduct);
