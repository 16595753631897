import { Suspense, useEffect, type JSX } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Routes, useLocation } from "react-router";
import { LogChannel, PaymentMethod, RoutePath } from "../constants";
import { defaultRestaurantInfo } from "../constants/defaults";
import { useBridgeWrapper } from "../hooks/useBridgeWrapper";
import { useFonts } from "../hooks/useFonts";
import { useLogger } from "../hooks/useLogger";
import Admin from "./Admin/Admin";
import Diagnostic from "./Admin/diagnostic/Diagnostic";
import Choose from "./Checkout/Choose";
import OrderMaker from "./Checkout/OrderMaker";
import Home from "./Home/Home";
import Login from "./Login/Login";
import "./Main.scss";
import { useTheme } from "./Theme/ThemeWrapper";
import TriggerPage from "./TriggerPage";

/** Main wrapper for the whole app. */
const Main = (): JSX.Element => {
	const { settings } = useTheme();
	const { _toggleUspScanner, _openSmartTde } = useBridgeWrapper();
	const { t } = useTranslation();
	const { log } = useLogger();

	useEffect(() => {
		log("Version " + defaultRestaurantInfo.config.versionNumber, LogChannel.generic);
		// dependencies are empty, so this will only run once
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	/**
	 * Activate/deactivate scanner depending on the settings, if there are any.
	 */
	useEffect(() => {
		_toggleUspScanner(settings.kioskId ? settings.isScannerEnabled : false);
	}, [settings.isScannerEnabled, settings.kioskId, _toggleUspScanner]);

	/**
	 * Open SmartTde App in background if there are settings depending on SmartTde service.
	 */
	useEffect(() => {
		if (settings.kioskId ? settings.availablePaymentMethods?.includes(PaymentMethod.CASH_DRAWER) : false) {
			_openSmartTde(true);
		}
	}, [settings.availablePaymentMethods, settings.kioskId, _openSmartTde]);

	return (
		<div className="Main">
			<Suspense
				fallback={
					<div>
						{t("common.crud.loading", { item: null })}
						{t("common.ellipsis")}
					</div>
				}
			>
				<BrowserRouter>
					<RoutesWrapper />
				</BrowserRouter>
			</Suspense>
		</div>
	);
};

export default Main;

const RoutesWrapper = (): JSX.Element => {
	const location = useLocation();
	const { log } = useLogger();
	useFonts();

	useEffect(() => {
		log(location.pathname, LogChannel.router);
	}, [location, log]);

	return (
		<Routes>
			<Route path={RoutePath.adminPage} element={<Admin />} />
			<Route path={RoutePath.homePage} element={<Home />} />
			<Route path={RoutePath.loginPage} element={<Login />} />
			<Route path={RoutePath.choosePage} element={<Choose />} />
			<Route path={RoutePath.orderPage} element={<OrderMaker />} />
			<Route path={RoutePath.reloadTrigger} element={<TriggerPage />} />
			<Route path={RoutePath.closeTrigger} element={<TriggerPage />} />
			<Route path={RoutePath.diagnostic} element={<Diagnostic />} />
		</Routes>
	);
};
