import { Box, Container, Dialog, Typography } from "@mui/material";
import ConfirmModal from "./Checkout/Modals/Wrappers/ConfirmModal";
import TouchButton from "./Layout/Buttons/TouchButton";

interface SimplePageProps {
	title?: string;
	message?: string;
	clickLabel?: string;
	onClickButton?: () => void;
}

const SimplePage = ({ title, message, clickLabel, onClickButton }: SimplePageProps) => {
	const handleClick = () => {
		if (onClickButton === undefined) return;

		onClickButton();
	};

	return (
		<Container>
			<Dialog open={true} fullScreen={true} fullWidth={true} maxWidth="xl" onClose={() => {}} scroll="paper">
				<ConfirmModal>
					<Box>
						<Box>
							{title ? (
								<Typography color="primary" variant="h4" textAlign="center">
									{title}
								</Typography>
							) : null}
							{message ? (
								<Box>
									<Typography>{message}</Typography>
								</Box>
							) : null}
						</Box>
					</Box>
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						{clickLabel && onClickButton ? (
							<TouchButton color="success" variant="contained" size="large" onClick={handleClick}>
								<Typography variant="button">{clickLabel}</Typography>
							</TouchButton>
						) : null}
					</Box>
				</ConfirmModal>
			</Dialog>
		</Container>
	);
};

export default SimplePage;
