import { ArrowCircleRightOutlined, HighlightOffRounded } from "@mui/icons-material";
import { alpha, Badge, Box, Stack, Typography } from "@mui/material";
import { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as BarCodeIcon } from "../../../assets/system/barcode.svg";
import { ReactComponent as CartIcon } from "../../../assets/system/cart.svg";
import { Font } from "../../../constants";
import { useCurrency } from "../../../hooks/useCurrency";
import TouchArea from "../../Layout/Buttons/TouchArea";
import TouchButton from "../../Layout/Buttons/TouchButton";
import { useTheme } from "../../Theme/ThemeWrapper";
import BottomBar from "./BottomBar";

export interface CartBottomBarProps {
	amount: number;
	itemsCount: number;
	cancelOrder: () => void;
	reviewOrder: () => void;
	confirmOrder: () => void;
}

const CartBottomBar = ({ amount, itemsCount, cancelOrder, reviewOrder, confirmOrder }: CartBottomBarProps) => {
	const { theme, settings } = useTheme();
	const { t } = useTranslation();
	const { currency } = useCurrency();

	const handleCancelOrder = useCallback(() => cancelOrder(), [cancelOrder]);
	const handleClickCart = useCallback(
		() => (itemsCount === 0 && !settings.isScannerEnabled ? {} : reviewOrder()),
		[reviewOrder, itemsCount, settings.isScannerEnabled]
	);
	const handleReviewConfirmOrder = useCallback(() => (settings.directPay ? confirmOrder() : reviewOrder()), [reviewOrder, confirmOrder, settings.directPay]);

	const textColor: string = theme.systemTheme.palette.background.paper;
	const buttonTextColor: string = theme.systemTheme.palette.info.contrastText;
	const buttonBorderColor: string = useMemo(() => alpha(theme.systemTheme.palette.info.contrastText, 0.1), [theme.systemTheme]);
	const confirmDescription: string = useMemo(
		() => (settings.directPay ? t("checkout.cartBottomBar.confirmOrder") : t("common.continue")),
		[t, settings.directPay]
	);

	return (
		<BottomBar>
			<Box>
				<TouchButton
					color="inherit"
					variant="outlined"
					size="large"
					sx={{ color: buttonTextColor, borderColor: buttonBorderColor }}
					startIcon={<HighlightOffRounded />}
					disableElevation
					className="cancel-order-btn"
					onClick={handleCancelOrder}
				>
					<Typography variant="button">{t("checkout.cartBottomBar.cancelOrder")}</Typography>
				</TouchButton>
			</Box>
			<Box>
				<TouchArea onClick={handleClickCart}>
					<Stack direction="row" alignItems="center">
						{settings.isScannerEnabled ? (
							<Badge className="scanner" badgeContent={itemsCount === 0 ? "" : itemsCount} color={itemsCount === 0 ? "default" : "primary"}>
								<BarCodeIcon fill={textColor} />
							</Badge>
						) : (
							<>
								<Box component="span">
									<Typography variant="h3" fontWeight="bold" fontFamily={Font.openSans} color={textColor}>
										{currency(amount)}
									</Typography>
								</Box>
								<Badge className="bagBadge" badgeContent={itemsCount === 0 ? "" : itemsCount} color={itemsCount === 0 ? "default" : "primary"}>
									<CartIcon fill={textColor} />
								</Badge>
							</>
						)}
					</Stack>
				</TouchArea>
			</Box>
			<Box>
				<TouchButton
					color="success"
					variant="contained"
					fillBgWhenDisabled={true}
					size="large"
					endIcon={<ArrowCircleRightOutlined />}
					disableElevation
					disabled={itemsCount === 0}
					onClick={handleReviewConfirmOrder}
				>
					<Typography variant="button">{confirmDescription}</Typography>
				</TouchButton>
			</Box>
		</BottomBar>
	);
};

export default memo(CartBottomBar);
