const base = (process.env.REACT_APP_4DELIVERY_BASE_URL ?? "") + "/api/v1/kiosk";

/**
 * 4Delivery Service - Kiosk endpoint's
 */
export const Service4DeliveryEndpoints = {
	authLogin: base + "/login-test",
	configGet: base + "/restaurant-info-get",
	configSet: base + "/restaurant-info-set",
	menuGet: base + "/menu",
	orderNoPayment: base + "/order/without-payment", // orderUid as idempotence key
	orderDocument: base + "/order/document",
	cashDrawer: base + "/cashdrawer/request",
	satispay: {
		request: base + "/satispay/request", // orderUid as idempotence key
		update: base + "/satispay/update-status",
		cancel: base + "/satispay/cancel",
		test: base + "/satispay/test"
	},
	stripe: {
		request: base + "/payment/request", // orderUid as idempotence key
		intentRequest: base + "/payment/intent-request",
		update: base + "/payment/update-status",
		cancel: base + "/payment/cancel",
		test: base + "/payment/test"
	}
};

export * from "./Auth/Login";
export * from "./Config/useRestaurantInfo";
export * from "./Menu/useMenu";
export * from "./OrderPayments/ElectronicPayment";
export * from "./OrderPayments/Order";
export * from "./OrderPayments/Payment";
