import { StepButton, StepButtonProps } from "@mui/material";
import { memo } from "react";
import { useLongPress } from "use-long-press";
import useSoundEffect, { SoundEffectProps } from "../../../hooks/effects/useSoundEffect";

interface TouchStepButtonProps extends StepButtonProps, SoundEffectProps {}

const TouchStepButton = ({ muteSound, tone, ...rest }: TouchStepButtonProps) => {
	const { playSound } = useSoundEffect({ muteSound, tone });
	const bind = useLongPress((event: any) => {
		if (rest.onClick) {
			rest.onClick(event);
		}
	});

	return <StepButton disableRipple disableTouchRipple onClickCapture={playSound} {...bind()} {...rest} />;
};

export default memo(TouchStepButton);
