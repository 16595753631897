import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDrawer } from "../../../../../services/SmartTDE";
import { TransactionStatusDrawer, UseDrawerResult } from "../../../../../types/SmartTde";
import { delayer } from "../../../../../utils/functions";

interface CancelCashTransactionProps {
	setIsCanceling: Dispatch<SetStateAction<boolean>>;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	setTextWarning: Dispatch<SetStateAction<string | undefined>>;
	cancelPayment: () => void;
	cashTransaction: TransactionStatusDrawer | null;
}
const CancelCashTransaction = ({ setIsCanceling, setIsLoading, setTextWarning, cancelPayment, cashTransaction }: CancelCashTransactionProps) => {
	const { t } = useTranslation();
	const { postCancelTransaction } = useDrawer();

	const cancel = async () => {
		if (!cashTransaction) cancelPayment();

		setIsCanceling(true);
		setIsLoading(true);

		await postCancelTransaction(cashTransaction?.transaction_id as number)
			.then(async (response: UseDrawerResult) => {
				if (response.result) {
					setTextWarning(t("checkout.paymentModal.canceled"));
				} else {
					throw new Error(response.errorMessage);
				}
			})
			.catch(async (err: unknown) => {
				setTextWarning(t("checkout.paymentModal.canceledError") + " " + t("system.error.staff"));
			})
			.finally(async () => {
				setIsLoading(false);
				await delayer(10000);
				setIsCanceling(false);
				setTextWarning(undefined);
				cancelPayment();
			});
	};

	useEffect(() => {
		/* eslint-disable react-hooks/exhaustive-deps */
		cancel();
	}, []);

	return null;
};

export default CancelCashTransaction;
