import { Card, CardContent, CardHeader, Paper, Typography } from "@mui/material";
import { Dispatch, memo, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { AppConfig, MenuTicketFormat, SettingsConfig, Vat } from "../../../types";
import { useTheme } from "../../Theme/ThemeWrapper";
import AdminSettingsCheckout from "./AdminSettingsCheckout";
import AdminSettingsKioskProfile from "./AdminSettingsKioskProfile";
import AdminSettingsLanguage from "./AdminSettingsLanguage";
import AdminSettingsPayment from "./AdminSettingsPayment";
import AdminSettingsPreference from "./AdminSettingsPreference";
import AdminSettingsPrinter from "./AdminSettingsPrinter";
import AdminSettingsProduct from "./AdminSettingsProduct";
import AdminSettingsScanner from "./AdminSettingsScanner";

interface AdminSettingsProps {
	isAdmin: boolean;
	onCreateKioskId: (kioskId: number) => void;
	onChooseKioskId: (kioskId: number) => void;
	currentConfig: AppConfig;
	currentSettings: SettingsConfig;
	setCurrentSettings: Dispatch<SetStateAction<SettingsConfig>>;
	currentHideSnoozed: number;
	setCurrentHideSnoozed: Dispatch<SetStateAction<number>>;
	menuVatList: Vat[];
	menuTickets: MenuTicketFormat[];
	satispayDisabled: boolean;
	setSatispayDisabled: Dispatch<SetStateAction<boolean>>;
	onOpenWebPage: (url: string) => void;
	stripeLocationId: string | null;
}

const AdminSettings = ({
	isAdmin,
	onCreateKioskId,
	onChooseKioskId,
	currentConfig,
	currentSettings,
	setCurrentSettings,
	currentHideSnoozed,
	setCurrentHideSnoozed,
	menuVatList,
	menuTickets,
	satispayDisabled,
	setSatispayDisabled,
	onOpenWebPage,
	stripeLocationId
}: AdminSettingsProps) => {
	const { t } = useTranslation();
	const { theme } = useTheme();

	const settingToggler = useCallback(
		(key: keyof SettingsConfig) => {
			setCurrentSettings((prev: SettingsConfig) => ({ ...prev, [key]: !prev[key] }));
		},
		[setCurrentSettings]
	);

	return (
		<Paper sx={{ p: "1.5rem 0" }} elevation={0}>
			{isAdmin ? (
				<AdminSettingsKioskProfile
					onCreateKioskId={onCreateKioskId}
					onChooseKioskId={onChooseKioskId}
					currentSettings={currentSettings}
					currentConfig={currentConfig}
				/>
			) : null}

			{currentSettings.kioskId > 0 ? (
				<Card sx={{ mt: "4rem" }} elevation={3}>
					<CardHeader
						title={
							<Typography color={theme.systemTheme.palette.primary.contrastText} fontWeight="bold">
								{t("system.admin.settings.kioskProfile").toUpperCase() + " " + currentSettings.kioskId}
							</Typography>
						}
						sx={{ backgroundColor: `${theme.systemTheme.palette.primary.main}` }}
					/>

					<CardContent>
						{isAdmin ? (
							<>
								<AdminSettingsCheckout
									currentSettings={currentSettings}
									setCurrentSettings={setCurrentSettings}
									settingToggler={settingToggler}
								/>

								<AdminSettingsProduct currentSettings={currentSettings} setCurrentSettings={setCurrentSettings} />
							</>
						) : null}

						<AdminSettingsPreference
							isAdmin={isAdmin}
							currentSettings={currentSettings}
							settingToggler={settingToggler}
							currentHideSnoozed={currentHideSnoozed}
							setCurrentHideSnoozed={setCurrentHideSnoozed}
						/>

						{isAdmin ? <AdminSettingsLanguage currentSettings={currentSettings} setCurrentSettings={setCurrentSettings} /> : null}

						<AdminSettingsPayment
							isAdmin={isAdmin}
							currentSettings={currentSettings}
							setCurrentSettings={setCurrentSettings}
							satispayDisabled={satispayDisabled}
							setSatispayDisabled={setSatispayDisabled}
							stripeLocationId={stripeLocationId}
						/>

						<AdminSettingsScanner isAdmin={isAdmin} currentSettings={currentSettings} settingToggler={settingToggler} />

						<AdminSettingsPrinter
							isAdmin={isAdmin}
							currentSettings={currentSettings}
							setCurrentSettings={setCurrentSettings}
							settingToggler={settingToggler}
							menuVatList={menuVatList}
							menuTickets={menuTickets}
							onOpenWebPage={onOpenWebPage}
						/>
					</CardContent>
				</Card>
			) : null}
		</Paper>
	);
};

export default memo(AdminSettings);
