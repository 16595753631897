import { Chip, ChipProps, styled } from "@mui/material";
import { memo } from "react";

const ChipRem = ({ ...rest }: ChipProps) => {
	const StyledMuiChip = styled(Chip)<ChipProps>(() => {
		return {
			height: "2rem",
			borderRadius: "1rem",
			"& .MuiChip-icon": {
				width: "2rem",
				height: "2rem",
				padding: "0.25rem 0"
			},
			"& .MuiChip-label": {
				lineHeight: "2rem"
			}
		};
	});

	return <StyledMuiChip {...rest} />;
};

export default memo(ChipRem);
