import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LogChannel, PrinterType } from "../../../../../constants";
import { useBridgeWrapper } from "../../../../../hooks/useBridgeWrapper";
import { useLogger } from "../../../../../hooks/useLogger";
import { ElectronicPayment } from "../../../../../services/4Delivery";
import useRtPrinter from "../../../../../services/Rt/useRtPrinter";
import { Cart, Order, PaymentErrorResponse, PaymentIntentResponse, SimpleStatusResponse } from "../../../../../types";
import { OrderRequestManager } from "../../../../../utils/OrderRequestManager";
import { useTheme } from "../../../../Theme/ThemeWrapper";

interface CancelIntentProps {
	cart: Cart;
	order: Order;
	electronicPayment: ElectronicPayment;
	paymentIntent: PaymentIntentResponse;
	isStripeRnPayment: boolean;
	setIsCanceling: Dispatch<SetStateAction<boolean>>;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	setTextWarning: Dispatch<SetStateAction<string | undefined>>;
	resetPaymentIntent: () => void;
	cancelPayment: () => void;
}
const CancelIntent = ({
	cart,
	order,
	electronicPayment,
	paymentIntent,
	isStripeRnPayment,
	setIsCanceling,
	setIsLoading,
	setTextWarning,
	resetPaymentIntent,
	cancelPayment
}: CancelIntentProps) => {
	const { settings } = useTheme();
	const { _cancelUsbPayment } = useBridgeWrapper();
	const { t } = useTranslation();
	const { log, warn } = useLogger();
	const orderRequestManager = new OrderRequestManager(order);
	const { RT } = useRtPrinter();

	const delay = (time: number) => {
		return new Promise((resolve) => setTimeout(resolve, time));
	};

	const cancel = async () => {
		setIsCanceling(true);
		setIsLoading(true);

		await electronicPayment
			.cancelPayment(paymentIntent.kiosk_cart_id)
			.then(async (value: SimpleStatusResponse) => {
				const text: string = t("checkout.paymentModal.canceled");
				log(`Payment Intent canceled successfully. Cart id: ${paymentIntent.kiosk_cart_id}`, LogChannel.payment);
				setTextWarning(text);
				if (settings.printerType === PrinterType.basiq) {
					await RT.cashSystemPaymentCanceled(paymentIntent, text);
				} else {
					// await CC.cashSystemPaymentCanceled(paymentIntent, text);
				}

				setIsLoading(false);
			})
			.catch(async (err: PaymentErrorResponse) => {
				const text: string = t("checkout.paymentModal.canceledError");
				warn(`Payment Intent cancel error. Cart id: ${paymentIntent.kiosk_cart_id}. Details: ${err.error_code}`, LogChannel.payment);
				setTextWarning(text + " " + t("system.error.staff"));
				if (settings.printerType === PrinterType.basiq) {
					await RT.cashSystemPaymentCanceled(paymentIntent, text);
				} else {
					// await CC.cashSystemPaymentCanceled(paymentIntent, text);
				}
				setIsLoading(false);
				await delay(10000);
			})
			.finally(() => {
				if (isStripeRnPayment) {
					_cancelUsbPayment();
				}

				orderRequestManager.resetOrderRequestId();
				setTextWarning(undefined);
				resetPaymentIntent();
				cancelPayment();
			});
	};

	useEffect(() => {
		/* eslint-disable react-hooks/exhaustive-deps */
		cancel();
	}, []);

	return null;
};

export default CancelIntent;
