import { cloneDeep } from "lodash";
import { Cc, PaymentMethod } from "../../constants";
import { mapToCoatcheckPaymentMethod, mapToCoatcheckPaymentMethodName } from "../../constants/enum/Cc";
import { Cart, CartItem, MenuTicketFormat, PrintingItemCc, SettingsConfig, TicketFormat } from "../../types";
import { centsToValue } from "../../utils/Numbers";
import { tItemInfo } from "../../utils/i18nMenu";

/**
 * The `TicketGenerator` class is responsible for generating tickets for items in a cart.
 * It maps the ticket formats based on the provided settings and generates the necessary
 * printing items for the CoatCheck printer.
 */
export class TicketGenerator {
	cart: Cart;
	settings: SettingsConfig;
	columns: number;
	menuTickets: MenuTicketFormat[];
	paymentMethod: PaymentMethod;
	lang: string;

	constructor(cart: Cart, settings: SettingsConfig, menuTickets: MenuTicketFormat[], paymentMethod: PaymentMethod, lang: string) {
		this.cart = cart;
		this.settings = settings;
		this.columns = 21;
		this.menuTickets = this.mapPluTicketFormat(menuTickets, settings.ticketFormats);
		this.paymentMethod = paymentMethod;
		this.lang = lang;
	}

	/**
	 * Gets the ticket format by PLU.
	 *
	 * @param uid the PLU ID
	 * @returns the ticket format code
	 */
	getTicketFormatByPlu(uid: string): Cc.Format {
		let chosenFormat: Cc.Format = Cc.Format.A;
		this.menuTickets.forEach((mtf: MenuTicketFormat) => {
			const foundFormat = mtf.plu.find((pluMtf: MenuTicketFormat) => pluMtf.id === uid);
			if (foundFormat && foundFormat.code !== undefined) {
				chosenFormat = foundFormat.code;
			}
		});

		return chosenFormat;
	}

	/**
	 * Maps the PLU ticket format.
	 *
	 * @param menuTickets the menu tickets
	 * @param settingsTicketFormat the ticket format associated to each PLU in the settings
	 * @returns the mapped PLU ticket format
	 */
	mapPluTicketFormat(menuTickets: MenuTicketFormat[], settingsTicketFormat: TicketFormat[]): MenuTicketFormat[] {
		const tickets = cloneDeep(menuTickets);

		tickets.forEach((mtf: MenuTicketFormat, catIndex: number) => {
			const categoryFormat = settingsTicketFormat.find((tf: TicketFormat) => tf.id === mtf.id && tf.type === mtf.type && tf.code !== undefined);
			if (categoryFormat) {
				tickets[catIndex].code = categoryFormat.code;
			}

			mtf.plu.forEach((pluMtf: MenuTicketFormat, pluIndex: number) => {
				const pluFormat = settingsTicketFormat.find(
					(pluTf: TicketFormat) => pluTf.id === pluMtf.id && pluTf.type === pluMtf.type && pluTf.cat === pluMtf.cat && pluTf.code !== undefined
				);
				tickets[catIndex].plu[pluIndex].code = pluFormat ? pluFormat.code : categoryFormat?.code;
			});
		});
		return tickets;
	}

	/**
	 * Makes the printing items for the CoatCheck printer.
	 *
	 * @returns an array of `PrintingItemCc` objects to be printed by the CoatCheck printer.
	 */
	make(): PrintingItemCc[] {
		const tallonList: PrintingItemCc[] = [];

		this.cart.items?.forEach((ci: CartItem) => {
			const shortText: string = ci.item.itemInfo.shortText.substring(0, this.columns);
			const shortTextTranslated = tItemInfo(this.lang, ci.item.itemInfo, "shortText").substring(0, this.columns);

			for (let j = 0; j < ci.quantity; j++) {
				const newItem: PrintingItemCc = {
					commandType: Cc.Command.printTicket,
					ID: this.getTicketFormatByPlu(ci.item.itemInfo.uid),
					Qnt: 1,
					Price: centsToValue(ci.item.itemInfo.price),
					TextLine1: "",
					TextLine2: shortTextTranslated,
					TextLine3: "",
					Cut: Cc.Cut.byTicket,
					PayId: mapToCoatcheckPaymentMethod(this.paymentMethod),
					PayDesc: mapToCoatcheckPaymentMethodName(this.paymentMethod),
					PLUId: ci.item.itemInfo.uid,
					PLUDesc: shortText
				};
				tallonList.push(newItem);
			}
		});

		return tallonList;
	}
}
