import { IconButton, IconButtonProps } from "@mui/material";
import { memo } from "react";
import { useLongPress } from "use-long-press";
import useSoundEffect, { SoundEffectProps } from "../../../hooks/effects/useSoundEffect";

interface TouchIconProps extends IconButtonProps, SoundEffectProps {}

const TouchIcon = ({ muteSound, tone, ...rest }: TouchIconProps) => {
	const { playSound } = useSoundEffect({ muteSound, tone });

	const bind = useLongPress((event: any) => {
		if (rest.onClick) {
			rest.onClick(event);
		}
	});

	return <IconButton disableRipple disableTouchRipple onClickCapture={playSound} {...bind()} {...rest} />;
};

export default memo(TouchIcon);
