import {
	FormControl,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import { isEqual } from "lodash";
import { Dispatch, memo, SetStateAction, useEffect, useMemo, useState, type JSX } from "react";
import { useTranslation } from "react-i18next";
import { Cc, TicketFormatType } from "../../../constants";
import { getCcFormatName } from "../../../constants/enum/Cc";
import { useDebounceValue } from "../../../hooks/useDebounceValue";
import { MenuTicketFormat, SettingsConfig, TicketFormat } from "../../../types";

interface AdminSettingsCcTicketsProps {
	currentSettings: SettingsConfig;
	setCurrentSettings: Dispatch<SetStateAction<SettingsConfig>>;
	menuTickets: MenuTicketFormat[];
}
const AdminSettingsCcTickets = ({ currentSettings, setCurrentSettings, menuTickets }: AdminSettingsCcTicketsProps) => {
	const { t } = useTranslation();

	const [localTickets, setLocalTickets] = useState<TicketFormat[]>(currentSettings.ticketFormats ?? []);
	const debouncedLocalTickets = useDebounceValue(localTickets, 700);

	const localMenuTickets = useMemo(() => menuTickets, [menuTickets]);

	const handleLocalTicketChange = (newTicket: TicketFormat) => {
		if (newTicket.type === TicketFormatType.categoryChild) {
			if (localTickets.find((tf: TicketFormat) => tf.id === newTicket.id && tf.type === newTicket.type)) {
				setLocalTickets(
					localTickets.map((tf: TicketFormat) => {
						if (tf.id === newTicket.id && tf.type === newTicket.type) {
							return { ...newTicket };
						} else {
							return tf;
						}
					})
				);
			} else {
				setLocalTickets((localTickets) => [...localTickets, { ...newTicket }]);
			}
		} else if (newTicket.type === TicketFormatType.plu) {
			if (localTickets.find((tf: TicketFormat) => tf.id === newTicket.id && tf.cat === newTicket.cat && tf.type === newTicket.type)) {
				setLocalTickets(
					localTickets.map((tf: TicketFormat) => {
						if (tf.id === newTicket.id && tf.cat === newTicket.cat && tf.type === newTicket.type) {
							return { ...newTicket };
						} else {
							return tf;
						}
					})
				);
			} else {
				setLocalTickets((localTickets) => [...localTickets, { ...newTicket }]);
			}
		}
	};

	useEffect(() => {
		if (!isEqual(debouncedLocalTickets, currentSettings?.ticketFormats)) {
			setCurrentSettings((prev: SettingsConfig) => ({
				...prev,
				ticketFormats: [...debouncedLocalTickets]
			}));
		}
	}, [debouncedLocalTickets, currentSettings?.ticketFormats, setCurrentSettings]);

	const ticketsOptions = useMemo((): JSX.Element[] => {
		const options: JSX.Element[] = [
			<MenuItem key={9999} value={""}>
				{"-"}
			</MenuItem>
		];
		Object.values(Cc.Format).forEach((value: string | Cc.Format) => {
			if (isNaN(Number(value))) return null; //looping over a "Numeric ENUM" returns both names and values. Get rid of names!
			options.push(
				<MenuItem key={value} value={value}>
					{getCcFormatName(Number(value))}
				</MenuItem>
			);
		});
		return options;
	}, []);

	return (
		<>
			<Typography>{t("system.admin.settings.tickets")}</Typography>
			<Typography>{t("system.admin.settings.ticketMapping")}</Typography>
			<TableContainer component={Paper} elevation={1}>
				<Table
					sx={{
						minWidth: "40rem",
						"& td, & th": { padding: "1rem !important" }
					}}
					aria-label="simple-table"
				>
					<TableHead>
						<TableRow>
							<TableCell component="th" scope="row" align="center" colSpan={4}>
								{""}
							</TableCell>
							<TableCell align="center">{t("system.admin.settings.ticketTable.ticketCode")}</TableCell>
						</TableRow>
					</TableHead>

					{localMenuTickets.map((ticketCat, indexCat) => {
						const handleChangeCat = (e: SelectChangeEvent<Cc.Format | "">) => {
							const newTicketCode = e.target.value;
							const convertedValue = newTicketCode !== "" ? +newTicketCode : undefined;

							handleLocalTicketChange({
								id: ticketCat.id,
								cat: "",
								type: TicketFormatType.categoryChild,
								code: convertedValue
							});
						};

						const catSet = localTickets.find((local) => local.id === ticketCat.id && local.type === TicketFormatType.categoryChild);
						const catValue = catSet !== undefined ? (catSet.code ?? "") : "";

						return (
							<TableBody key={`body_${ticketCat.id}_${indexCat}`}>
								<TableRow key={`cat_${ticketCat.id}_${indexCat}`}>
									<TableCell component="th" scope="row" colSpan={4}>
										{`${t("system.admin.settings.ticketTable.category")}: ${ticketCat.desc}`}
									</TableCell>
									<TableCell component="th" scope="row" colSpan={4}>
										<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
											<Select value={catValue} onChange={handleChangeCat} error={false} color="secondary">
												{ticketsOptions}
											</Select>
										</FormControl>
									</TableCell>
								</TableRow>
								{ticketCat.plu.map((mtf: MenuTicketFormat, mtfIndex: number) => {
									const handleChangePlu = (e: SelectChangeEvent<Cc.Format | "">) => {
										const newTicketCode = e.target.value;
										const convertedValue = newTicketCode !== "" ? +newTicketCode : undefined;
										handleLocalTicketChange({
											id: mtf.id,
											cat: ticketCat.id,
											type: TicketFormatType.plu,
											code: convertedValue
										});
									};

									const pluSet = localTickets.find(
										(local) => local.id === mtf.id && local.cat === ticketCat.id && local.type === TicketFormatType.plu
									);
									const pluValue = pluSet !== undefined ? (pluSet.code ?? "") : "";

									return (
										<TableRow key={`${indexCat}_${mtfIndex}_plu_${ticketCat.id}_${mtf.id}`}>
											<TableCell component="th" scope="row" colSpan={2}></TableCell>
											<TableCell component="th" scope="row" colSpan={2}>
												{mtf.desc?.toUpperCase()}
											</TableCell>
											<TableCell align="center" sx={{ p: "0.25rem" }}>
												<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
													<Select
														value={pluValue}
														onChange={handleChangePlu}
														error={catValue === "" && pluValue === ""}
														color="secondary"
													>
														{ticketsOptions}
													</Select>
												</FormControl>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						);
					})}
				</Table>
			</TableContainer>
		</>
	);
};

export default memo(AdminSettingsCcTickets);
