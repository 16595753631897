/* Service SmartTde - API response/request types. */

import { DrawerErrorCode } from "../constants/enum/SmartTde/SmartTdeErrors";

export const smartTdeHeaderPrefix = "X-";

/**
 * Smart TDE request headers.
 *
 * @param X-uid-device - Device UID.
 * @param X-device - Device Type Id.
 * @param X-progressive-id - Progressive ID.
 */
export interface SmartTdeHeaders {
	"X-uid-device": string;
	"X-device": number;
	"X-progressive-id": number;
}

/**
 * Smart TDE request headers.
 *
 * @param X-pos-id - The Kiosk ID.
 */
export interface SmartTdeRequestHeaders extends SmartTdeHeaders {
	"X-pos-id": number;
}

/**
 * Smart TDE response headers.
 *
 * @param X-process-as-message-id - Process message ID.
 * @param X-error-code - Error code.
 * @param X-error-description - Error description.
 */
export interface SmartTdeResponseHeaders extends SmartTdeHeaders {
	"X-process-as-message-id": number;
	"X-error-code"?: number;
	"X-error-description"?: string;
}

/*******************************/
/**********  DRAWER   **********/
/*******************************/
/**
 * UseDrawer hook result.
 */
export interface UseDrawerResult {
	result: boolean;
	errorMessage?: string;
	errorCode?: DrawerErrorCode;
	data?: any;
}

/**
 * Transaction status.
 *
 * @param operation_result - True if the operation was successful.
 * @param transaction_id - Transaction ID.
 * @param to_pay - Amount to pay in cents. (e.g. 1000 = 10.00€)
 * @param is_closed - True if the transaction is closed.
 * @param non_erogato - Amount the drawer did not dispense as change. In cents. (e.g. 1000 = 10.00€)
 */
export interface TransactionStatusDrawer {
	operation_result: boolean;
	transaction_id: number;
	to_pay: number;
	is_closed: boolean;
	non_erogato: number;
}

/**
 * Request to start a transaction.
 *
 * @param to_pay - Amount to pay in cents. (e.g. 1000 = 10.00€)
 */
export interface StartTransactionDrawerRequest {
	to_pay: number;
}

/**
 * Response from the API.
 *
 * @param operation_result - True if the operation was successful.
 */
export interface ResultDrawerResponse {
	operation_result: boolean;
	xHeaders: SmartTdeResponseHeaders;
}

/**
 * Response from the API.
 *
 * @param transaction_id - Transaction ID.
 */
export interface StartTransactionDrawerResponse extends ResultDrawerResponse {
	transaction_id: number;
}

/**
 * Response from the API.
 */
export interface GetTransactionStatusDrawerResponse extends ResultDrawerResponse, TransactionStatusDrawer {}
