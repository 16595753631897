import { useCallback, useMemo } from "react";
import { BarcodeTypology } from "../constants";
import { defaultItemSelectable } from "../constants/defaults";
import { BarcodeItemInfo, Category, ItemInfo, ItemSelectable } from "../types";
import { Barcode } from "../types/Barcode";
import { parseBarcode } from "../utils/barcode";
import { useCurrency } from "./useCurrency";

/**
 * Custom hook to handle scanned barcodes
 */
export const useBarcodeDecoder = (barcodeList: BarcodeItemInfo[], categories: Category[]) => {
	const { currency, numberFormat } = useCurrency();

	// Convert a barcodeItemInfo to an ItemSelectable object
	const convertBarcodeToItemSelectable = useCallback(
		(barcodeItem: BarcodeItemInfo, menuItem: ItemSelectable): ItemSelectable => {
			// Extract the core info from the barcodeItemInfo
			// Please note that BarcodeItemInfo and ItemInfo shared the same core info fields
			const { tipology, barcodes, ...coreInfo } = barcodeItem;

			// For weight barcodes, override the longText with unit of measure (um) price
			if (tipology === BarcodeTypology.weight) {
				coreInfo.longText = `(${currency(coreInfo.price)}/${coreInfo.um ?? ""}) ${coreInfo.longText}`;
			}

			// Return the ItemSelectable object with the core info and the default values for the rest of the fields
			return {
				...menuItem,
				itemInfo: {
					...menuItem.itemInfo,
					...coreInfo
				} as ItemInfo
			} as ItemSelectable;
		},
		[currency]
	);

	// Construct a JS map of all available barcodes using the barcode as the key
	// and the corresponding ItemSelectable object from the menu as the value
	// please note that each BarcodeItemInfo can have multiple barcodes
	const barcodeMap: Map<string, ItemSelectable> = useMemo(() => {
		const map = new Map<string, ItemSelectable>();

		if (!barcodeList) {
			return map;
		}

		barcodeList.forEach((bi: BarcodeItemInfo) => {
			const associationKey = bi.uid;
			// find the first ItemSelectable that has the same uid as the barcodeItemInfo looping over all categories, return the first one found
			// if no ItemSelectable is found, the defaultItemSelectable is returned
			const menuItem: ItemSelectable = categories.reduce((acc, category) => {
				const itemSelectable = category.children.reduce((acc, child) => {
					const itemSelectable = child.itemSelectable.find((itemSelectable) => itemSelectable.itemInfo.uid === associationKey);
					return itemSelectable || acc;
				}, acc);
				return itemSelectable || acc;
			}, defaultItemSelectable);

			const barcodeSelectable: ItemSelectable = convertBarcodeToItemSelectable(bi, menuItem);

			bi.barcodes?.forEach((barcode: string) => {
				map.set(barcode, barcodeSelectable);
			});
		});

		return map;
	}, [barcodeList, categories, convertBarcodeToItemSelectable]);

	// Decode a barcode and return the corresponding ItemSelectable object
	const decodeBarcode = useCallback(
		(barcodeString: string): ItemSelectable | undefined => {
			let mapIndex = barcodeString;

			// Parse the barcode string
			const barcode: Barcode | null = parseBarcode(barcodeString);
			if (!barcode) {
				return undefined;
			}
			// If the barcode prefix is 2, than it's an openPrice or weight barcode tipology.
			// This mean only the first 7 digits of the barcode are used as index
			if (barcode.isWeightOpen) {
				mapIndex = barcodeString.substring(0, 7);
			}

			const barcodeSelectable = barcodeMap.get(mapIndex);
			// If the barcode exists, convert it to an ItemInfo object
			if (barcodeSelectable) {
				if (barcode.isWeightOpen && barcode.value) {
					if (barcodeSelectable.itemInfo.price) {
						const unitPrice = barcodeSelectable.itemInfo.price;
						const unitQuantity = Math.floor((barcode.value * 100) / unitPrice) / 100;

						// override shortText with the values from the scanned barcode
						barcodeSelectable.itemInfo.shortText = `${numberFormat(unitQuantity)}${barcodeSelectable.itemInfo.um ?? ""} ${barcodeSelectable.itemInfo.shortText}`;
					}
					// override price with the values from the scanned barcode
					barcodeSelectable.itemInfo.price = barcode.value;
				}

				return barcodeSelectable;
			}

			return undefined;
		},
		[barcodeMap, numberFormat]
	);

	return { barcodeMap, decodeBarcode };
};
