import { useEffect } from "react";
import { LogChannel } from "../constants";
import { useLogger } from "../hooks/useLogger";
import { getStorageObject } from "../utils/StorageWrapper";

/**
 * This hook is used to log the error boundary using the logger
 *
 * The error boundary error is stored in the local storage when a runtime error occurs in the application.
 * This hook retrieves the error boundary error from the local storage and logs it when the Home component mounts.
 *
 */
const useErrorBoundaryLog = () => {
	const { warn } = useLogger();

	useEffect(() => {
		const boundaryError = getStorageObject("boundaryError");
		if (boundaryError) {
			warn(boundaryError, LogChannel.boundaryError);
			localStorage.removeItem("boundaryError");
		}
	}, [warn]);
};

export default useErrorBoundaryLog;
